import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CONSTANTS } from 'app/auth/service/service.assets';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { LocalStorageService } from '../../../app/auth/service/localstorage.service';
import { CookieService } from 'app/auth/service/cookie.service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService, private _localStorageService: LocalStorageService, private _cookieService : CookieService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;
      const userRole = this._localStorageService.getLocalStorage(CONSTANTS.ROLE);    
      if(userRole == 'ROLE_MASTER'){     
        // Load menu
        this.menu = this._coreMenuService.getCurrentMenu();
      }else{
        let permission =  this._cookieService.getCookie(CONSTANTS.PERMISSION);
        if(permission){
          var permit = permission.split(",");
          var permission_menu:any = [];
          permit.forEach(element => {      
            if(atob(element) == 'VIEW_APP_USER'){
              permission_menu.push(this._coreMenuService.getCurrentMenu().filter(d => d.id == "appUser")[0]);
            }
            if(atob(element) == 'VIEW_BANNER'){
              permission_menu.push(this._coreMenuService.getCurrentMenu().filter(d => d.id == "banner")[0]);
            }
            if(atob(element) == 'VIEW_CMS_PAGES'){
              permission_menu.push(this._coreMenuService.getCurrentMenu().filter(d => d.id == "cmsPages")[0]);
            }
            if(atob(element) == 'VIEW_SETTINGS'){
              permission_menu.push(this._coreMenuService.getCurrentMenu().filter(d => d.id == "settings")[0]);
            }
            if(atob(element) == 'VIEW_TRANSACTION'){
              permission_menu.push(this._coreMenuService.getCurrentMenu().filter(d => d.id == "transaction")[0]);
            }
            if(atob(element) == 'VIEW_ROLE'){
              permission_menu.push(this._coreMenuService.getCurrentMenu().filter(d => d.id == "role")[0]);
            }
            if(atob(element) == 'VIEW_ROLE_USER'){
              permission_menu.push(this._coreMenuService.getCurrentMenu().filter(d => d.id == "roleUser")[0]);
            }
          });
          this.menu = permission_menu;
        }
      }

      this._changeDetectorRef.markForCheck();

    });
  }
}
