import { Component, OnInit } from '@angular/core'
import { SelectionType } from '@swimlane/ngx-datatable';
import { APIClientService } from 'app/auth/service/apiclient.service';
import { CommonService } from 'app/auth/service/common.service';
import { URL } from "app/auth/service/service.assets";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { AppUserService } from 'app/auth/service/app-user.service';
import { Router } from '@angular/router';
import { CookieService } from 'app/auth/service/cookie.service';
import { CONSTANTS } from 'app/auth/service/service.assets';
import { LocalStorageService } from 'app/auth/service/localstorage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(
    private _cookieService : CookieService,
    private _apiClientService: APIClientService,
    private _commonService: CommonService,
    private _appUserService: AppUserService,
    private _localStorageService: LocalStorageService,
    private _route: Router
  ) {}
  public isUserButton: boolean = false;
  public isTransactionButton: boolean = false;
  public isCountDisplay: boolean = false;

  private searchPolicyUserObs: Subscription;
  private levelRequest: Subscription;
  public contentHeader: object
  public pageStart: any = 0;
  public pageLevelStart: any = 0;
  public dashbordCount: any= {
    "users":"-",
    "transaction":"-",
    "peso":"-",
    "usd":"-"
  }
  public userTrnasactiondata: any;
  fetchUserLevelUpRequestListParam = {
    limit: 10,
    pageNo: 1,
    searchString: "",
    sortBy: "createdDate",
    direction: "desc",
    user_id: '',
    start_date: '',
    end_date: ''
  };

  public userdata: any;
  fetchAppUserListParam = {
    limit: 10,
    pageNo: 1,
    searchString: "",
    sortBy: "createdDate",
    direction: "desc",
    user_id: '',
    start_date: '',
    end_date: ''
  };

  @BlockUI() blockUI: NgBlockUI;
  ngOnInit() {
    const userRole = this._localStorageService.getLocalStorage(CONSTANTS.ROLE);    
    if(userRole == 'ROLE_MASTER'){   
      this.isUserButton = true;
      this.isTransactionButton = true;
      this.isCountDisplay = true;
    }else{
      
      let permission =  this._cookieService.getCookie(CONSTANTS.PERMISSION);
      if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('ADD_BANNER')) >= 0){
       this.isUserButton = true;
      }
 
      if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('ADD_BANNER')) >= 0){
       this.isTransactionButton = true;
      }
    }

    


    this.contentHeader = {
      headerTitle: 'Dashboard',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Dashboard',
            isLink: true,
            link: '/'
          },
          {
            name: 'Sample',
            isLink: false
          }
        ]
      }
    }
    // this._apiClientService.getCall(URL.BASE_URL + "admin/dashboard/countTotal")
    //   .subscribe(
    //     res => {
    //       if (res.status == "SUCCESS"){
    //         this.dashbordCount ={
    //           "users": res.data.totalEconUser,
    //           "transaction": res.data.totalTransaction,
    //           "peso": res.data.totalPesoBalance,
    //           "usd": res.data.totalUSDBalance,
    //         }
    //       }
    //     },
    //     err => {
    //       this._commonService.checkError(err);
    //      }
    //   );

    //this.searchUserTransaction(false);
   // this.requestUserForLevel(false);
    
  }

  requestUserForLevel(loader) {
    if (loader) {
      this.blockUI.start();
    }
    return new Promise((resolve) => {
      if (this.levelRequest) {
        this.levelRequest.unsubscribe();
      }
      this.levelRequest = this._appUserService.requestUserForLevel(this.fetchUserLevelUpRequestListParam, true, false).subscribe(async (results) => {
        this.blockUI.stop();
        this.userdata = results.data.content;
        this.pageLevelStart = (results.data.pageable.pageSize * results.data.pageable.pageNumber) + 1;
        resolve("");
      }, (error) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
        resolve("");
      });
    });
  }

  /**
  * edit sub admin
  * @param id 
  */
  editSelectedAppUser(id: any) {
    this._route.navigate(["admin/appUser/add"], { queryParams: { id: btoa(id) } });
  }

  searchUserTransaction(loader) {
    if (loader) {
      this.blockUI.start();
    }
    return new Promise((resolve) => {
      if (this.searchPolicyUserObs) {
        this.searchPolicyUserObs.unsubscribe();
      }
      this.searchPolicyUserObs = this._appUserService.searchUserTransaction(this.fetchAppUserListParam, true, false).subscribe(async (results) => {
        this.blockUI.stop();
        this.userTrnasactiondata = results.data.content;       
        this.pageStart = (results.data.pageable.pageSize * results.data.pageable.pageNumber) + 1; 
        resolve("");
      }, (error) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
        resolve("");
      });
    });
  }

}
