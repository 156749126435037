import { environment } from "environments/environment";

export class CONSTANTS {
    
    public static ACCESSTOKEN = "accessToken";
    public static PERMISSION = "permission";
    public static ROLE = "role";
    public static ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
    public static ROLE_MOBILE_USER = "ROLE_MOBILE_USER";
    public static REFRESHTOKEN = "refreshToken";
    public static AUTHORIZATION = "Basic ZWNvbi1jbGllbnQ6ZWNvbi1zZWNyZXQ=";
    public static BEARER = "Bearer ";
    public static ROLE_PERMISSION = ['manage.role.add','manage.role.edit','manage.role.view','manage.role.delete'];
    public static ROLE_USER_PERMISSION = ['manage.role_user.add','manage.role_user.edit','manage.role_user.view','manage.role_user.delete'];
    public static APP_USER_PERMISSION = ['manage.app_user.add','manage.app_user.edit','manage.app_user.view','manage.app_user.delete'];
    public static BANNER_PERMISSION = ['manage.banner.add','manage.banner.edit','manage.banner.view','manage.banner.delete'];
    public static CMS_PAGES_PERMISSION = ['manage.cms_pages.edit','manage.cms_pages.view'];

}

export class URL{
    public static BASE_URL : string = environment.apiUrl;
    public static SIGNIN : string = environment.apiUrl + "auth/login";
    public static LOGOUT : string = environment.apiUrl + "oauth/logout";
    public static FORGOT_PASSWORD : string = environment.apiUrl + "noAuth/forgotPassword";
    public static VERIFY_TOKEN : string = environment.apiUrl + "noAuth/verifyToken  ";
    public static RESET_PASSWORD : string = environment.apiUrl + "noAuth/resetPassword";
    public static SET_PASSWORD : string = environment.apiUrl + "noAuth/verifyEmail";

    public static SEARCH_PERMISSION : string = environment.apiUrl + "permission/search";
    public static GET_PERMISSION_BY_ID : string = environment.apiUrl + "permission/get";
    public static DELETE_PERMISSION_BY_ID : string = environment.apiUrl + "permission/delete";
    public static SAVE_OR_UPDATE_PERMISSION : string = environment.apiUrl + "permission/save";

    public static SEARCH_ROLE : string = environment.apiUrl + "admin/role/list";
    public static GET_ROLE_BY_ID : string = environment.apiUrl + "admin/role/get";
    public static GET_ALL_ROLE : string = environment.apiUrl + "admin/role/list";
    public static DELETE_ROLE_BY_ID : string = environment.apiUrl + "admin/role/delete";
    public static SAVE_OR_UPDATE_ROLE : string = environment.apiUrl + "admin/role/save";

    public static SEARCH_SUB_ADMIN: string = environment.apiUrl + "admin/list";
    public static GET_SUB_ADMIN_BY_ID : string = environment.apiUrl + "admin/get";
    public static DELETE_SUB_ADMIN_BY_ID : string = environment.apiUrl + "admin/econUser/delete";
    public static SAVE_OR_UPDATE_SUB_ADMIN : string = environment.apiUrl + "admin/save";
    public static GET_LOGIN_USER_PROFILE : string = environment.apiUrl + "user/getProfile";

    public static SEARCH_APP_USER : string = environment.apiUrl + "users";    
    public static SEARCH_USER_TRANSACTION: string = environment.apiUrl + "admin/transaction/list";    
    public static REQUEST_DOC_LEVEL_LIST: string = environment.apiUrl + "admin/user/list/requestedDocLevel";
    public static GET_ALL_USER_LIST: string = environment.apiUrl + "admin/user/list-without-pagination";    
    public static GET_APP_USER_BY_ID : string = environment.apiUrl + "admin/user/get";
    public static GET_APP_USER_DOCUMENT_BY_ID: string = environment.apiUrl + "admin/user/documents";
    public static GET_APP_USER_DOCUMENT: string = environment.apiUrl + "admin/user/document";
    public static DELETE_APP_USER_BY_ID : string = environment.apiUrl + "admin/user/delete";
    public static SAVE_OR_UPDATE_APP_USER: string = environment.apiUrl + "admin/user/create";
    public static VIEW_DOCUMENT_APP_USER: string = environment.apiUrl + "admin/user/allDocuments";
    public static POST_DELETE_DOCUMENT: string = environment.apiUrl + "user/document/delete";
    
    

    public static SEARCH_BANNER : string = environment.apiUrl + "admin/banner/search";
    public static GET_BANNER_BY_ID: string = environment.apiUrl + "admin/banner/get";
    public static DELETE_BANNER_BY_ID: string = environment.apiUrl + "admin/banner/delete";
    public static SAVE_OR_UPDATE_BANNER : string = environment.apiUrl + "admin/banner/save";
    public static UPDATE_EXTERNAL_ID: string = environment.apiUrl + "admin/user/updateExternalId";
    public static UPDATE_USER_EXTERNAL_ID: string = environment.apiUrl + "admin/updateExternalId";
    

    public static SEARCH_CMS_PAGES : string = environment.apiUrl + "admin/cmsPages/search";
    public static GET_CMS_PAGES_BY_ID: string = environment.apiUrl + "user/cmsPages/get";
    public static UPDATE_CMS_PAGES : string = environment.apiUrl + "admin/cmsPages/update";

    public static SEARCH_NOTIFICATION: string = environment.apiUrl + "admin/notification/list";
    public static SEND_NOTIFICATION: string = environment.apiUrl + "admin/notification/send";
    public static SAVE_SETTINGS: string = environment.apiUrl + "admin/settings/update";
}

export class ROUTS{
    public static SIGN_IN : string = 'signin';
    public static FORGOT_PASSWORD : string = 'forgot';
    public static RESET_PASSWORD : string = 'reset-password';
    public static VERIFY_USER : string = 'verify-user';
    public static DASHBOARD : string = 'dashboard';

    public static NOT_AUTHORIZED = 'not-authorized';
    public static ERROR_401 = 'error401';
    public static ERROR_404 = 'error';
    public static MAINTENANCE = 'maintenance';
}