<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section id="sub-admin-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body pb-1">


                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="d-flex justify-content-between align-items-center m-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25"
                                                (change)="changePageSize(basicSelectedOption)"
                                                [(ngModel)]="basicSelectedOption">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            entries</label>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6 col-12 d-flex justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                                        <label class="d-flex align-items-center">Search
                                        <input type="search" placeholder="Search" class="form-control ml-25"
                                                [(ngModel)]="fetchAppUserListParam.searchString" (keyup)="searchAppUserBySearchBox()" />
                                        </label>
                                    </div>
                                </div> -->
                            </div>

                            <ngx-datatable [rows]="userdata" [rowHeight]="58" class="bootstrap core-bootstrap"
                                [headerHeight]="40" [footerHeight]="50" [externalSorting]="true" (sort)="onSort($event)"
                                [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber"
                                [limit]="page.size" (page)="setPage($event)" [scrollbarH]="true">

                                <ngx-datatable-column name="S No." [width]="100">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        {{rowIndex + pageStart}}
                                    </ng-template>
                                </ngx-datatable-column>  
                                
                                <ngx-datatable-column name="User Name" prop="level" [width]="250">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span>
                                            {{row.econUserFirstName}} {{row.econUserLastName}}
                                        </span>                                                                                                                  
                                    </ng-template>
                                </ngx-datatable-column>                               

                                <ngx-datatable-column name="User Level" prop="level" [width]="250"></ngx-datatable-column>
                                
                                <ngx-datatable-column name="Document" [width]="250">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span>
                                            <a target="_blank" href="{{row.documentPath}}">Link</a>
                                        </span>                                                                                                                  
                                    </ng-template>
                                </ngx-datatable-column>  
                                
                                <ngx-datatable-column name="Supervisor Name" prop="level" [width]="250">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span>
                                            {{row.createdByFirstName}} {{row.createdByLastName}}
                                        </span>                                                                                                                  
                                    </ng-template>
                                </ngx-datatable-column>
                                
                                <ngx-datatable-column name="Date" [width]="250">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span>
                                            {{row.createdDate | date: 'dd/MM/yyyy hh:mm a'}}
                                        </span>                                                                                                                  
                                    </ng-template>
                                </ngx-datatable-column>
                               
                            </ngx-datatable>                            

                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>