import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { APIClientService } from "./apiclient.service";
import { URL } from "./service.assets";

@Injectable({ providedIn: 'root' })
export class SubAdminService {

    constructor(private _apiClientService: APIClientService){}

    /**
     * search role
     * @param fetchRoleListParam 
     * @param isActive 
     * @param isDeleted 
     * @returns 
     */
    searchSubAdmin(fetchSubAdminListParam : any,isActive : any, isDeleted : any){
        // const formData = new FormData();
        var urlencoded = new URLSearchParams();
        urlencoded.append('name', fetchSubAdminListParam.searchString);
        urlencoded.append('direction', fetchSubAdminListParam.direction);
        urlencoded.append('sortBy', fetchSubAdminListParam.sortBy);
        urlencoded.append('limit', fetchSubAdminListParam.limit);
        urlencoded.append('pageNo', fetchSubAdminListParam.pageNo);
        urlencoded.append('isDeleted', isDeleted);
        urlencoded.append('isActvie', isActive);
        urlencoded.append('mobileNumber', fetchSubAdminListParam.searchString);
        urlencoded.append('email', fetchSubAdminListParam.searchString);
        urlencoded.append('role', fetchSubAdminListParam.searchString);

        return this._apiClientService.doPostXURL(URL.SEARCH_SUB_ADMIN,urlencoded).pipe(
			    map(response => response)
		    );
    }

    /**
     * get roleby id
     * @param id 
     * @returns 
     */
    getSubAdminById(id : any){
      var urlencoded = new URLSearchParams();
      urlencoded.append('userId', id);
      return this._apiClientService.doPostXURL(URL.GET_SUB_ADMIN_BY_ID, urlencoded).pipe(
			  map(response => response)
		  );
    }

    /**
     * delete role by id
     * @param id 
     * @returns 
     */
    deleteSubAdminId(id : any){
      
      const formData = new FormData();
      formData.append('uId', id);
      return this._apiClientService.doPost(URL.DELETE_SUB_ADMIN_BY_ID,formData).pipe(
			  map(response => response)
		  );
    }
    
    /**
     * save or update role
     * @param formData 
     * @returns 
     */
    saveOrUpdateSubAdmin(formData : any){
        return this._apiClientService.doPostRaw(URL.SAVE_OR_UPDATE_SUB_ADMIN,formData).pipe(
			map(response => response)
		);
    }

    getLoginUserProfile(){
      return this._apiClientService.doGet(URL.GET_LOGIN_USER_PROFILE).pipe(
			  map(response => response)
		  )
    }
}