import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { APIClientService } from "./apiclient.service";
import { URL } from "./service.assets";

@Injectable({ providedIn: 'root' })
export class PermissionService {

    constructor(private _apiClientService: APIClientService){}

    /**
     * search permission
     * @param fetchUserListParam 
     * @param isActive 
     * @param isDeleted 
     * @returns 
     */
    searchPermission(fetchUserListParam : any,isActive : any, isDeleted : any){
        const formData = new FormData();
        formData.append('name', fetchUserListParam.name);
        formData.append('direction', fetchUserListParam.direction);
        formData.append('sortBy', fetchUserListParam.sortBy);
        formData.append('limit', fetchUserListParam.limit);
        formData.append('pageNo', fetchUserListParam.pageNo);
        formData.append('isDeleted', isDeleted);
        formData.append('isActvie', isActive);

        return this._apiClientService.doPost(URL.SEARCH_PERMISSION,formData).pipe(
			    map(response => response)
		    );
    }

    /**
     * get permission by id
     * @param id 
     * @returns 
     */
    getPermissionById(id : any){
      
      return this._apiClientService.doGet(URL.GET_PERMISSION_BY_ID+"?pId="+id).pipe(
			  map(response => response)
		  );
    }

    /**
     * delete permission by id
     * @param id 
     * @returns 
     */
    deletePermissionById(id : any){
      
      const formData = new FormData();
      formData.append('pId', id);
      return this._apiClientService.doPost(URL.DELETE_PERMISSION_BY_ID,formData).pipe(
			  map(response => response)
		  );
    }
    /**
     * save or update permission
     * @param formData 
     * @returns 
     */
    saveOrUpdatePermission(formData : any){
        return this._apiClientService.doPost(URL.SAVE_OR_UPDATE_PERMISSION,formData).pipe(
			map(response => response)
		);
    }
}