<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section id="manage-app-user-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body pb-1">
                            <div class="row">
                                <!-- <div class="col-12 mb-50">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <button class="btn btn-primary ml-1"  rippleEffect [routerLink]="['add']">
                                                <i data-feather="plus" class="mr-0 mr-sm-1"></i><span
                                                    class="d-none d-sm-inline-block">Add New
                                                    App User</span>
                                            </button>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-6 col-12">
                                    <div class="d-flex justify-content-between align-items-center m-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25"
                                                (change)="changePageSize(basicSelectedOption)"
                                                [(ngModel)]="basicSelectedOption">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            entries</label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                                    <div
                                        class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                                        <label class="d-flex align-items-center">Search<input type="search"
                                                placeholder="Search" class="form-control ml-25"
                                                [(ngModel)]="fetchAppUserListParam.searchString"
                                                (keyup)="searchAppUserBySearchBox()" /></label>
                                    </div>
                                </div>
                            </div>
                            <ngx-datatable [rows]="data" [rowHeight]="58" class="bootstrap core-bootstrap"
                                [headerHeight]="45" [footerHeight]="50"
                                [externalSorting]="true"
                                (sort)="onSort($event)"                                
                                [externalPaging]="true"
                                [count]="page.totalElements"
                                [offset]="page.pageNumber"
                                [limit]="page.size"
                                (page)="setPage($event)"
                                [scrollbarH]="true">
                                <ngx-datatable-column name="S No." [sortable]="false" [minWidth]="250">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        {{rowIndex + 1}}
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="First Name" [minWidth]="350">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <!-- <a title="Edit user" href="javascript:void(0)" (click)="editSelectedAppUser(row._id)"> -->
                                            {{row.firstname}} 
                                        <!-- </a> -->
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Last Name" [minWidth]="350">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <!-- <a title="Edit user" href="javascript:void(0)" (click)="editSelectedAppUser(row._id)"> -->
                                            {{row.lastname}}
                                        <!-- </a> -->
                                    </ng-template>
                                </ngx-datatable-column>

                                                         

                                <ngx-datatable-column name="email" [minWidth]="350">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span *ngIf="row.email">
                                            {{row.email}}
                                        </span>
                                    </ng-template>                                    
                                </ngx-datatable-column>

                               

                               

                                <ngx-datatable-column *ngIf="isEditButton || isDeleteButton" name="Actions" prop="actions" [minWidth]="0" [sortable]="false">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <ul>
                                            <li>
                                                <a title="View document" href="javascript:void(0)" (click)="viewDocument(row.id)"><span
                                                        [data-feather]="'book'" [size]="16" [class]="'mr-50'"></span></a>
                                            </li>
                                            <li>
                                                <a title="View transaction" href="javascript:void(0)" (click)="viewTransaction(row.id)"><span
                                                        [data-feather]="'file'" [size]="16" [class]="'mr-50'"></span></a>
                                            </li>                                            
                                            <li>
                                                <a title="Delete user" href="javascript:void(0)" *ngIf="isDeleteButton" (click)="deleteSelectedAppUser(row.id)"><span
                                                        [data-feather]="'trash'" [size]="16"
                                                        [class]="'mr-50'"></span></a>
                                            </li>
                                        </ul>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>