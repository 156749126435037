import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { SampleComponent } from './sample.component';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from 'app/auth/helpers';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ManagePermissionComponent } from './permission/manage-permission/manage-permission.component';
import { AddPermissionComponent } from './permission/add-permission/add-permission.component';
import { ManageRoleComponent } from './role/manage-role/manage-role.component';
import { AddRoleComponent } from './role/add-role/add-role.component';
import { ManageSubAdminComponent } from './sub-admin/manage-sub-admin/manage-sub-admin.component';
import { AddSubAdminComponent } from './sub-admin/add-sub-admin/add-sub-admin.component';
import { ManageAppUserComponent } from './app-user/manage-app-user/manage-app-user.component';
import { AddAppUserComponent } from './app-user/add-app-user/add-app-user.component';
import { AddBannerComponent } from './banner/add-banner/add-banner.component';
import { ManageBannerComponent } from './banner/manage-banner/manage-banner.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ManageCmsPageComponent } from './cms-page/manage-cms-page/manage-cms-page.component';
import { AddCmsPageComponent } from './cms-page/add-cms-page/add-cms-page.component';
import { QuillModule } from 'ngx-quill'
import { BlockUIModule } from 'ng-block-ui';
import { ManageNotificationComponent } from './notification/manage-notification/manage-notification.component';
import { SendNotifcationComponent } from './notification/send-notification/send-notification.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SettingsComponent } from './settings/settings.component';
import { ViewDocumentComponent } from './app-user/view-document/view-document.component';
import { ManageTransactionComponent } from './transaction/manage-transaction/manage-transaction.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ViewUpdateUserListComponent } from './app-user/view-update-user-list/view-update-user-list.component';

const routes = [
  {
    path: 'sample',
    component: SampleComponent,
    data: { animation: 'sample' },
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { animation: 'dashboard' },
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'admin/permission',
  //   component: ManagePermissionComponent,
  //   data: { animation: 'managePermission' },
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'admin/permission/add',
  //   component: AddPermissionComponent,
  //   data: { animation: 'addPermission' },
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'admin/role',
    component: ManageRoleComponent,
    data: { animation: 'manageRole' },
    canActivate: [AuthGuard],
    
  },
  {
    path: 'admin/role/add',
    component: AddRoleComponent,
    data: { animation: 'addRole' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/roleUser/add',
    component: AddSubAdminComponent,
    data: { animation: 'addSubAdmin' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/roleUser',
    component: ManageSubAdminComponent,
    data: { animation: 'manageSubAdmin' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/appUser/add',
    component: AddAppUserComponent,
    data: { animation: 'addAppUser' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/appUser/viewDocument',
    component: ViewDocumentComponent,
    data: { animation: 'addAppUser' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/appUser',
    component: ManageAppUserComponent,
    data: { animation: 'manageAppUser' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/updateUserBySupervisor',
    component: ViewUpdateUserListComponent,
    data: { animation: 'manageAppUser' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/transaction',
    component: ManageTransactionComponent,
    data: { animation: 'manageTransaction' },
    canActivate: [AuthGuard]
  },  
  {
    path: 'admin/banner/add',
    component: AddBannerComponent,
    data: { animation: 'addBanner' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/banner',
    component: ManageBannerComponent,
    data: { animation: 'manageBanner' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/cmsPages/add',
    component: AddCmsPageComponent,
    data: { animation: 'addCmsPage' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/cmsPages',
    component: ManageCmsPageComponent,
    data: { animation: 'manageCmsPages' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/notification',
    component: ManageNotificationComponent,
    data: { animation: 'notificationPages' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/notification/send',
    component: SendNotifcationComponent,
    data: { animation: 'sendNotification' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/settings',
    component: SettingsComponent,
    data: { animation: 'settingsPages' },
    canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [SampleComponent, DashboardComponent, ManagePermissionComponent, AddPermissionComponent, ManageRoleComponent, AddRoleComponent, ManageSubAdminComponent, AddSubAdminComponent, ManageAppUserComponent, AddAppUserComponent, AddBannerComponent, ManageBannerComponent, ManageCmsPageComponent, AddCmsPageComponent, ManageNotificationComponent, SendNotifcationComponent, SettingsComponent, ViewDocumentComponent, ManageTransactionComponent, ViewUpdateUserListComponent],
  imports: [BlockUIModule.forRoot(), QuillModule.forRoot(), ImageCropperModule, RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule, NgxDatatableModule, NgSelectModule, AutocompleteLibModule],
  exports: [SampleComponent, DashboardComponent, ManagePermissionComponent, AddPermissionComponent, ManageRoleComponent, AddRoleComponent, ManageSubAdminComponent, AddSubAdminComponent, ManageAppUserComponent, AddAppUserComponent, AddBannerComponent, ManageBannerComponent, ManageCmsPageComponent, AddCmsPageComponent]
})
export class SampleModule {}
