import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUserService } from 'app/auth/service/app-user.service';
import { CommonService } from 'app/auth/service/common.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SendNotifcationComponent implements OnInit {

  constructor(private _activeRouter : ActivatedRoute,
              private _appUserService : AppUserService,
              private _commonService : CommonService,
              private formBuilder: FormBuilder,
              private _route: Router) { }
  
  private appUserId : any;
  public contentHeader: object;

    public ReactiveUDFormSubmitted = false;
    public submitted = false;
    notitype ='bydevice';
    // Reactive User Details form data
    public UDForm:any = {}
    @BlockUI() blockUI: NgBlockUI;

  async ngOnInit() {
    this.contentHeader = {
      headerTitle: 'Notification',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Notification',
            isLink: true,
            link: '../'
          },
          {
            name: 'send',
            isLink: false
          }
        ]
      }
    }
  }
  selectMulti:any[] =[];
  // select Basic Multi
  public selectMultiSelected = [];

  customSearchFn($event) {
    console.log($event);
    let payload ={
      searchString: $event.term,
      pageNo:1,
      limit:10,
      direction:'desc',
      sortBy:'createdDate'
    }
    this._appUserService.searchAppUser(payload, true, false).subscribe(async (results) => {
      this.selectMulti  =[];
      results.data.content.forEach(element => {
        this.selectMulti.push({
          id: element.id,
          name: element.firstName + ' ' + element.lastName
        })
      });
    }, (error) => {
      this.blockUI.stop();
      this._commonService.checkError(error);
    });
  }

  sendNotification(){
    let paylod;
    if (this.UDForm.devicetype != 'user') {
      paylod = {
        "deviceTypes": [this.UDForm.devicetype],
        "title": this.UDForm.title,
        "message": this.UDForm.message
      }
    }else{
      console.log(this.selectMultiSelected)
      paylod = {
        "userIds": this.selectMultiSelected,
        "title": this.UDForm.title,
        "message": this.UDForm.message
      }
    }
    this.blockUI.start();
    this._appUserService.sendnotification(paylod).subscribe(async (results) => {
      this.blockUI.stop();
      this._commonService.checkSuccess(results);
      this._route.navigate(["admin/notification"]);
    }, (error) => {
      this.blockUI.stop();
      this._commonService.checkError(error);
    });
  }
  
 

}
