<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <section id="manage-permission-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body pb-1">
              <form [formGroup]="addRoleForm" #RoleForm="ngForm" (ngSubmit)="submit(RoleForm)">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="name">Name</label>
                      <input type="text" id="name"  formControlName="name"
                        class="form-control" name="name" [(ngModel)]="name"
                        [ngClass]="{ 'is-invalid': submitted && addRoleForm.controls.name.errors }" />
                      <div *ngIf="submitted && addRoleForm.controls.name.errors" class="invalid-feedback">
                        <div *ngIf="addRoleForm.controls.name.errors.required">Name is required</div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-control-label" for="Gurd Name">Gurd Name</label>
                      <input class="form-control input-md" ngModel #GurdNameRef="ngModel" required id="GurdName" name="GurdName"
                        type="text" placeholder="Gurd Name">
                      <span *ngIf="RoleForm.submitted && GurdNameRef.invalid" class="invalid-form">
                        <small class="form-text text-muted danger" *ngIf="GurdNameRef.errors.required"> Gurd Name is
                          required!</small>
                      </span>
                    </div>
                  </div> -->
                </div>
                <div class="col-12">
                  <div class="table-responsive border rounded mt-1">
                    <h6 class="py-1 mx-1 mb-0 font-medium-2 header-align-block">
                      <i data-feather="lock" [size]="20" class="mr-25 all-header-component"></i>
                      <span class="align-middle all-header-component">Permission</span>
                      <div class="custom-control custom-checkbox all-header-component check-box-set-right">
                        <input type="checkbox" class="custom-control-input" id="all" (change)="onSelectAll($event)"/>
                        <label class="custom-control-label" for="all"></label>
                      </div>
                    </h6>
                    
                    <table class="table table-striped table-borderless">
                      <thead class="thead-light">
                        <tr>
                          <th>Module</th>
                          <th>View</th>
                          <th>Update</th>
                          <th>Add</th>
                          <th>Delete</th>
                          <th>ALL</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Role</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="KMgwbdldzjjI5WxMOiQwiA" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="KMgwbdldzjjI5WxMOiQwiA"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="J42N7ZeVmutmiwy9VMJJVA" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="J42N7ZeVmutmiwy9VMJJVA"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="Y9Qfkovo1K1Ny-pTgcvQtg" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="Y9Qfkovo1K1Ny-pTgcvQtg"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="QvsXBAU_3nNT282k6_cE-Q" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="QvsXBAU_3nNT282k6_cE-Q"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="role-all" (change)="onSelectAllRole($event)" />
                              <label class="custom-control-label" for="role-all"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Role User</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="eKSaoLqDuDmkzjszLrscGg" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="eKSaoLqDuDmkzjszLrscGg"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="Fi_5SsL-r5M2m3lKX6IDEA" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="Fi_5SsL-r5M2m3lKX6IDEA"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="Mz3-wfIzCxf-pLSUbUHGJg" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="Mz3-wfIzCxf-pLSUbUHGJg"></label>
                            </div>
                          </td>
                          
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="23awvN6TvR72Y0TtidvB6A" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="23awvN6TvR72Y0TtidvB6A"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="role-user-all" (change)="onSelectAllRoleUser($event)"/>
                              <label class="custom-control-label" for="role-user-all"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>App User</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="YA06gXB678riRCaN6HjzUg" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="YA06gXB678riRCaN6HjzUg"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="VPsGZ4h6s3XE1PRdAL6YRA" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="VPsGZ4h6s3XE1PRdAL6YRA"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="d6wN1H3VzISyHydACsHcCw" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="d6wN1H3VzISyHydACsHcCw"></label>
                            </div>
                          </td>
                          
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="TTBcCcN5a2Rat-JnL6DKcA" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="TTBcCcN5a2Rat-JnL6DKcA"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="app-user-all" (change)="onSelectAllAppUser($event)"/>
                              <label class="custom-control-label" for="app-user-all"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Banner</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="Me7Wze8DsPESKZJ8ZESZQQ" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="Me7Wze8DsPESKZJ8ZESZQQ"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="nUp8TDGaXDHBfsG9Bs7t9Q" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="nUp8TDGaXDHBfsG9Bs7t9Q"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="tSyaFNTTzsGns5MPbgXGqg" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="tSyaFNTTzsGns5MPbgXGqg"></label>
                            </div>
                          </td>
                          
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="wMQADFfVE_MxRJQ-roPM2g" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="wMQADFfVE_MxRJQ-roPM2g"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="banner-all" (change)="onSelectAllBanner($event)"/>
                              <label class="custom-control-label" for="banner-all"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Manage CMS</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="VLGMQhJjXln--4OysIKehg" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="VLGMQhJjXln--4OysIKehg"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="rVcIlYPhxCE3chcZPpHKAA" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="rVcIlYPhxCE3chcZPpHKAA"></label>
                            </div>
                          </td>
                          <td>
                            
                          </td>
                          
                          <td>
                            
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="cms-pages-all" (change)="onSelectAllCmsPages($event)"/>
                              <label class="custom-control-label" for="cms-pages-all"></label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Transaction</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="nsp04ykIziTFeWHgqfqu6A" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="nsp04ykIziTFeWHgqfqu6A"></label>
                            </div>
                          </td>

                          <td>                            
                          </td>

                          <td>                            
                          </td>
                          
                          <td>                            
                          </td>

                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="transaction-all" (change)="onSelectAllTransactionPages($event)"/>
                              <label class="custom-control-label" for="transaction-all"></label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Setting</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="6pqYaWan5gV2iY69NCYcQA" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="6pqYaWan5gV2iY69NCYcQA"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="O5xx8dsKkXlcbRKOaHLwmA" (change)="onSingleCheckBox($event)"/>
                              <label class="custom-control-label" for="O5xx8dsKkXlcbRKOaHLwmA"></label>
                            </div>
                          </td>

                          <td>                            
                          </td>
                          
                          <td>                            
                          </td>

                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="setting-pages-all" (change)="onSelectAllSettingPages($event)"/>
                              <label class="custom-control-label" for="setting-pages-all"></label>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-12 d-flex flex-sm-row flex-column mt-2">
                  <button type="submit" class="btn btn-primary" rippleEffect>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>