import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { APIClientService } from "./apiclient.service";
import { URL } from "./service.assets";

@Injectable({ providedIn: 'root' })
export class RoleService {

    constructor(private _apiClientService: APIClientService){}

    /**
     * search role
     * @param fetchRoleListParam 
     * @param isActive 
     * @param isDeleted 
     * @returns 
     */
    searchRole(fetchRoleListParam : any,isActive : any, isDeleted : any){
        // const formData = new FormData();
        var urlencoded = new URLSearchParams();
        urlencoded.append('name', fetchRoleListParam.searchString);
        urlencoded.append('direction', fetchRoleListParam.direction);
        urlencoded.append('sortBy', fetchRoleListParam.sortBy);
        urlencoded.append('limit', fetchRoleListParam.limit);
        urlencoded.append('pageNo', fetchRoleListParam.pageNo);
        urlencoded.append('isDeleted', isDeleted);
        urlencoded.append('isActvie', isActive);
        urlencoded.append('gurdName', fetchRoleListParam.searchString);

        return this._apiClientService.doPostXURL(URL.SEARCH_ROLE,urlencoded).pipe(
			    map(response => response)
		    );
    }

    /**
     * get roleby id
     * @param id 
     * @returns 
     */
    getRoleById(id : any){
      var urlencoded = new URLSearchParams();
      urlencoded.append('encRoleId', id);
      return this._apiClientService.doPostXURL(URL.GET_ROLE_BY_ID, urlencoded).pipe(
			  map(response => response)
		  );
    }

    /**
     * delete role by id
     * @param id 
     * @returns 
     */
    deleteRoleById(id : any){
      
      const formData = new FormData();
      formData.append('encRoleId', id);
      return this._apiClientService.doPost(URL.DELETE_ROLE_BY_ID,formData).pipe(
			  map(response => response)
		  );
    }
    
    /**
     * save or update role
     * @param formData 
     * @returns 
     */
    saveOrUpdateRole(formData : any){
        return this._apiClientService.doPostRaw(URL.SAVE_OR_UPDATE_ROLE,formData).pipe(
			map(response => response)
		);
    }

    /**
     * get all active role
     * @returns 
     */
    getAllRole(){
      var urlencoded = new URLSearchParams();
      urlencoded.append('limit', '100');
      urlencoded.append('pageNo','1');
      return this._apiClientService.doPostXURL(URL.GET_ALL_ROLE, urlencoded).pipe(
			  map(response => response)
		  );
    }
     
    /**
     * save or update role
     * @param formData 
     * @returns 
     */
    updateSettings(formData : any){
      return this._apiClientService.doPostRaw(URL.SAVE_SETTINGS,formData).pipe(
			map(response => response)
		);
    }
}