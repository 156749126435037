import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsPagesService } from 'app/auth/service/cmsPages.service';
import { CommonService } from 'app/auth/service/common.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-add-cms-page',
  templateUrl: './add-cms-page.component.html',
  styleUrls: ['./add-cms-page.component.scss']
})
export class AddCmsPageComponent implements OnInit {

  constructor(private _activeRouter: ActivatedRoute,
    private _cmsPagesService: CmsPagesService,
    private _commonService: CommonService,
    private formBuilder: FormBuilder,
    private _route: Router) { }

  private cmsPageId: any;
  public contentHeader: object;
  public ReactiveUserDetailsForm: FormGroup;
  public ReactiveUDFormSubmitted = false;
  public submitted = false;

  public UDForm = {
    title: '',
    metaKey: '',
    metaDiscription: '',
    content: '',
    languageContent: '',
    isActive: '',
    isDelete: false
  };
  @BlockUI() blockUI: NgBlockUI;
  
  async ngOnInit() {
    this._activeRouter.queryParams.subscribe(params => {
      this.cmsPageId = params['id'] ? atob(params['id']) : null;
    });

    this.ReactiveUserDetailsForm = this.formBuilder.group({
      id: [null],
      metaKey: ['', Validators.required],
      metaDiscription: ['', Validators.required],
      title: ['', [Validators.required]],
      content: ['', [Validators.required]],
      languageContent: ['', [Validators.required]],
      isActive: [null],
      isDelete: [false],
    });

    await this.getCmsPagesDetailAndSetHeader();
  }

  getCmsPagesDetailAndSetHeader() {
    if (this.cmsPageId != undefined || this.cmsPageId != null) {
      this.contentHeader = {
        headerTitle: 'Manage CMS',
        actionButton: false,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'Mange CMS',
              isLink: true,
              link: '../'
            },
            {
              name: 'edit',
              isLink: false
            }
          ]
        }
      }
      this.blockUI.start();
      this.ReactiveUserDetailsForm.controls.id.setValue(this.cmsPageId);
      this._cmsPagesService.getCmsPagesById(this.cmsPageId).subscribe(async (results) => {
        this.blockUI.stop();
        this.ReactiveUserDetailsForm.controls.metaKey.setValue(results.data.metaKey);
        this.ReactiveUserDetailsForm.controls.metaDiscription.setValue(results.data.metaDiscription);
        this.ReactiveUserDetailsForm.controls.content.setValue(results.data.content);
        this.ReactiveUserDetailsForm.controls.languageContent.setValue(results.data.languageContent);
        this.ReactiveUserDetailsForm.controls.title.setValue(results.data.title);
        this.ReactiveUserDetailsForm.controls.isActive.setValue(results.data.isActive);
      }, (error) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
      });

    } else {
      this._route.navigate(['not-authorized']);
    }
  }


  ReactiveUDFormOnSubmit() {
    this.ReactiveUDFormSubmitted = true;
    this.submitted = true;
   
    // stop here if form is invalid
    if (this.ReactiveUserDetailsForm.invalid) {
      return;
    }

    if (this.cmsPageId != undefined || this.cmsPageId != null) {}else{
      var slug = this.ReactiveUserDetailsForm.value.title.replace(/\s+/g, '-').toLowerCase()
      this.ReactiveUserDetailsForm.value.slug = slug.replace(/\s/g, '');
    }    

    this.blockUI.start();
    this._cmsPagesService.updateCmsPages(this.ReactiveUserDetailsForm.value).subscribe(async (results) => {
      this.blockUI.stop();
      this._commonService.checkSuccess(results);
      this._route.navigate(["admin/cmsPages"]);
    }, (error) => {
      this.blockUI.stop();
      this._commonService.checkError(error);
    });
  }

  get ReactiveUDForm() {
    return this.ReactiveUserDetailsForm.controls;
  }

}
