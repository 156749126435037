<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      <section id="sub-admin-page">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body pb-1">
                  <!-- <div class="row pb-2">
                    <div class="col-3 custom-control custom-radio mr-1"  style="margin-left: 15px;">
                      <input type="radio" name="notitype" id="bydevice"  class="custom-control-input layout-name" value="bydevice"  [(ngModel)]="notitype"/>
                      <label class="custom-control-label" for="bydevice">By device type</label>
                    </div>
                    <div class="col-3 custom-control custom-radio mr-1">
                      <input type="radio" name="notitype" id="byuser"  class="custom-control-input layout-name" value="byuser" [(ngModel)]="notitype"/>
                      <label class="custom-control-label" for="byuser">By User</label>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-6 mb-1">
                      <label for="UDDeviceType">Device Type</label>
                      <select id="UDDeviceType" class="form-control" [(ngModel)]="UDForm.devicetype">
                        <option value="ANDROID">ANDROID</option>
                        <option value="IOS">IOS</option>
                        <option value="WEB">WEB</option>
                        <option value="user">Individual User</option>
                      </select>
                    </div>
                    <div class="col-6" *ngIf="UDForm.devicetype == 'user' ">
                      <label>Select User</label>
                    
                      <div class="form-group">
                        <!-- Basic Multi select -->
                        <ng-select [items]="selectMulti" [multiple]="true" [closeOnSelect]="false" [searchable]="true" bindLabel="name"
                          bindValue="id" placeholder="Search User" (search)="customSearchFn($event)" [(ngModel)]="selectMultiSelected">
                          <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-label"> {{ item.name }}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                          </ng-template>
                        </ng-select>
                        <!--/ Basic Multi select -->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDLastName">Title</label>
                        <input type="text" id="UDLastName" [(ngModel)]="UDForm.title" class="form-control" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDLastName">Message</label>
                        <textarea class="form-control" [(ngModel)]="UDForm.message"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <!-- <div class="col-6" *ngIf="notitype == 'bydevice' ">
                      <label for="UDDeviceType">Device Type</label>
                      <select id="UDDeviceType" class="form-control" [(ngModel)]="UDForm.devicetype">
                        <option value="ANDROID">ANDROID</option>
                        <option value="IOS">IOS</option>
                        <option value="ALL">ALL</option>
                      </select>
                    </div> -->
                    
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <button class="btn btn-primary" (click)="sendNotification()" rippleEffect>Submit</button>
                      </div>
                    </div>
                  </div>
                 
                
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </div>