<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      <section id="sub-admin-page">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body pb-1">
  
                <form [formGroup]="ReactiveUserDetailsForm" (ngSubmit)="ReactiveUDFormOnSubmit()">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDFirstName">First Name</label>
                        <input type="text" id="UDFirstName" [(ngModel)]="UDForm.firstName" formControlName="firstName"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.firstName.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.firstName.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.firstName.errors.required">First Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDLastName">Last Name</label>
                        <input type="text" id="UDLastName" formControlName="lastName" [(ngModel)]="UDForm.lastName"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.lastName.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.lastName.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.lastName.errors.required">Last Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDMotherName">Mother Name</label>
                        <input type="text" id="UDMotherName" formControlName="motherName" [(ngModel)]="UDForm.motherName" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.motherName.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.motherName.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.motherName.errors.required">Mother Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="!appUserId">
                      <div class="form-group">
                        <label for="UDAddress">Address</label>
                        <input type="text" id="UDAddress" formControlName="address" [(ngModel)]="UDForm.address"
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.address.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.address.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.address.errors.required">Address is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="appUserId">
                      <div class="form-group">
                        <div class="form-group file-upload">
                          <label for="workPosition">Address ID</label>
                          <input type="file" id="addressfile" (change)="handleAddressProofFileInput($event)" accept=".jpeg, .png, .jpg"
                            class="form-control" />                          
                          <img *ngIf="addressPicPath" class="document_image" [src]="addressPicPath" alt="profile pic">
                          <div *ngIf="addressProofError" class="invalid-feedback d-block">
                            <div>Address ID is required</div>
                          </div>
                        </div>
                      </div>
                    </div>                    
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDEmail">Email</label>
                        <input type="text" id="UDEmail" formControlName="email" [(ngModel)]="UDForm.email"
                        pattern="^[_\.0-9a-zA-Z-]+@([0-9a-zA-Z][0-9a-zA-Z-]+)+((\.)[a-zA-Z]{2,})+$"
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.email.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.email.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.email.errors.required">Email is required</div>
                          <div *ngIf="ReactiveUDForm.email.errors.email || ReactiveUDForm.email.errors.pattern" >Email must be a valid email address</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDMobileNumber">Mobile Number</label>
                        <input type="text" id="UDMobileNumber" pattern="^-?(0|[1-9]\d*)?$" formControlName="mobileNumber"
                          [(ngModel)]="UDForm.mobileNumber" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.mobileNumber.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.mobileNumber.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.mobileNumber.errors.required">Mobile Number is required</div>
                          <div *ngIf="ReactiveUDForm.mobileNumber.errors.pattern" >Please enter valid mobile number</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDNationality">Nationality</label>
                        <input type="text" id="UDNationality" formControlName="nationality" [(ngModel)]="UDForm.nationality" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.nationality.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.nationality.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.nationality.errors.required">Nationality is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDWorkactivity">Work Activity</label>
                        <input type="text" id="UDWorkactivity" formControlName="workactivity" [(ngModel)]="UDForm.workactivity" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.workactivity.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.workactivity.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.workactivity.errors.required">Work activity is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDWorkcompany">Work Company</label>
                        <input type="text" id="UDWorkcompany" formControlName="workcompany" [(ngModel)]="UDForm.workcompany" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.workcompany.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.workcompany.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.workcompany.errors.required">Work company is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDWorkposition">Work Position</label>
                        <input type="text" id="UDWorkposition" formControlName="workposition" [(ngModel)]="UDForm.workposition" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.workposition.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.workposition.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.workposition.errors.required">Work position is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDWorkposition">User Type</label>
                        <div class="row pb-2">
                          <div class="col-3 custom-control custom-radio mr-1" style="margin-left: 15px;">
                            <input type="radio" formControlName="userType" id="user" class="custom-control-input layout-name" value="1"
                              [(ngModel)]="UDForm.userType" />
                            <label class="custom-control-label" for="user">User</label>
                          </div>
                          <div class="col-3 custom-control custom-radio mr-1">
                            <input type="radio" formControlName="userType" id="cashier" class="custom-control-input layout-name" value="2"
                              [(ngModel)]="UDForm.userType" />
                            <label class="custom-control-label" for="cashier">Cashier</label>
                          </div>
                          <div *ngIf="submitted && ReactiveUDForm.userType.errors" class="invalid-feedback">
                            <div *ngIf="ReactiveUDForm.userType.errors.required">UserType is required</div>
                          </div>
                        </div>
                      </div>                      
                    </div>

                    <div class="col-6">
                      <div class="form-group">
                        <label for="Status">Status</label>
                        <select formControlName="isDelete" class="form-control" [(ngModel)]="UDForm.isDelete"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.isDelete.errors }">
                          <option value="0">Active</option>
                          <option value="1">Inactive</option>                          
                        </select>
                        <div *ngIf="submitted && ReactiveUDForm.isDelete.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.isDelete.errors.required">User status is required</div>
                        </div>
                      </div>
                    </div>


                    <!-- <div class="col-6">
                      <div class="form-group">
                        <label for="UDWorkposition">Status</label>
                        <div class="row pb-2">
                          <div class="col-3 custom-control custom-radio mr-1" style="margin-left: 15px;">
                            <input type="radio" formControlName="isDelete" id="active" class="custom-control-input layout-name" value="1"
                              [(ngModel)]="UDForm.isDelete" />
                            <label class="custom-control-label" for="Active">Active</label>
                          </div>
                          <div class="col-3 custom-control custom-radio mr-1">
                            <input type="radio" formControlName="isDelete" id="inactive" class="custom-control-input layout-name" value="2"
                              [(ngModel)]="UDForm.isDelete" />
                            <label class="custom-control-label" for="Inactive">Inactive</label>
                          </div>
                          <div *ngIf="submitted && ReactiveUDForm.isDelete.errors" class="invalid-feedback">
                            <div *ngIf="ReactiveUDForm.isDelete.errors.required">User status is required</div>
                          </div>
                        </div>
                      </div>                      
                    </div> -->
                    

                    <!-- <div class="col-6">
                      <div class="form-group">
                        <label for="UDRole">Role</label>
                        <select id="UDRole" formControlName="role" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.role.errors }">
                          <option [value]="role.id" *ngFor="let role of RoleList">{{role.name}}</option>
                        </select>
                        <div *ngIf="submitted && ReactiveUDForm.role.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.role.errors.required">Role is required</div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="col-6">
                      <div class="form-group">
                        <label for="UDDeviceType">DeviceType</label>
                        <select id="UDDeviceType" formControlName="deviceType" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.deviceType.errors }">
                          <option value="ANDROID">ANDROID</option>
                          <option value="IOS">IOS</option>
                        </select>
                        <div *ngIf="submitted && ReactiveUDForm.deviceType.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.deviceType.errors.required">DeviceType is required</div>
                        </div>
                      </div>
                    </div> -->

                      <div class="col-6">
                        <div class="form-group">
                          <label for="UDCountryCode">Country Code</label>
                          <select id="UDCountryCode" formControlName="countryCode" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.countryCode.errors }">
                            <option value="+1">USA (+1)</option>
                            <option value="+52">Mexico (+52)</option>
                            <option value="+91">India (+91)</option>
                          </select>
                          <div *ngIf="submitted && ReactiveUDForm.countryCode.errors" class="invalid-feedback">
                            <div *ngIf="ReactiveUDForm.countryCode.errors.required">Country Code is required</div>
                          </div>
                        </div>
                      </div>
                    <!-- <div class="col-6">
                      <div class="form-group">
                        <label for="UDCountryCode">Country Code</label>
                        <input type="text" id="UDCountryCode" formControlName="countryCode"
                          [(ngModel)]="UDForm.countryCode" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.countryCode.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.countryCode.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.countryCode.errors.required">Country Code is required</div>
                        </div>
                      </div>
                    </div> -->

                    <div class="col-6" *ngIf="!appUserId">
                      <div class="form-group">
                        <label for="Up Level">Up Level</label>
                        <select formControlName="level" class="form-control" [(ngModel)]="UDForm.level"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.level.errors }">
                          <option value="1">Level 1</option>
                          <option value="2">Level 2</option>                          
                        </select>
                        <div *ngIf="submitted && ReactiveUDForm.level.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.level.errors.required">Level is required</div>
                        </div>
                      </div>
                    </div>

                    

                    <div class="col-6" *ngIf="appUserId">
                      <label for="workPosition">Other Document</label>
                      <input type="file" id="file" (change)="handleDocumentFirstFileInput($event)" accept=".jpeg, .png, .jpg"
                        class="form-control" />                                            
                      <div *ngIf="addressProofError" class="invalid-feedback d-block">
                        <div>Other Document is required</div>
                      </div>
                    </div>

                    <div class="col-6" *ngIf="appUserId">
                      <div class="form-group">
                        <label for="Up Level">Level Up Request</label>
                        <select formControlName="levelStatus" class="form-control" [(ngModel)]="UDForm.levelStatus"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.levelStatus.errors }" (change)="handleLevelUpInput($event)" >                          
                          <option value="6">Level 1 Approve</option>
                          <option value="7">Level 1 Reject</option>
                          <option value="2">Level 2 Approve</option>
                          <option value="3">Level 2 Reject</option>
                        </select>
                        <div *ngIf="submitted && ReactiveUDForm.levelStatus.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.levelStatus.errors.required">Level Request is required</div>
                        </div>
                      </div>

                      <div class="form-group" *ngIf="statusForLevel == 3 || statusForLevel == 7">
                        <label for="Reject Reason">Reject Reason</label>
                        <textarea formControlName="reason" class="form-control" [(ngModel)]="UDForm.reason"></textarea>
                        <!-- <div *ngIf="submitted && ReactiveUDForm.level.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.level.errors.required">Level is required</div>
                        </div> -->
                      </div>
                    </div>                    

                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <div *ngIf="addressPicPath">
                            <h4>Level 1 Document : </h4>
                            <div class="address-image">                             
                              <a target="_blank" download href="{{addressPicPath}}"> {{addressPicName}} </a>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div *ngIf="level1_array && level1_array.length > 0">
                            <h4>Level 2 Document :</h4>
                            <div *ngFor="let data of level1_array; let i = index">
                              Document {{i+1}} : <a target="_blank" download href="{{data.documentPath}}"> {{data.document}} </a>
                              <span class="delete-icon" (click)="deleteDocument(data.documentId)"><i class="fa fa-trash">&nbsp;</i></span>
                            </div>
                          </div>
                        </div>                        
                      </div>                      
                    </div>                                      
                    
                    
                    <div class="col-12">
                      <div class="form-group">
                        <br />
                        <button class="btn btn-primary" rippleEffect>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
  
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </div>