import { Injectable } from "@angular/core";
import { CONSTANTS } from "./service.assets";

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

    /**
     * clear local storage
     */
     clearLocalStorage() {
        localStorage.clear();
    }

    /**
     * set value in local storage
     * @param key 
     * @param value 
     */
    setLocalStorage(key: string ,value: string ) {
        localStorage.setItem(key, value);
    }

    /**
     * remove item from local storage
     * @param key 
     */
    removeLocalStorage(key: string) {
        localStorage.removeItem(key);
    }

    /**
     * get value from local storage
     * @param key 
     * @returns 
     */
    getLocalStorage(key: string): any {
        if(localStorage.getItem(key)){
            return localStorage.getItem(key);
        } else {
            return null;
        }
    }

}