import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { ErrorComponent } from './error/error.component';

import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

// routing
const routes: Routes = [
  {
    path: 'error',
    component: ErrorComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
];

@NgModule({
  declarations: [ErrorComponent,NotAuthorizedComponent, MaintenanceComponent],
  imports: [ContentHeaderModule, NgbModule, NgSelectModule, FormsModule, CommonModule, RouterModule.forChild(routes), CoreCommonModule]
})
export class CommonPagesModule {}
