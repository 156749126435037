<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <section id="manage-role-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body pb-1">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>Property</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Value</th>                      
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of data">
                      <td>{{item.settingsProperty}}</td>
                      <td>{{item.propertyName}}</td>
                      <td>{{item.description}}</td>
                      <td>
                        <div class="form-group">
                          <input type="text" class="form-control" name="name" [(ngModel)]="item.value" />
                        </div>
                      <!-- <td>
                        <div class="form-group">
                          <input type="text" class="form-control" name="name" [(ngModel)]="item.value1" />
                        </div>
                      </td> -->
                      <td>
                        <button class="btn btn-primary" rippleEffect (click)="updateValue(item)">Update</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>