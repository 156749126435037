import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'app/auth/models/page';
import { CommonService } from 'app/auth/service/common.service';
import { CookieService } from 'app/auth/service/cookie.service';
import { CONSTANTS } from 'app/auth/service/service.assets';
import { SubAdminService } from 'app/auth/service/sub-admin.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { LocalStorageService } from 'app/auth/service/localstorage.service';

@Component({
  selector: 'app-manage-sub-admin',
  templateUrl: './manage-sub-admin.component.html',
  styleUrls: ['./manage-sub-admin.component.scss']
})
export class ManageSubAdminComponent implements OnInit {

  page = new Page();

  constructor(private _subAdminService : SubAdminService,
              private _commonService : CommonService,
              private _cookieService: CookieService,
              private _localStorageService: LocalStorageService,
              private _route : Router) { 
                this.page.pageNumber = 0;
                this.page.size = 10;
              }
  public contentHeader: object;
  public basicSelectedOption: number = 10;
  public data: any ;

  pageLimitOptions: any = [1000];
  SORT_BY: string[] = ['firstName'];
  isDirectionDesc: boolean = true;
  private searchPolicyUserObs: Subscription;
  public pageStart: any = 0;
  public isAddButton: boolean = false;
  public isEditButton: boolean = false;
  public isDeleteButton: boolean = false;

  fetchSubAdminListParam = {
		limit: 10,
		pageNo: 1,
		searchString: "",
		sortBy: "createdDate",
		direction: "desc",
	};

  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {

    const userRole = this._localStorageService.getLocalStorage(CONSTANTS.ROLE);    
    if(userRole == 'ROLE_MASTER'){  
      this.isAddButton = true;
      this.isEditButton = true;
      this.isDeleteButton = true;
    }else{

      let permission =  this._cookieService.getCookie(CONSTANTS.PERMISSION);
        if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('ADD_ROLE_USER')) >= 0){
          this.isAddButton = true;
        }
        if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('UPDATE_ROLE_USER')) >= 0){
          this.isEditButton = true;
        }
        if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('DELETE_ROLE_USER')) >= 0){
          this.isDeleteButton = true;
        }  
    }    

    this.contentHeader = {
      headerTitle: 'Role User',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Role User',
            isLink: false
          }
        ]
      }
    }
    this.searchSubAdmin(true);
  }
  /**
   * search sub admin
   * @returns 
   */
  searchSubAdmin(loader){
    if(loader){
      this.blockUI.start()
    }
    return new Promise( (resolve)=>{
      if(this.searchPolicyUserObs){
        this.searchPolicyUserObs.unsubscribe();
      }
      this.searchPolicyUserObs = this._subAdminService.searchSubAdmin(this.fetchSubAdminListParam,true,false).subscribe(async ( results ) => {
        this.blockUI.stop();
        this.data = results.data.content;
        this.pageStart = (results.data.pageable.pageSize * results.data.pageable.pageNumber) + 1;
        this.page.totalElements = results.data.totalElements;
        resolve("");
      },( error ) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
        resolve("");
      });
    });
  }
  /**
   * delete sub admin
   * @param id 
   */
  deleteSelectedSubAdmin(id : any ){
    swal.fire({
      title: 'Are you sure you want to remove role user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      if (result.value) {
        this._subAdminService.deleteSubAdminId(id).subscribe(async ( results ) => {
          this._commonService.checkSuccess(results);
          await this.searchSubAdmin(true);
        },( error ) => {
          this._commonService.checkError(error);
        });
      }
    });
    
  }
  /**
   * edit sub admin
   * @param id 
   */
  editSelectedSubAdmin(id : any){
    this._route.navigate(["admin/roleUser/add"], { queryParams: { id : btoa(id)}});
  }

  /**
   * change page size and find data and set page 0
   * @param id 
   */
  changePageSize(id : any){
    this.basicSelectedOption = id;
    this.fetchSubAdminListParam.limit = id;
    this.page.size = id;
    this.page.pageNumber = 1;
    this.fetchSubAdminListParam.pageNo = 1;
    this.searchSubAdmin(false);
  }
  /**
   * sort field name and set page 0
   * @param e 
   */
  onSort(e){
    this.fetchSubAdminListParam.direction = e.newValue;
    this.fetchSubAdminListParam.sortBy = e.column.prop;
    this.page.pageNumber = 1;
    this.fetchSubAdminListParam.pageNo = 1;
    this.searchSubAdmin(false);
  }
  /**
   * page change evnet set page number and find data
   * @param event 
   */
  setPage(event){
    this.page.pageNumber = event.offset;
    this.fetchSubAdminListParam.pageNo = event.offset;
    this.searchSubAdmin(false);
  }
  /**
   * search sub admin and set page 1
   */
  searchSubAdminBySearchBox(){
    this.page.pageNumber = 1;
    this.fetchSubAdminListParam.pageNo = 1;
    this.searchSubAdmin(false);
  }
}
