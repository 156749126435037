<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      <section id="cms-pages-page">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body pb-1">
  
                <form [formGroup]="ReactiveUserDetailsForm" (ngSubmit)="ReactiveUDFormOnSubmit()">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDTitle">Title</label>
                        <input type="text" id="UDTitle" [(ngModel)]="UDForm.title" formControlName="title"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.title.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.title.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.title.errors.required">Title is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDMetaKey">Meta Key</label>
                        <input type="text" id="UDMetaKey" formControlName="metaKey" [(ngModel)]="UDForm.metaKey"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.metaKey.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.metaKey.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.metaKey.errors.required">Meta Key is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDMetaDiscription">Meta Discription</label>
                        <input type="text" id="UDMetaDiscription" formControlName="metaDiscription"
                          [(ngModel)]="UDForm.metaDiscription" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.metaDiscription.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.metaDiscription.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.metaDiscription.errors.required">Meta Discription is required</div>
                        </div>
                      </div>
                    </div>
                    
                    <!-- <div class="col-6">
                        <div class="form-group">
                          <label for="UDContent">Content</label>
                          <input type="text" id="UDContent" formControlName="content"
                            [(ngModel)]="UDForm.content" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.content.errors }" />
                          <div *ngIf="submitted && ReactiveUDForm.content.errors" class="invalid-feedback">
                            <div *ngIf="ReactiveUDForm.content.errors.required">Content is required</div>
                          </div>
                        </div>
                    </div> -->
                    <div class="col-12">
                        <div class="form-group">
                            <label for="UDContent">English Language Content</label>
                          <quill-editor id="UDContent" formControlName="content"
                          [(ngModel)]="UDForm.content" [ngClass]="{ 'is-invalid error-editor': submitted && ReactiveUDForm.content.errors }"></quill-editor>
                          <div *ngIf="submitted && ReactiveUDForm.content.errors" class="invalid-feedback">
                            <div *ngIf="ReactiveUDForm.content.errors.required">Content is required</div>
                          </div>
                        </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="UDContent">Spanish Language Content</label>
                        <quill-editor id="UDContent" formControlName="languageContent" [(ngModel)]="UDForm.languageContent"
                          [ngClass]="{ 'is-invalid error-editor': submitted && ReactiveUDForm.languageContent.errors }"></quill-editor>
                        <div *ngIf="submitted && ReactiveUDForm.languageContent.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.languageContent.errors.required">Language Content is required</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">

                        <div class="custom-control custom-switch">
                            <p class="mb-50">Status</p>
                            <input type="checkbox"  formControlName="isActive" [(ngModel)]="UDForm.isActive"  class="custom-control-input" id="UDIsActive" />
                            <label class="custom-control-label" for="UDIsActive">
                              <span class="switch-icon-left"><i data-feather="check"></i></span>
                              <span class="switch-icon-right"><i data-feather="x"></i></span>
                            </label>
                          </div>
                        </div>
                    </div>

                    
                    
                    <div class="col-12">
                      <div class="form-group">
                        <button class="btn btn-primary" rippleEffect>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </div>