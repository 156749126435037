import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { APIClientService } from "./apiclient.service";
import { URL } from "./service.assets";

@Injectable({ providedIn: 'root' })
export class CmsPagesService {

    constructor(private _apiClientService: APIClientService){}

    /**
     * search cms pages
     * @param fetchCmsPagesListParam 
     * @param isActive 
     * @param isDeleted 
     * @returns 
     */
    searchCmsPages(fetchCmsPagesListParam : any, isDeleted : any){
        var urlencoded = new URLSearchParams();
        urlencoded.append('title', fetchCmsPagesListParam.searchString);
        urlencoded.append('direction', fetchCmsPagesListParam.direction);
        urlencoded.append('sortBy', fetchCmsPagesListParam.sortBy);
        urlencoded.append('limit', fetchCmsPagesListParam.limit);
        urlencoded.append('pageNo', fetchCmsPagesListParam.pageNo);
        urlencoded.append('isDeleted', isDeleted);
        // urlencoded.append('isActvie', isActive);
        urlencoded.append('metaDiscription', fetchCmsPagesListParam.searchString);
        urlencoded.append('metaKey', fetchCmsPagesListParam.searchString);

        return this._apiClientService.doPostXURL(URL.SEARCH_CMS_PAGES,urlencoded).pipe(
			    map(response => response)
		    );
    }

    /**
     * get cms page by id
     * @param id 
     * @returns 
     */
    getCmsPagesById(id : any){
      
      return this._apiClientService.doGet(URL.GET_CMS_PAGES_BY_ID+"?cId="+id).pipe(
			  map(response => response)
		  );
    }


    
    /**
     * save or update cms pages
     * @param formData 
     * @returns 
     */
    updateCmsPages(formData : any){
        
        return this._apiClientService.doPost(URL.UPDATE_CMS_PAGES,formData).pipe(
			map(response => response)
		);
    }
}