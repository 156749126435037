import { CoreMenu } from '@core/types'
import { CookieService } from 'app/auth/service/cookie.service'
import { LocalStorageService } from 'app/auth/service/localstorage.service'

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    // translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'dashboard'
  },
  {
    id: 'appUser',
    title: 'App User',
    // translate: 'MENU.SAMPLE',
    type: 'item',
    icon: 'users',
    url: 'admin/appUser',
    // hidden: CookieService.getShowAppUser()
  },
  // {
  //   id: 'appUser',
  //   title: 'User Changes',
  //   // translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'users',
  //   url: 'admin/updateUserBySupervisor',
  //   // hidden: CookieService.getShowAppUser()
  // },
  // {
  //   id: 'transaction',
  //   title: 'Transaction',
  //   // translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'file',
  //   url: 'admin/transaction',
  //   // hidden: CookieService.getShowBanner()
  // },
  // {
  //   id: 'banner',
  //   title: 'Banner',
  //   // translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'image',
  //   url: 'admin/banner',
  //   // hidden: CookieService.getShowBanner()
  // },
  // {
  //   id: 'cmsPages',
  //   title: 'Manage CMS',
  //   // translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'layout',
  //   url: 'admin/cmsPages',
  //   // hidden: CookieService.getShowCmsPages()
  // },
  // {
  //   id: 'role',
  //   title: 'Role',
  //   // translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'user',
  //   url: 'admin/role',
  //   // hidden: CookieService.getShowRole()
  // },
  // {
  //   id: 'roleUser',
  //   title: 'Role User',
  //   // translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'users',
  //   url: 'admin/roleUser',
  //   // hidden: CookieService.getShowRoleUser()
  // },
  // // {
  // //   id: 'notification',
  // //   title: 'Notification',
  // //   // translate: 'MENU.SAMPLE',
  // //   type: 'item',
  // //   icon: 'bell',
  // //   url: 'admin/notification',
  // //   // hidden: CookieService.getShowRoleUser()
  // // },
  // {
  //   id: 'settings',
  //   title: 'Settings',
  //   // translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'settings',
  //   url: 'admin/settings',
  //   //hidden: CookieService.getAdmin()
  // }
  // {
  //   id: 'sample',
  //   title: 'Sample',
  //   // translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'file',
  //   url: 'sample'
  // },
  // {
  //   id: 'permission',
  //   title: 'Permission',
  //   // translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'lock',
  //   url: 'admin/permission'
  // },
]
