<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      <section id="manage-cms-pages-page">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body pb-1">
                <div class="row">
                  <div class="col-12 mb-50">
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                      <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" (change)="changePageSize(basicSelectedOption)"
                          [(ngModel)]="basicSelectedOption">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries</label>
                    </div>
                  </div>
                  <div class="col-md-6 col-12 d-flex justify-content-md-end">
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                      <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                          class="form-control ml-25" [(ngModel)]="fetchCmsPagesListParam.searchString"
                          (keyup)="searchCmsPagesBySearchBox()" /></label>
                    </div>
                  </div>
                </div>
                <ngx-datatable [rows]="data" [rowHeight]="58" class="bootstrap core-bootstrap"
                  [externalSorting]="true"
                  (sort)="onSort($event)"
  
                  [externalPaging]="true"
                  [count]="page.totalElements"
                  [offset]="page.pageNumber"
                  [limit]="page.size"
                  (page)="setPage($event)"
                  [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true">
                  <ngx-datatable-column name="S No." [sortable]="false" [width]="50">
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                      {{rowIndex + pageStart}}
                    </ng-template>
                  </ngx-datatable-column>
                  
                  <ngx-datatable-column name="Title" prop="title" [width]="300"></ngx-datatable-column>
                  <ngx-datatable-column name="Meta Key" prop="metaKey" [width]="300"></ngx-datatable-column>
                  <ngx-datatable-column name="Meta Discription" prop="metaDiscription" [width]="300"></ngx-datatable-column>
  
                  <ngx-datatable-column *ngIf="isEditButton" name="Actions" prop="actions" [width]="200" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <ul>
                        <li>
                          <a href="javascript:void(0)" *ngIf="isEditButton" (click)="editSelectedCmsPage(row.id)"><span [data-feather]="'edit'"
                              [size]="16" [class]="'mr-50'"></span></a>
                        </li>
                      </ul>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </div>