import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'app/auth/models/page';
import { AppUserService } from 'app/auth/service/app-user.service';
import { CommonService } from 'app/auth/service/common.service';
import { CookieService } from 'app/auth/service/cookie.service';
import { CONSTANTS } from 'app/auth/service/service.assets';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { LocalStorageService } from 'app/auth/service/localstorage.service';

@Component({
  selector: 'app-manage-app-user',
  templateUrl: './manage-app-user.component.html',
  styleUrls: ['./manage-app-user.component.scss']
})
export class ManageAppUserComponent implements OnInit {

  page = new Page();

  constructor(private _appUserService : AppUserService,
              private _commonService : CommonService,
              private _cookieService: CookieService,
              private _localStorageService: LocalStorageService,
              private _route : Router) { 
                this.page.pageNumber = 0;
                this.page.size = 10;
              }
  public contentHeader: object;
  public basicSelectedOption: number = 10;
  public data: any ;

  pageLimitOptions: any = [1000];
  SORT_BY: string[] = ['firstName'];
  isDirectionDesc: boolean = true;
  private searchPolicyUserObs: Subscription;
  public pageStart: any = 0;
  public isAddButton: boolean = false;
  public isEditButton: boolean = false;
  public isDeleteButton: boolean = false;

  fetchAppUserListParam = {
		limit: 10,
		pageNo: 1,
		searchString: "",
		sortBy: "createdDate",
		direction: "desc",
	};
  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {
    let permission =  this._cookieService.getCookie(CONSTANTS.PERMISSION);
    const userRole = this._localStorageService.getLocalStorage(CONSTANTS.ROLE);    
    if(userRole == 'ROLE_MASTER'){   
      this.isAddButton = true;  
      this.isEditButton = true;
      this.isDeleteButton = true;
    }else{
      if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('ADD_APP_USER')) >= 0){
        this.isAddButton = true;
      }
      if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('UPDATE_APP_USER')) >= 0){
        this.isEditButton = true;
      }
      if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('DELETE_APP_USER')) >= 0){
        this.isDeleteButton = true;
      }
    }
    
    this.contentHeader = {
      headerTitle: 'App User',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'App User',
            isLink: false
          }
        ]
      }
    }
    this.searchAppUser(true);
  }
  /**
   * search sub admin
   * @returns 
   */
   searchAppUser(loader){
     if(loader){
      this.blockUI.start();
     }
    return new Promise( (resolve)=>{
      if(this.searchPolicyUserObs){
        this.searchPolicyUserObs.unsubscribe();
      }
      this.searchPolicyUserObs = this._appUserService.searchAppUser(this.fetchAppUserListParam,true,false).subscribe(async ( results ) => {
        this.blockUI.stop();
        this.data = results.response.data;
        this.pageStart = (results.response.pageSize * results.response.pageNumber) + 1;
        this.page.totalElements = results.response.totalDocuments;
        resolve("");
      },( error ) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
        resolve("");
      });
    });
  }

 
  /**
   * delete sub admin
   * @param id 
   */
  deleteSelectedAppUser(id : any ){
    swal.fire({
      title: 'Are you sure you want to remove app user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      if (result.value) {
        this._appUserService.deleteAppUserId(id).subscribe(async ( results ) => {
          this._commonService.checkSuccess(results);
          await this.searchAppUser(true);
        },( error ) => {
          this._commonService.checkError(error);
        });
      }
    });
    
  }
  /**
   * edit sub admin
   * @param id 
   */
  editSelectedAppUser(id : any){
    this._route.navigate(["admin/appUser/add"], { queryParams: { id : btoa(id)}});
  }

  viewTransaction(id: any) {    
    this._route.navigate(["admin/transaction"], {queryParams:{id:btoa(id)}});    
  }

  viewDocument(id: any) {    
    this._route.navigate(["admin/appUser/viewDocument"], {queryParams:{id:btoa(id)}});    
  }

  /**
   * change page size and find data and set page 0
   * @param id 
   */
  changePageSize(id : any){
    this.basicSelectedOption = id;
    this.fetchAppUserListParam.limit = id;
    this.page.size = id;
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
    this.searchAppUser(false);
  }
  /**
   * sort field name and set page 0
   * @param e 
   */
  onSort(e){
    this.fetchAppUserListParam.direction = e.newValue;
    this.fetchAppUserListParam.sortBy = e.column.prop;
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
    this.searchAppUser(false);
  }
  /**
   * page change evnet set page number and find data
   * @param event 
   */
  setPage(event){
    console.log(event)
    this.page.pageNumber = event.offset;
    this.fetchAppUserListParam.pageNo = event.offset + 1;
    this.searchAppUser(false);
  }
  /**
   * search sub admin and set page 0
   */
  searchAppUserBySearchBox(){
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
    this.searchAppUser(false);
  }


  externalIdClick(loader){
    if (loader) {
      this.blockUI.start();
    }

    var urlencoded = new FormData();
    this._appUserService.postExternalIdClick(urlencoded).subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.status == "SUCCESS") {
          this._commonService.checkSuccess(data);
        }else{

        }
      }, (error) => {
        this._commonService.checkError(error);
      });
  }

}
