<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo--><a class="brand-logo" href="javascript:void(0);">
      <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
      <h2 class="brand-text text-primary ml-1">Xtrahub</h2></a
    >
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img
          [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/reset-password-v2-dark.svg'
              : 'assets/images/pages/reset-password-v2.svg'
          "
          img-fluid="img-fluid"
          alt="Register V2"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Reset password-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">Reset Password 🔒</h2>
        <div *ngIf="!isResetPasswordSucessFully">
        <p class="card-text mb-2">Please enter your new password below</p>
        <form class="auth-reset-password-form mt-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-new">New Password</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="newPassword"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="reset-password-new"
                tabindex="1"
                autofocus
                minlength="6"
                maxlength="24"
                [ngClass]="{ 'is-invalid error': f.newPassword.touched && f.newPassword.errors }"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="f.newPassword.touched && f.newPassword.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': f.newPassword.touched && f.newPassword.errors }"
            >
              <div *ngIf="f.newPassword.errors.required">Password is required</div>
              <div *ngIf="f.newPassword.errors.minlength">Password must be 6-24 characters</div>
              <div *ngIf="f.newPassword.errors.maxlength">Password must be 6-24 characters</div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-confirm">Confirm Password</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="confPasswordTextType ? 'text' : 'password'"
                formControlName="confirmPassword"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="reset-password-confirm"
                tabindex="2"
                pattern="{{ f.newPassword.value }}"
                [ngClass]="{ 'is-invalid error': f.confirmPassword.touched && f.confirmPassword.errors }"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': confPasswordTextType,
                      'icon-eye': !confPasswordTextType
                    }"
                    (click)="toggleConfPasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="f.confirmPassword.touched && f.confirmPassword.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': f.confirmPassword.touched && f.confirmPassword.errors }"
            >
              <div *ngIf="f.confirmPassword.errors.required">Confirm password is required</div>
              <div *ngIf="f.confirmPassword.errors.pattern">Password & Confirm Password does not match</div>
            </div>
          </div>
          <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="3" rippleEffect>
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Set New Password</button>
        </form>
        </div>
        <div class="alert message-color" role="alert" *ngIf="isResetPasswordSucessFully">
          <p>Your password has been successfully changed. Please sign-in with new password</p>
        </div>
        <p class="text-center mt-2">
          <a routerLink="/signin"
            ><i data-feather="chevron-left" class="mb-25"></i> Back to login</a
          >
        </p>
      </div>
    </div>
    <!-- /Reset password-->
  </div>
</div>
