import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from '../service/cookie.service';
import { LocalStorageService } from '../service/localstorage.service';
import { CONSTANTS } from '../service/service.assets';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, 
              private _localStorageService: LocalStorageService,
              private _cookieService: CookieService) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._localStorageService.getLocalStorage(CONSTANTS.ACCESSTOKEN);
    if (currentUser) {
      const userRole = this._localStorageService.getLocalStorage(CONSTANTS.ROLE);
      //if ROLE is MOBILE USER then to login into web application
      if (!userRole || userRole == CONSTANTS.ROLE_MOBILE_USER) {
        this._localStorageService.clearLocalStorage();
        // If role is not SUPER ADMIN then redirect to not-authorized page
        this._router.navigate(['not-authorized']);
        return false;
      }
      // if(this._localStorageService.getLocalStorage('isLoad') == 'true'){
      //   this._localStorageService.setLocalStorage('isLoad','false');
      //   window.location.reload();
      // }
      
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/signin']);
    return false;
  }
}
