<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->

    <!-- Basic Alerts start -->
    <section id="dashboard-page">
      <div class="row">
           <!-- Statistics Card -->
          <div class="col-xl-12 col-md-6 col-12">
            <div class="card card-statistics">
              <div class="card-header">
                <h4 class="card-title">Dashboard</h4>
                <div class="d-flex align-items-center">
                  <!-- <p class="card-text font-small-2 mr-25 mb-0">Updated 1 month ago</p> -->
                </div>
              </div>
              <div  class="card-body statistics-body">
                <div class="row">
                  <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                    <div class="media">
                      <div class="avatar bg-light-info mr-2">
                        <div class="avatar-content">
                          <i data-feather="user" class="avatar-icon"></i>
                        </div>
                      </div>
                      <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{dashbordCount.users}}</h4>
                        <p class="card-text font-small-3 mb-0">
                          <a class="link-text" [routerLink]="['/admin/appUser']">Total Users</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                    <div class="media">
                      <div class="avatar bg-light-primary mr-2">
                        <div class="avatar-content">
                          <i data-feather="trending-up" class="avatar-icon"></i>
                        </div>
                      </div>
                      <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{dashbordCount.transaction}}</h4>
                        <p class="card-text font-small-3 mb-0">
                          <a class="link-text" [routerLink]="['/admin/transaction']">Transaction</a>                          
                        </p>
                      </div>
                    </div>
                  </div> -->
                  
                  <!-- <div class="col-sm-6 col-xl-3 mb-2 mb-sm-0">
                    <div class="media">
                      <div class="avatar bg-light-danger mr-2">
                        <div class="avatar-content">
                          <i data-feather="box" class="avatar-icon"></i> 
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="width: 24px;height:24px; color:red;">
                            <path
                              d="M176 32C244.4 32 303.7 71.01 332.8 128H352C369.7 128 384 142.3 384 160C384 177.7 369.7 192 352 192H351.3C351.8 197.3 352 202.6 352 208C352 213.4 351.8 218.7 351.3 224H352C369.7 224 384 238.3 384 256C384 273.7 369.7 288 352 288H332.8C303.7 344.1 244.4 384 176 384H96V448C96 465.7 81.67 480 64 480C46.33 480 32 465.7 32 448V288C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224V192C14.33 192 0 177.7 0 160C0 142.3 14.33 128 32 128V64C32 46.33 46.33 32 64 32H176zM254.4 128C234.2 108.2 206.5 96 176 96H96V128H254.4zM96 192V224H286.9C287.6 218.8 288 213.4 288 208C288 202.6 287.6 197.2 286.9 192H96zM254.4 288H96V320H176C206.5 320 234.2 307.8 254.4 288z" />
                          </svg>
                        </div>
                      </div>
                      <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{dashbordCount.peso}}</h4>
                        <p class="card-text font-small-3 mb-0">Total Peso in Wallet</p>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="col-sm-6 col-xl-3">
                    <div class="media">
                      <div class="avatar bg-light-success mr-2">
                        <div class="avatar-content">
                          <i data-feather="dollar-sign" class="avatar-icon"></i>
                        </div>
                      </div>
                      <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0">{{dashbordCount.usd}}</h4>
                        <p class="card-text font-small-3 mb-0">Total USD in Wallet</p>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!--/ Statistics Card -->
      </div>
    </section>
    <!-- Basic Alerts end -->

    <section *ngIf="isUserButton" id="manage-app-user-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">User request for level up</h4>
              <div class="d-flex align-items-center">
                <a class="all-text" [routerLink]="['/admin/appUser']">View all users</a>
    
                <!-- <p class="card-text font-small-2 mr-25 mb-0">Updated 1 month ago</p> -->
              </div>
            </div>
            <div class="card-body pb-1">
              <ngx-datatable [rows]="userdata" [rowHeight]="58" class="bootstrap core-bootstrap" [headerHeight]="40"
                [footerHeight]="50" [externalSorting]="false" [externalPaging]="false" [scrollbarH]="true">
                <ngx-datatable-column name="S No." [sortable]="false" [width]="50">
                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    {{rowIndex + pageLevelStart}}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="First Name" prop="firstName" [width]="250">
                </ngx-datatable-column>

                <ngx-datatable-column name="Last Name" prop="lastName" [width]="250">
                </ngx-datatable-column>                
    
                <ngx-datatable-column name="Mobile Number" prop="mobileNumber" [width]="200">
                </ngx-datatable-column>

                <ngx-datatable-column name="Wallet Amount" prop="walletAmount" [width]="200">
                </ngx-datatable-column>

                <ngx-datatable-column name="External Id" prop="externalId" [width]="200">
                </ngx-datatable-column>

                
    
                <!-- <ngx-datatable-column name="Date" [width]="200">
                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    <span *ngIf="row.createdDate">
                      {{row.createdDate | date: 'dd/MM/yyyy'}}
                    </span>
                  </ng-template>
                </ngx-datatable-column> -->

                <ngx-datatable-column name="Actions" prop="actions" [width]="150"
                  [sortable]="false">
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <ul>
                      <li>
                        <a title="Edit user" href="javascript:void(0)" (click)="editSelectedAppUser(row.id)"><span
                            [data-feather]="'edit'" [size]="16" [class]="'mr-50'"></span></a>
                      </li>
                    </ul>
                  </ng-template>
                </ngx-datatable-column>

              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </section>

 
    <section *ngIf="isTransactionButton" id="manage-app-user-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Latest transaction</h4>
              <div class="d-flex align-items-center"> 
                <a class="all-text" [routerLink]="['/admin/transaction']">View all transaction</a>
                
                <!-- <p class="card-text font-small-2 mr-25 mb-0">Updated 1 month ago</p> -->
              </div>
            </div>
            <div class="card-body pb-1">                            
              <ngx-datatable [rows]="userTrnasactiondata" [rowHeight]="58" class="bootstrap core-bootstrap" [headerHeight]="40"
                [footerHeight]="50" [externalSorting]="false" [externalPaging]="false"
                [scrollbarH]="true">
                <ngx-datatable-column name="S No." [sortable]="false" [width]="50">
                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    {{rowIndex + pageStart}}
                  </ng-template>
                </ngx-datatable-column>
    
                <ngx-datatable-column name="Transaction By User" [width]="300">
                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    {{row.transactionByUser.firstName}} ({{row.transactionByUser.mobileNumber}})
                  </ng-template>
                </ngx-datatable-column>
    
                <ngx-datatable-column name="Transaction To User" [width]="300">
                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    <span *ngIf="row.transactionType == 'WALLET_RECHARGE'">
                      <span *ngIf="row.transactionByUser && row.transactionByUser.mobileNumber">
                        {{row.transactionByUser.firstName}} ({{row.transactionByUser.mobileNumber}})
                      </span>
                    </span>
                    <span *ngIf="row.transactionType != 'WALLET_RECHARGE'">
                      <span *ngIf="row.transferToUser && row.transferToUser.mobileNumber">
                        {{row.transferToUser.firstName}} ({{row.transferToUser.mobileNumber}})
                      </span>
                    </span>
                  </ng-template>
                </ngx-datatable-column>
    
                <ngx-datatable-column name="transactionType" [width]="200">
                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    <span *ngIf="row.transactionType == 'WALLET_RECHARGE'" class="orange-text">WALLET RECHARGE</span>
                    <span *ngIf="row.transactionType == 'RECEIVED'" class="green-text">RECEIVED</span>
                    <span *ngIf="row.transactionType == 'TRANSFER'" class="red-text">TRANSFER</span>
                  </ng-template>
                </ngx-datatable-column>
               
                <ngx-datatable-column name="Amount" prop="mainPrice" [width]="200">
                </ngx-datatable-column>
    
                <ngx-datatable-column name="Date" [width]="200">
                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    <span *ngIf="row.createdDate">
                      {{row.createdDate | date: 'dd/MM/yyyy'}}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>    
            </div>
          </div>
        </div>
      </div>
    </section> 
        

  </div>
</div>