import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { CommonService } from "./common.service";
import { CONSTANTS, URL } from "./service.assets";
import { LocalStorageService } from "./localstorage.service";
import { map } from "rxjs/operators";
@Injectable({ providedIn: 'root' })
export class APIClientService {

    constructor( 
        private _httpClient: HttpClient, 
        private _router: Router,
        private _commonService: CommonService,
        private _localStorageService: LocalStorageService ) { }

    /**
     * get
     * @param url 
     * @returns 
     */
    doGet(url: string ): Observable<any> {
        return this._httpClient.get(url, { headers: this.getAuthHeader() });
    }
    /**
     * get
     * @param url 
     * @returns 
     */
    getCall(url: string ): Observable<any> {
        return this._httpClient.get(url, { headers: this.getAuthHeader() }).pipe(map(response => response));
    }

    /**
     * get with params
     * @param url 
     * @param params 
     * @returns 
     */
     doGetWithParam(url: string, params: HttpParams ): Observable<any> {
        return this._httpClient.get(url, { headers: this.getAuthHeader(), params });
    }

    /**
     * post
     * @param url 
     * @param request 
     * @returns 
     */
    doPost(url: string, request: any ): Observable<any> {
        return this._httpClient.post(url, request, { headers: this.getAuthHeader() });
    }
    /**
     * post
     * @param url 
     * @param request 
     * @returns 
     */
    doPostRaw(url: string, request: any ): Observable<any> {
        let headers = new HttpHeaders({
            "Accept": "application/json",
            'Content-Type': 'application/json',
            'Authorization': this._localStorageService.getLocalStorage(CONSTANTS.ACCESSTOKEN),
        });
        return this._httpClient.post(url, request, { headers: this.getAuthHeader() });
    }
    /**
     * post
     * @param url 
     * @param request 
     * @returns 
     */
    doPostXURL(url: string, request: any ): Observable<any> {
        let headers = new HttpHeaders({
            "Accept": "application/json",
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this._localStorageService.getLocalStorage(CONSTANTS.ACCESSTOKEN),
        });
        return this._httpClient.post(url, request, { headers: headers });
    }

    /**
     * for auth token
     * @param url 
     * @param request 
     * @returns 
     */
    doAuthorization(url: string, request: any ): Observable<any> {
    	return this._httpClient.post(url, request, {headers: this.getAuthenticationHeader()});
    }

    /**
     * delete
     * @param url 
     * @returns 
     */
    doDelete(url: string, params: HttpParams): Observable<any> {
    	return this._httpClient.delete(url, { headers: this.getAuthHeader() , params });
    }

    /**
     * get with auth
     * @param url 
     * @returns 
     */
    doGetPublic(url: string , authorization: string): Observable<any> {
        return this._httpClient.get(url);
    }

   /**
    * post with auth
    * @param url 
    * @param request 
    * @returns 
    */
    doPostPublic(url: string, request: any ): Observable<any> {
    
        return this._httpClient.post<any>(url , request);
    }

    /**
     * get brear token
     * @returns 
     */
    private getAuthHeader(): HttpHeaders {
        let headers = new HttpHeaders({
            'Authorization': this._localStorageService.getLocalStorage(CONSTANTS.ACCESSTOKEN),
        });
        return headers;
    }

    /**
     * get basic token
     * @returns 
     */
    private getAuthenticationHeader(): HttpHeaders {
        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': CONSTANTS.AUTHORIZATION,
        });
        return headers;
    }

}