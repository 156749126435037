import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CoreConfigService } from '@core/services/config.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'app/auth/service/common.service';

@Component({
  selector: 'app-auth-reset-password',
  templateUrl: './auth-reset-password.component.html',
  styleUrls: ['./auth-reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthResetPasswordComponent implements OnInit {
  // Public
  public coreConfig: any;
  public passwordTextType: boolean;
  public confPasswordTextType: boolean;
  public resetPasswordForm: FormGroup;
  public submitted = false;
  private token: string;
  public isResetPasswordSucessFully = false;
  public loading = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _coreConfigService: CoreConfigService, 
    private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private _commonService: CommonService) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }
  //go to solution : https://stackoverflow.com/questions/34364880/expression-has-changed-after-it-was-checked
  //for password pattern
  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  /**
   * Toggle confirm password
   */
  toggleConfPasswordTextType() {
    this.confPasswordTextType = !this.confPasswordTextType;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this._authenticationService.resetPassword(this.resetPasswordForm.controls.newPassword.value,this.token).subscribe(async ( results ) => {
      this.isResetPasswordSucessFully = true;
      this.loading = false;
    },( error ) => {  
         this.loading = false;
         this._commonService.checkError(error);
        //  this._toastrService.error(error.error.message);
    });
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._activatedRoute.queryParams.forEach((params: Params) => {
			this.token = params['tkn'];
		});
    this._authenticationService.verifyToken(this.token).subscribe((results) => {
		},(error) => {
      this._commonService.checkError(error);
			// this._toastrService.error(error.error.message);
			}
		);
    
    this.resetPasswordForm = this._formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
