<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section id="manage-app-user-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body pb-1">
                            <div (click)="download()">
                                <div class="col-sm pull-right">
                                    <button class="btn btn-primary pull-right" rippleEffect>Export Transaction</button> &nbsp;
                                </div>
                            </div>
                            <div>
                                <form [formGroup]="ReactiveUserDetailsForm" (ngSubmit)="ReactiveUDFormOnSubmit()">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-sm">
                                                <div class="form-group">
                                                    <label for="User">User</label>

                                                    <div class="ng-autocomplete" *ngIf="userAllList && userAllList.length > 0">
                                                        <ng-autocomplete 
                                                            [data]="userAllList" 
                                                            [searchKeyword]="keyword" 
                                                            [initialValue]="initialValue"
                                                            placeholder="Search user"                                                             
                                                            (inputCleared)="onCleared($event)"
                                                            (selected)='selectEvent($event)'
                                                            (inputChanged)='onChangeSearch($event)' 
                                                            (inputFocused)='onFocused($event)' 
                                                            [itemTemplate]="itemTemplateStatic"
                                                            [notFoundTemplate]="notFoundTemplate">
                                                        </ng-autocomplete>
                                                        
                                                        <ng-template #itemTemplateStatic let-item>
                                                            <a [innerHTML]="item.firstName"></a>
                                                        </ng-template>
                                                        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>

                                                    <!-- <select formControlName="user_id" name="user_id" class="form-control">
                                                        <option [value]="0">Select user</option>
                                                        <option *ngFor="let data of userAllList;" [selected]="data.isSelected" [value]="data.id">
                                                            {{data.firstName | titlecase}} {{data.lastName }} ({{data.mobileNumber}})
                                                        </option>
                                                    </select> -->
                                                </div>
                                            </div>
                                            <div class="col-sm">
                                                <div class="form-group birthdate-calender">
                                                    <label for="Start date">Start date</label>
                                                    <input class="form-control start-date-input" formControlName="start_date" type="date" id="start_date"
                                                        placeholder="Start date" [max]="maxBirthDate" required>
                                                </div>
                                            </div>
                                            <div class="col-sm">
                                                <div class="form-group birthdate-calender">
                                                    <label for="End date">End date</label>
                                                    <input class="form-control end-date-input" formControlName="end_date" type="date" id="end_date" 
                                                        placeholder="End date" [max]="maxBirthDate" required>
                                                    <span class="error" id="showDateErrorMessage">End date should be grater then start date</span>    
                                                </div>
                                            </div>
                                            <div class="col-sm">
                                                <div class="form-group">
                                                    <br/>&nbsp;
                                                    <button class="btn btn-primary" rippleEffect>Submit</button> &nbsp;
                                                    <button type="button" class="btn btn-warning" (click)="resetFilter()">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="d-flex justify-content-between align-items-center m-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25"
                                                (change)="changePageSize(basicSelectedOption)"
                                                [(ngModel)]="basicSelectedOption">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            entries</label>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6 col-12 d-flex justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                                        <label class="d-flex align-items-center">Search
                                        <input type="search" placeholder="Search" class="form-control ml-25"
                                                [(ngModel)]="fetchAppUserListParam.searchString" (keyup)="searchAppUserBySearchBox()" />
                                        </label>
                                    </div>
                                </div> -->
                            </div>

                            <div class="number-table">
                                <ngx-datatable [rows]="userdata" [rowHeight]="58" class="bootstrap core-bootstrap"
                                [headerHeight]="40" [footerHeight]="50" [externalSorting]="true" (sort)="onSort($event)"
                                [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber"
                                [limit]="page.size" (page)="setPage($event)" [scrollbarH]="true">
                                <ngx-datatable-column name="S No." [sortable]="false" [width]="50">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        {{rowIndex + pageStart}}
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Tran. id" prop="id" [width]="100">
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Date" [width]="120">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span *ngIf="row.createdDate">
                                            {{row.createdDate | date: 'dd/MM/yyyy'}} 
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="transactionType" [width]="160">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span *ngIf="row.transactionType == 'WALLET_RECHARGE'" class="orange-text">WALLET RECHARGE</span>
                                        <span *ngIf="row.transactionType == 'RECEIVED'" class="green-text">RECEIVED</span>
                                        <span *ngIf="row.transactionType == 'TRANSFER'" class="red-text">TRANSFER</span>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Amount" [width]="100">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span class="number-text">  
                                        {{(row.mainPrice).toFixed(2)}}
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                
                                <ngx-datatable-column name="Rate" [width]="100">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span class="number-text">  
                                        {{(row.usdToMxnRate).toFixed(2)}}
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="fees" [width]="100">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span *ngIf="row.transactionType == 'WALLET_RECHARGE'">
                                            <span class="number-text">                                                                                                 
                                                {{ (((row.mainPrice * 2.9)/100) + (0.30)) | number:'1.2-2' }}
                                                <!-- {{ (((row.mainPrice * stripe_commition_percentage)/100) + (stripe_commition_fee)) | number:'1.1-1' }} -->
                                            </span>
                                        </span>
                                        <span *ngIf="row.transactionType != 'WALLET_RECHARGE'">
                                            <span class="number-text">
                                                {{row.fees}}
                                            </span>
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>  
                                
                                

                                <ngx-datatable-column name="Total Amount" [width]="120">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span *ngIf="row.transactionType == 'WALLET_RECHARGE'">
                                            <span class="number-text">                                                                                                
                                                {{ (row.mainPrice +(row.mainPrice * 2.9)/100 + 0.30) | number:'1.2-2'}}
                                                <!-- {{ (row.mainPrice +(row.mainPrice * stripe_commition_percentage)/100 + stripe_commition_fee) | number:'1.1-1'}} -->
                                            </span>
                                        </span>
                                        <span *ngIf="row.transactionType != 'WALLET_RECHARGE'">
                                            <span class="number-text">
                                                {{ ((row.mainPrice*row.usdToMxnRate) - row.fees) | number:'1.2-2' }}
                                                <!-- {{ ((row.mainPrice*row.usdToMxnRate) - row.fees) | number:'1.1-1' }} -->
                                            </span>
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Transaction By User" [width]="250">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        {{row.transactionByUser.firstName}} {{row.transactionByUser.lastName}} ({{row.transactionByUser.mobileNumber}})
                                        <br />
                                        <span *ngIf="row.transactionByUser.userType == 1">User</span>
                                        <span *ngIf="row.transactionByUser.userType != 1">Cashier</span>
                                    </ng-template>
                                </ngx-datatable-column>   
                                
                                <ngx-datatable-column name="Transaction To User" [width]="250">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                        <span *ngIf="row.transactionType == 'WALLET_RECHARGE'">
                                            <span *ngIf="row.transactionByUser && row.transactionByUser.mobileNumber">
                                                {{row.transactionByUser.firstName}} {{row.transactionByUser.lastName}} ({{row.transactionByUser.mobileNumber}})
                                                <br />
                                                <span *ngIf="row.transactionByUser.userType == 1">User</span>
                                                <span *ngIf="row.transactionByUser.userType != 1">Cashier</span>
                                            </span>
                                        </span>
                                        <span *ngIf="row.transactionType != 'WALLET_RECHARGE'">
                                            <span *ngIf="row.transferToUser && row.transferToUser.mobileNumber">
                                                {{row.transferToUser.firstName}} {{row.transferToUser.lastName}} ({{row.transferToUser.mobileNumber}})
                                                <br/>
                                                <span *ngIf="row.transferToUser.userType == 1">User</span>
                                                <span *ngIf="row.transferToUser.userType != 1">Cashier</span>
                                            </span>
                                        </span>                                                                              
                                    </ng-template>
                                </ngx-datatable-column>                                

                                

                             

                               
                                <!-- <ngx-datatable-column name="Device Type" prop="deviceType" [width]="175"></ngx-datatable-column> -->

                               
                            </ngx-datatable>
                            </div>

                            

                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>