import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreCommonModule } from '@core/common.module';
import { AuthForgotPasswordComponent } from './auth-forgot-password/auth-forgot-password.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthResetPasswordComponent } from './auth-reset-password/auth-reset-password.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthSetPasswordComponent } from './auth-set-password/auth-set-password.component';



// routing
const routes: Routes = [
  {
    path: 'signin',
    component: AuthLoginComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'forgot',
    component: AuthForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: AuthResetPasswordComponent
  },
  {
    path: 'verify-user',
    component: AuthSetPasswordComponent
  },
];

@NgModule({
  declarations: [
    AuthLoginComponent,
    AuthForgotPasswordComponent,
    AuthResetPasswordComponent,
    AuthSetPasswordComponent
  ],
  imports: [ContentHeaderModule, NgSelectModule, CommonModule, RouterModule.forChild(routes), NgbModule, FormsModule, ReactiveFormsModule, CoreCommonModule]
})
export class AuthenticationModule {}
