import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { CONSTANTS, URL } from './service.assets';
import { CommonService } from './common.service';
import { APIClientService } from './apiclient.service';
import { LocalStorageService } from './localstorage.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient, 
    private _commonService: CommonService,
    private _apiClientService: APIClientService,
    private _localStorageService: LocalStorageService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login 
   * @param email 
   * @param password 
   * @returns 
   */
  login(email: string, password: string) {
    let urlSearchParams = new URLSearchParams();
		urlSearchParams.append('email', email);
		urlSearchParams.append('password', password);
    urlSearchParams.append('grant_type', "password");
		let body = urlSearchParams.toString();

    return this._apiClientService.doAuthorization(URL.SIGNIN, body).pipe(
      map(response => response)
    );

  }

  /**
   * User logout
   *
   */
  logout() {
    
    return this._apiClientService.doPost(URL.LOGOUT, null).pipe(
      map(response => response)
    );
  }

  /**
   * forgot password
   * @param email 
   * @returns 
   */
  forgotPassword(email: string){
    const formData = new FormData();
		formData.append('email', email);

    return this._apiClientService.doPostPublic(URL.FORGOT_PASSWORD, formData).pipe(
      map(response => response)
    );
  }

  /**
   * verify token
   * @param token 
   * @returns 
   */
  verifyToken(token: string){
    const formData = new FormData();
		formData.append('tkn', token);
    return this._apiClientService.doPostPublic(URL.VERIFY_TOKEN, formData).pipe(
      map(response => response)
    );
  }

  /**
   * reset password
   * @param password 
   * @param tkn 
   * @returns 
   */
  resetPassword(password: string, tkn: string){
    const formData = new FormData();
		formData.append('password', password);
    formData.append('tkn',tkn);

    return this._apiClientService.doPostPublic(URL.RESET_PASSWORD, formData).pipe(
      map(response => response)
    );
  }

  /**
   * first time set password and verify user
   * @param password 
   * @param tkn 
   * @returns 
   */
  setPassword(password: string, tkn: string){
    const formData = new FormData();
		formData.append('password', password);
    formData.append('tkn',tkn);

    return this._apiClientService.doPostPublic(URL.SET_PASSWORD, formData).pipe(
      map(response => response)
    );
  }
}
