import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'app/auth/models/page';
import { AppUserService } from 'app/auth/service/app-user.service';
import { CommonService } from 'app/auth/service/common.service';
import { CookieService } from 'app/auth/service/cookie.service';
import { CONSTANTS } from 'app/auth/service/service.assets';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';

@Component({
  selector: 'app-manage-notification',
  templateUrl: './manage-notification.component.html',
  styleUrls: ['./manage-notification.component.scss']
})
export class ManageNotificationComponent implements OnInit {

  page = new Page();

  constructor(private _appUserService : AppUserService,
              private _commonService : CommonService,
              private _cookieService: CookieService,
              private _route : Router) { 
                this.page.pageNumber = 0;
                this.page.size = 10;
              }
  public contentHeader: object;
  public basicSelectedOption: number = 10;
  public data: any ;

  pageLimitOptions: any = [1000];
  SORT_BY: string[] = ['firstName'];
  isDirectionDesc: boolean = true;
  private searchPolicyUserObs: Subscription;
  public pageStart: any = 0;
  public isAddButton: boolean = false;
  public isEditButton: boolean = false;
  public isDeleteButton: boolean = false;

  fetchAppUserListParam = {
		limit: 10,
		pageNo: 1,
		searchString: "",
		sortBy: "createdDate",
		direction: "desc",
	};
  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {
    let permission =  this._cookieService.getCookie(CONSTANTS.PERMISSION);
    // if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('manage.app_user.add')) >= 0){
      this.isAddButton = true;
    // }
    // if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('manage.app_user.edit')) >= 0){
      this.isEditButton = true;
    // }
    // if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('manage.app_user.delete')) >= 0){
      this.isDeleteButton = true;
    // }
    this.contentHeader = {
      headerTitle: 'Notification',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Notification',
            isLink: false
          }
        ]
      }
    }
    this.searchNotification(true);
  }
  /**
   * search sub admin
   * @returns 
   */
   searchNotification(loader){
     if(loader){
      this.blockUI.start();
     }
    return new Promise( (resolve)=>{
      if(this.searchPolicyUserObs){
        this.searchPolicyUserObs.unsubscribe();
      }
      this.searchPolicyUserObs = this._appUserService.searchNotification(this.fetchAppUserListParam,true,false).subscribe(async ( results ) => {
        this.blockUI.stop();
        this.data = results.data.content;
        this.pageStart = (results.data.pageable.pageSize * results.data.pageable.pageNumber) + 1;
        this.page.totalElements = results.data.totalElements;
        resolve("");
      },( error ) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
        resolve("");
      });
    });
  }
  /**
   * delete sub admin
   * @param id 
   */
  deleteSelectedAppUser(id : any ){
    swal.fire({
      title: 'Are you sure you want to remove app user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      if (result.value) {
        this._appUserService.deleteAppUserId(id).subscribe(async ( results ) => {
          this._commonService.checkSuccess(results);
          await this.searchNotification(true);
        },( error ) => {
          this._commonService.checkError(error);
        });
      }
    });
    
  }
  /**
   * edit sub admin
   * @param id 
   */
  editSelectedAppUser(id : any){
    this._route.navigate(["admin/appUser/add"], { queryParams: { id : btoa(id)}});
  }

  /**
   * change page size and find data and set page 0
   * @param id 
   */
  changePageSize(id : any){
    this.basicSelectedOption = id;
    this.fetchAppUserListParam.limit = id;
    this.page.size = id;
    this.page.pageNumber =0;
    this.fetchAppUserListParam.pageNo = 1;
    this.searchNotification(false);
  }
  /**
   * sort field name and set page 0
   * @param e 
   */
  onSort(e){
    this.fetchAppUserListParam.direction = e.newValue;
    this.fetchAppUserListParam.sortBy = e.column.prop;
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
    this.searchNotification(false);
  }
  /**
   * page change evnet set page number and find data
   * @param event 
   */
  setPage(event){
    this.page.pageNumber = event.offset;
    this.fetchAppUserListParam.pageNo = event.offset + 1;
    this.searchNotification(false);
  }
  /**
   * search sub admin and set page 0
   */
  searchNotificationBySearchBox(){
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
    this.searchNotification(false);
  }
}
