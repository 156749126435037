import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'app/auth/models/page';
import { BannerService } from 'app/auth/service/banner.service';
import { CommonService } from 'app/auth/service/common.service';
import { CookieService } from 'app/auth/service/cookie.service';
import { CONSTANTS } from 'app/auth/service/service.assets';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { LocalStorageService } from 'app/auth/service/localstorage.service';

@Component({
  selector: 'app-manage-banner',
  templateUrl: './manage-banner.component.html',
  styleUrls: ['./manage-banner.component.scss']
})
export class ManageBannerComponent implements OnInit {

  page = new Page();

  constructor(private _bannerService : BannerService,
              private _commonService : CommonService,
              private _cookieService : CookieService,
              private _localStorageService: LocalStorageService,
              private _route : Router) {
                this.page.pageNumber = 1;
                this.page.size = 10;
               }
  public contentHeader: object;
  public basicSelectedOption: number = 10;
  public data: any ;

  isDirectionDesc: boolean = true;
  private searchPolicyUserObs: Subscription;
  public pageStart: any = 0;
  public isAddButton: boolean = false;
  public isEditButton: boolean = false;
  public isDeleteButton: boolean = false;

  fetchBannerListParam = {
    limit: 10,
    pageNo: 1,
    searchString: "",
    sortBy: "createdDate",
    direction: "desc",
  };

  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {    

    const userRole = this._localStorageService.getLocalStorage(CONSTANTS.ROLE);    
    if(userRole == 'ROLE_MASTER'){   
      this.isAddButton = true;
      this.isEditButton = true;
      this.isDeleteButton = true;
    }else{
      let permission =  this._cookieService.getCookie(CONSTANTS.PERMISSION);
      if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('ADD_BANNER')) >= 0){
       this.isAddButton = true;
      }
      if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('UPDATE_BANNER')) >= 0){
       this.isEditButton = true;
      }
      if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('DELETE_BANNER')) >= 0){
       this.isDeleteButton = true;
      }
    }
   
    
    this.contentHeader = {
      headerTitle: 'Banner',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Banner',
            isLink: false
          }
        ]
      }
    }
    this.searchBanner(true);
  }

  /**
   * search banner
   * @returns 
   */
   searchBanner(loader){
     if(loader){
       this.blockUI.start();
     }
    return new Promise( (resolve)=>{
      if(this.searchPolicyUserObs){
        this.searchPolicyUserObs.unsubscribe();
      }
      this.searchPolicyUserObs = this._bannerService.searchBanner(this.fetchBannerListParam,true,false).subscribe(async ( results ) => {
        this.blockUI.stop();
        this.data = results.data.content;
        this.pageStart = (results.data.pageable.pageSize * results.data.pageable.pageNumber) + 1;
        this.page.totalElements = results.data.totalElements;
        resolve("");
      },( error ) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
        resolve("");
      });
    });
  }

  /**
   * delete banner
   * @param id 
   */
   deleteSelectedBanner(id : any ){
    swal.fire({
      title: 'Are you sure you want to remove banner?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      if (result.value) {
        this._bannerService.deleteBannerById(id).subscribe(async ( results ) => {
          this._commonService.checkSuccess(results);
          await this.searchBanner(true);
        },( error ) => {
          this._commonService.checkError(error);
        });
      }
    });
    
  }

  /**
   * edit banner
   * @param id 
   */
   editSelectedBanner(id : any){
    this._route.navigate(["admin/banner/add"], { queryParams: { id : btoa(id)}});
  }

  /**
   * change page size 
   * @param id 
   */
  changePageSize(id : any){
    this.basicSelectedOption = id;
    this.fetchBannerListParam.limit = id;
    this.page.size = id;
    this.page.pageNumber = 1;
    this.fetchBannerListParam.pageNo = 1;
    this.searchBanner(false);
  }
  /**
   * on sort set colom and find data and set page 1
   * @param e 
   */
  onSort(e){
    this.fetchBannerListParam.direction = e.newValue;
    this.fetchBannerListParam.sortBy = e.column.prop;
    this.page.pageNumber = 1;
    this.fetchBannerListParam.pageNo = 1;
    this.searchBanner(false);
  }
  /**
   * page chage set page and according find data
   * @param event 
   */
  setPage(event){
    this.page.pageNumber = event.offset;
    this.fetchBannerListParam.pageNo = event.offset;
    this.searchBanner(false);
  }
  /**
   * search box value wise find and set page 1
   */
  searchBannerBySearchBox(){
    this.page.pageNumber = 1;
    this.fetchBannerListParam.pageNo = 1;
    this.searchBanner(false);
  }

}
