import { Component, OnInit } from '@angular/core';
import { APIClientService } from 'app/auth/service/apiclient.service';
import { CommonService } from 'app/auth/service/common.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { URL } from "app/auth/service/service.assets";
import swal from 'sweetalert2';
import { RoleService } from 'app/auth/service/role.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(
    private _commonService : CommonService,
    private _apiClientService: APIClientService,
    private _roleService: RoleService
    ) { 
  }

  public contentHeader: object;
  public data: any ;

  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Settings',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Setting',
            isLink: false
          }
        ]
      }
    }
    this.searchRole(true);
  }
  /**
   * search role
   * @returns 
   */
  searchRole(loader){
    if(loader){
      this.blockUI.start()
    }
    this._apiClientService.getCall(URL.BASE_URL + "admin/settings/get")
      .subscribe(
        res => {
          this.blockUI.stop();
          this.data = res.data;
          console.log(res)
        },
        err => {
          this.blockUI.stop();
          this._commonService.checkError(err);
        }
      );
  }

  updateValue(item){
    swal.fire({
      title: 'Are you sure you want to update?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, Update it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      if (result.value) {
        let pay = {
          "id": item.id,
          "settingsProperty": item.settingsProperty,
          "value": item.value,
          "value1": item.value1,
          "description": item.description,
        }
        this._roleService.updateSettings(pay).subscribe(async (results) => {
          console.log(results);
        }, (error) => {
          this._commonService.checkError(error);
        });
      }
    });
  }

}
