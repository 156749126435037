import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUserService } from 'app/auth/service/app-user.service';
import { CommonService } from 'app/auth/service/common.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';
@Component({
  selector: 'app-add-app-user',
  templateUrl: './add-app-user.component.html',
  styleUrls: ['./add-app-user.component.scss']
})
export class AddAppUserComponent implements OnInit {

  constructor(private _activeRouter : ActivatedRoute,
              private _appUserService : AppUserService,
              private _commonService : CommonService,
              private formBuilder: FormBuilder,
              private _route: Router,
              private sanitizer: DomSanitizer,) { }
  
  public appUserId : any;
  public documentList: any;
  public level1_array: any;
  public level2_array: any;
  public contentHeader: object;
  public addressPicPath:any;
  public addressPicName:any;
  
  public level3_array:any;
  public addressProofError:any;

    public ReactiveUserDetailsForm: FormGroup;
    public ReactiveUDFormSubmitted = false;
    public submitted = false;
    public statusForLevel:boolean;

    // Reactive User Details form data
    public UDForm = {
      firstName: '',
      lastName: '',
      motherName: '',
      email: '',
      nationality: '',
      workactivity: '',
      workcompany: '',
      workposition: '',
      address: '',
      mobileNumber: '',
      level:'',
      userType:'',
      isDelete:0,
      levelStatus:'',
      reason:''
    };
    
    @BlockUI() blockUI: NgBlockUI;

  async ngOnInit() {
    var token = 'rpm_56YUI4562GTYI4562HKLLU746285HJLKITESXC41235HJKPPOI';
    this._appUserService.postAppUserDocumet(this.appUserId, token).subscribe(
      (data: any) => {
        this.getDocument();
      }, (error) => {
        console.log(error);
      });
    this._activeRouter.queryParams.subscribe(params => {
      this.appUserId = params['id'] ? atob(params['id']) : null;
    });

    this.ReactiveUserDetailsForm = this.formBuilder.group({
      id:[null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      motherName: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', [Validators.email]],
      nationality: [''],
      workactivity: [''],
      workcompany: [''],
      workposition: [''],
      role: [null],  
      level: [''],  
      levelStatus:[''],
      userType: ['1', Validators.required],  
      isDelete:[0],
      reason:[''],
      mobileNumber: ['', [Validators.required]],
      deviceType: ['web', [Validators.required]],
      countryCode: ['', [Validators.required]],
    });
    await this.getSubAdminDetailAndSetHeader();
    await this.getDocument();
  }

  getDocument() {
     if (this.appUserId != undefined || this.appUserId != null) {
    this._appUserService.getAppUserDocumetId(this.appUserId).subscribe(async (results) => {

      this.blockUI.stop();
      this.documentList = results.data;

      if (this.documentList.length > 0) {
        var address_obj = this.documentList.filter((data: any) => data.type == 'ADDRESS_PROOF');
        if (address_obj.length > 0){
          this.addressPicPath = address_obj[0].documentPath;
          this.addressPicName = address_obj[0].document;
        }
        this.level1_array = this.documentList.filter((data: any) => data.level == 2);
       // this.level2_array = this.documentList.filter((data: any) => data.level == 3);
      }

    }, (error) => {
      this.blockUI.stop();
      this._commonService.checkError(error);
    });
    }
  }

  getSubAdminDetailAndSetHeader(){

    if (this.appUserId != undefined || this.appUserId != null) {
      this.contentHeader = {
        headerTitle: 'App User',
        actionButton: false,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'App User',
              isLink: true,
              link: '../'
            },
            {
              name: 'edit',
              isLink: false
            }
          ]
        }
      }
      this.blockUI.start();
      this.ReactiveUserDetailsForm.controls.id.setValue(this.appUserId);
      this._appUserService.getAppUserById(this.appUserId).subscribe(async ( results ) => {
        this.blockUI.stop();
        
        if (results.data.level){
          this.ReactiveUserDetailsForm.controls.level.setValue(results.data.level);
        }
        this.ReactiveUserDetailsForm.controls['mobileNumber'].disable();
        this.ReactiveUserDetailsForm.controls.firstName.setValue(results.data.firstName);
        this.ReactiveUserDetailsForm.controls.lastName.setValue(results.data.lastName);
        this.ReactiveUserDetailsForm.controls.motherName.setValue(results.data.motherLastName);        
        this.ReactiveUserDetailsForm.controls.address.setValue(results.data.address);                
        this.ReactiveUserDetailsForm.controls.nationality.setValue(results.data.nationality);                
        this.ReactiveUserDetailsForm.controls.level.setValue(results.data.level);      
        this.ReactiveUserDetailsForm.controls.lastName.setValue(results.data.lastName);
        this.ReactiveUserDetailsForm.controls.levelStatus.setValue(results.data.levelStatus);          
        this.ReactiveUserDetailsForm.controls.workactivity.setValue(results.data.jobDescription);                
        this.ReactiveUserDetailsForm.controls.workcompany.setValue(results.data.companyName);                
        this.ReactiveUserDetailsForm.controls.workposition.setValue(results.data.positionInCompany);                
        this.ReactiveUserDetailsForm.controls.email.setValue(results.data.email);     
        if (results.data.userType) { 
          var userType = String(results.data.userType);
          this.ReactiveUserDetailsForm.controls.userType.setValue(userType);       
        }  
        if(results.data.reason){
          this.statusForLevel = results.data.levelStatus;          
          setTimeout(() => {
            this.ReactiveUserDetailsForm.controls.reason.setValue(results.data.reason);               
          }, 50);          
        }        

        this.ReactiveUserDetailsForm.controls.isDelete.setValue(results.data.isDelete);          
        console.log(this.ReactiveUserDetailsForm.controls)

        // if (results.data.isDelete) {           
        //   this.ReactiveUserDetailsForm.controls.isDelete.setValue(2);       
        // }  else{
        //   this.ReactiveUserDetailsForm.controls.isDelete.setValue(1);       
        // }

        
     //   this.ReactiveUserDetailsForm.controls.deviceType.setValue('web');
        this.ReactiveUserDetailsForm.controls.countryCode.setValue(results.data.countryCode);
        this.ReactiveUserDetailsForm.controls.mobileNumber.setValue(results.data.mobileNumber);
      },( error ) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
      });
      
    } else {

      this.ReactiveUserDetailsForm.controls['mobileNumber'].enable(); 
      this.contentHeader = {
        headerTitle: 'App User',
        actionButton: true,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'App User',
              isLink: true,
              link: '../'
            },
            {
              name: 'add',
              isLink: false
            }
          ]
        }
      }
    }

  }

  ReactiveUDFormOnSubmit() {
    this.ReactiveUDFormSubmitted = true;
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.ReactiveUserDetailsForm.invalid) {
      return;
    }
    this.blockUI.start();

    if (this.addressfileToUpload) {
      const formDataImage = new FormData();      
      formDataImage.append('userId', this.appUserId);
      formDataImage.append('type', 'ADDRESS_PROOF');
      formDataImage.append('level', this.ReactiveUserDetailsForm.get('level').value);      
      formDataImage.append('addressProof', this.addressfileToUpload, this.addressfileToUpload.name);

      this._appUserService.postAppUserDocumet(this.appUserId, formDataImage).subscribe(
        (data: any) => {
          this.getDocument();
        }, (error) => {
          console.log(error);
        });

    }

    this.ReactiveUserDetailsForm.value.positionInCompany = this.ReactiveUserDetailsForm.get('workposition').value
    this.ReactiveUserDetailsForm.value.motherLastName = this.ReactiveUserDetailsForm.get('motherName').value
    this.ReactiveUserDetailsForm.value.companyName = this.ReactiveUserDetailsForm.get('workcompany').value
    this.ReactiveUserDetailsForm.value.jobDescription = this.ReactiveUserDetailsForm.get('workactivity').value   
    this.ReactiveUserDetailsForm.value.userType = this.ReactiveUserDetailsForm.get('userType').value
    if (!this.appUserId){
      if (this.ReactiveUserDetailsForm.get('levelStatus').value) {
        if (this.ReactiveUserDetailsForm.get('levelStatus').value == 2 || this.ReactiveUserDetailsForm.get('levelStatus').value == 6) {
          this.ReactiveUserDetailsForm.value.levelStatus = this.ReactiveUserDetailsForm.get('levelStatus').value
        }
      }
    }

    if (this.appUserId){      
      if (this.ReactiveUserDetailsForm.get('levelStatus').value == 2 || this.ReactiveUserDetailsForm.get('levelStatus').value == 3 || this.ReactiveUserDetailsForm.get('levelStatus').value == 6 || this.ReactiveUserDetailsForm.get('levelStatus').value == 7) {         
        this.ReactiveUserDetailsForm.value.levelStatus = this.ReactiveUserDetailsForm.get('levelStatus').value
        // if (this.ReactiveUserDetailsForm.get('levelStatus').value == 3 || this.ReactiveUserDetailsForm.get('levelStatus').value == 7) {         
        //   if(this.ReactiveUserDetailsForm.get('reason').value){
        //     this.ReactiveUserDetailsForm.value.reason = this.ReactiveUserDetailsForm.get('reason').value
        //   }        
        // }

      }else{
        this.ReactiveUserDetailsForm.value.levelStatus = ''
      }
    }

    if(this.ReactiveUserDetailsForm.get('reason').value){
      this.ReactiveUserDetailsForm.value.reason = this.ReactiveUserDetailsForm.get('reason').value
    }    

    if(this.ReactiveUserDetailsForm.get('isDelete').value == 1){      
      this.ReactiveUserDetailsForm.value.isDelete = 1
    }else{      
      this.ReactiveUserDetailsForm.value.isDelete = 0
    }       

    this._appUserService.saveOrUpdateAppUser(this.ReactiveUserDetailsForm.value).subscribe(async ( results ) => {
      this.blockUI.stop();
      this._commonService.checkSuccess(results);
      this._route.navigate(["admin/appUser"]);
    },( error ) => {
      this.blockUI.stop();
      this._commonService.checkError(error);
    });    
  }

  get ReactiveUDForm() {
    return this.ReactiveUserDetailsForm.controls;
  }

  documentFirstfileToUpload: File | null = null;
  handleDocumentFirstFileInput(event: any) {    
    
    if (event.target.files) {      
      const files = event.target.files;
      if (files && files.item(0)) {
        this.blockUI.start();
        this.documentFirstfileToUpload = files.item(0);        
        
        const formData = new FormData();
        formData.append('level', '2');
        formData.append('type', 'LEVEL2_DOCUMENT');
        formData.append('userId', this.appUserId);        
        if (this.documentFirstfileToUpload) {         
          formData.append('document1', this.documentFirstfileToUpload, this.documentFirstfileToUpload.name);
        }        
        this._appUserService.postAppUserDocumet(this.appUserId, formData).subscribe(
          (data: any) => {
            this.blockUI.stop();
            this.getDocument();
          }, (error) => {
            this.blockUI.stop();
            console.log(error);       
          });


      }
    }
  }

  addressfileToUpload: File | null = null;
  handleAddressProofFileInput(event: any) {
    if (event.target.files) {
      this.addressProofError = '';
      const files = event.target.files;
      if (files && files.item(0)) {
        this.addressfileToUpload = files.item(0);
        this.addressPicPath = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files.item(0)));
      }
    }
  }

  handleLevelUpInput(event: any) {
    this.statusForLevel = event.target.value;
  }


  deleteDocument(id: any) {

    swal.fire({
      //title: 'warning!',
      icon: 'warning',
      text: 'Are you sure you want to delete this document?',
      confirmButtonText: `Yes`,
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#e57979',
    }).then((result) => {
      if (result.isConfirmed) {
        var urlencoded = new FormData();
        urlencoded.append("documentId", id);
        //urlencoded.append("isClearAll", "true");
        this._appUserService.postDeleteDocumentData(urlencoded).subscribe(
          (data: any) => {
            if (data.status == "SUCCESS") {
              this.getDocument();
            }
          }, (error) => {
            this._commonService.checkError(error);
          });
      }
    });
  }

}
