<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo--><a class="brand-logo" href="javascript:void(0);">
      <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
      <h2 class="brand-text text-primary ml-1">Xtrahub</h2></a
    >
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img
          class="img-fluid"
          [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/forgot-password-v2-dark.svg'
              : 'assets/images/pages/forgot-password-v2.svg'
          "
          alt="Forgot password V2"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Forgot password-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">Forgot Password? 🔒</h2>
        <div *ngIf="!isSentForgotpasswordMailSucessFully">
        <p class="card-text mb-2">Enter your email and we'll send you instructions to reset your password</p>
        <form class="auth-forgot-password-form mt-2" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit(true)">
          <div class="form-group">
            <label class="form-label" for="forgot-password-email">Email</label>
            <input
              class="form-control"
              type="text"
              formControlName="email"
              placeholder="john@example.com"
              aria-describedby="forgot-password-email"
              autofocus=""
              tabindex="1"
              pattern="^[_\.0-9a-zA-Z-]+@([0-9a-zA-Z][0-9a-zA-Z-]+)+((\.)[a-zA-Z]{2,})+$"
              [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }"
            />
            <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <!-- <div *ngIf="f.email.errors.email">Email must be a valid email address</div> -->
              <div *ngIf="f.email.errors.pattern">Email must be a valid email address</div>
            </div>
          </div>
          <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="2" rippleEffect>
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Send reset link
          </button>
        </form>
        </div>
        <div class="alert" role="alert" *ngIf="isSentForgotpasswordMailSucessFully">
          <p class="message-color" *ngIf="!loading">An email has been <span *ngIf="!isSentMailFirstTime">re</span>sent to {{email}} to reset the password.</p>
          <p class="mb-0 ">Didn't receive email ? <a (click)="onSubmit(false)" [class.disabled]="loading" class="alert-link">
            &nbsp;&nbsp;Resend Email&nbsp;&nbsp;<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></a></p>
        </div>
        <p class="text-center mt-2">
          <a routerLink="/signin"
            ><i data-feather="chevron-left" class="mb-25"></i> Back to login</a
          >
        </p>
      </div>
    </div>
    <!-- /Forgot password-->
  </div>
</div>
