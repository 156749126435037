import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUserService } from 'app/auth/service/app-user.service';
import { CommonService } from 'app/auth/service/common.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Page } from 'app/auth/models/page';

@Component({
  selector: 'app-view-update-user-list',
  templateUrl: './view-update-user-list.component.html',
  styleUrls: ['./view-update-user-list.component.scss']
})
export class ViewUpdateUserListComponent implements OnInit {

  page = new Page();

  constructor(private _activeRouter: ActivatedRoute,
    private _appUserService: AppUserService,
    private _commonService: CommonService,
    private formBuilder: FormBuilder,
    private _route: Router) { 
      this.page.pageNumber = 0;
      this.page.size = 10;
    }

    fetchAppUserListParam = {
      limit: 10,
      pageNo: 1,
      searchString:"",
      sortBy: "createdDate",
      direction: "desc",
      userId: '',
      start_date: '',
      end_date: ''
    };
  
  public contentHeader: object;
  public basicSelectedOption: number = 10;

  public ReactiveUserDetailsForm: FormGroup;
  public ReactiveUDFormSubmitted = false;
  public submitted = false;

  // Reactive User Details form data
  public UDForm = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
  };
  public appUserId : any;
  public userdata: any;
  public pageStart: any = 0;
  

  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {

    this._activeRouter.queryParams.subscribe(params => {
      this.appUserId = params['id'] ? atob(params['id']) : null;
      this.fetchAppUserListParam.userId = this.appUserId;
      this.blockUI.start();
      this._appUserService.getDocumentAppUserById(this.fetchAppUserListParam,true,false).subscribe(async (results) => {
        this.blockUI.stop();    
        this.userdata = results.data.content;    
        this.pageStart = (results.data.pageable.pageSize * results.data.pageable.pageNumber) + 1;
        this.page.totalElements = results.data.totalElements;      
      }, (error) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
      });
    });   

  }


  changePageSize(id: any) {
    this.basicSelectedOption = id;
    this.fetchAppUserListParam.limit = id;
    this.page.size = id;
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
   
  }

  onSort(e){
    this.fetchAppUserListParam.direction = e.newValue;
    this.fetchAppUserListParam.sortBy = e.column.prop;
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
    
  }


  setPage(event) {    
    this.page.pageNumber = event.offset;
    this.fetchAppUserListParam.pageNo = event.offset + 1;    
  }

}
