import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BannerService } from 'app/auth/service/banner.service';
import { CommonService } from 'app/auth/service/common.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import swal from 'sweetalert2';
import {NgxImageCompressService} from "ngx-image-compress";
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.scss']
})
export class AddBannerComponent implements OnInit {

  constructor(private _activeRouter: ActivatedRoute,
    private _bannerService: BannerService,
    private _commonService: CommonService,
    private formBuilder: FormBuilder,
    private _route: Router,
    private _modalService: NgbModal,
    private _imageCompress: NgxImageCompressService) { }

  private bannerId: any;
  public contentHeader: object;

  public ReactiveUserDetailsForm: FormGroup;
  public ReactiveUDFormSubmitted = false;
  public submitted = false;
  public bannerImage ;
  public isInvalidImage;
  public bannerImagePath;

  public UDForm = {
    title: '',
    url: '',
    position: '',
  };



  profileImage: any = null;
  imageChangedEvent: any = '';
  oldImage: any;
  CropImageUrl: any;
  lastImage: any;
  imageUrl: any;
  imageFileName: String = 'unknown';
  isUploadLogoValid: boolean = false;
  isUploadLogoSize: boolean = false;
  isUploadLogoType: boolean = false;
  isCropperShow: boolean = false;
  isUpdateButton: boolean = false;
  isDefaultProfile: boolean = false;
  modalReference: any;

  cropper = {
    x1: 30,
    y1: 30,
    x2: 150,
    y2: 150
  };

  @BlockUI() blockUI: NgBlockUI;

  async ngOnInit() {
    this._activeRouter.queryParams.subscribe(params => {
      this.bannerId = params['id'] ? atob(params['id']) : null;
    });

    this.ReactiveUserDetailsForm = this.formBuilder.group({
      id: [null],
      position: ['', Validators.required],
      url: ['', Validators.required],
      title: ['', [Validators.required]],
      bannerImageInput: [null],
    });
    await this.getBannerDetailAndSetHeader();
  }

  getBannerDetailAndSetHeader() {

    if (this.bannerId != undefined || this.bannerId != null) {
      this.contentHeader = {
        headerTitle: 'Banner',
        actionButton: false,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'Banner',
              isLink: true,
              link: '../'
            },
            {
              name: 'edit',
              isLink: false
            }
          ]
        }
      }
      this.blockUI.start();
      this.ReactiveUserDetailsForm.controls.id.setValue(this.bannerId);
      this._bannerService.getBannerById(this.bannerId).subscribe(async (results) => {
        this.blockUI.stop();
        this.ReactiveUserDetailsForm.controls.url.setValue(results.data.url);
        this.ReactiveUserDetailsForm.controls.position.setValue(results.data.position);
        this.ReactiveUserDetailsForm.controls.title.setValue(results.data.title);
        this.bannerImagePath = results.data.bannerImagePath;

        this.oldImage = results.data.bannerImageOutput;
        this.bannerImage = results.data.bannerImageOutput;
        
      }, (error) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
      });

    } else {
      this.contentHeader = {
        headerTitle: 'App User',
        actionButton: true,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'App User',
              isLink: true,
              link: '../'
            },
            {
              name: 'add',
              isLink: false
            }
          ]
        }
      }
    }

  }

  ReactiveUDFormOnSubmit() {
    this.ReactiveUDFormSubmitted = true;
    this.submitted = true;
    const formData = new FormData();

    if (this.bannerId != undefined || this.bannerId != null) {      

      formData.append('id', this.bannerId);
      formData.append('url', this.ReactiveUserDetailsForm.value.url);
      formData.append('position', this.ReactiveUserDetailsForm.value.position);
      formData.append('title', this.ReactiveUserDetailsForm.value.title);
      if (this.bannerImage) {
        let imgData = this.bannerImage != null ? this.imageBase64DataToFile(this.bannerImage, this.imageFileName, "image/png") : this.bannerImage;
        this.ReactiveUserDetailsForm.controls.bannerImageInput.setValue(imgData);
        formData.append('bannerImageInput', imgData);
      }

      this.blockUI.start();
      this._bannerService.saveOrUpdateBanner(formData).subscribe(async (results) => {
        this.blockUI.stop();
        this._commonService.checkSuccess(results);
        this._route.navigate(["admin/banner"]);
      }, (error) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
      });

      console.log(this.ReactiveUserDetailsForm.controls);

    }else{
      if (this.bannerImage == null) {
        this.isInvalidImage = true;
        return;
      } else {
        this.isInvalidImage = false;
      }
      // stop here if form is invalid
      if (this.ReactiveUserDetailsForm.invalid) {
        return;
      }

      let imgData = this.bannerImage != null ? this.imageBase64DataToFile(this.bannerImage, this.imageFileName, "image/png") : this.bannerImage;
      this.ReactiveUserDetailsForm.controls.bannerImageInput.setValue(imgData);
      formData.append('url', this.ReactiveUserDetailsForm.value.url);
      formData.append('position', this.ReactiveUserDetailsForm.value.position);
      formData.append('title', this.ReactiveUserDetailsForm.value.title);
      formData.append('bannerImageInput', imgData);

      this.blockUI.start();
      this._bannerService.saveOrUpdateBanner(formData).subscribe(async (results) => {
        this.blockUI.stop();
        this._commonService.checkSuccess(results);
        this._route.navigate(["admin/banner"]);
      }, (error) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
      });

      console.log(this.ReactiveUserDetailsForm.controls);
    }
   
    
  }

  get ReactiveUDForm() {
    return this.ReactiveUserDetailsForm.controls;
  }

  /**
    Used to set variable for image cropper when open profile picture popup
   */
  setProfilePictureModal(profilePictureModal) {
    
    this.oldImage = this.oldImage;
    this.CropImageUrl = this.oldImage;
    this.lastImage = this.oldImage;
    this.isUploadLogoValid = false;
    this.isUploadLogoSize = false;
    this.isUploadLogoType = false;
    this.isUpdateButton = false;
    this.isCropperShow = false;

    this.modalReference = this._modalService.open(profilePictureModal, {
      centered: true
    });
  }

  /**
     * Used to handle cropp object display size after image is loaded in crop element
     */
  imageLoaded() {
    setTimeout(() => {
      this.cropperReady();
    }, 2);
  }

  cropperReady() {
    // this.cropper = {
    //   x1: 30,
    //   y1: 30,
    //   x2: 150,
    //   y2: 150
    // }
  }

  /**
     * Crop image
     */
  imageCropped(event: ImageCroppedEvent) {
    //this.closeMessageAlert();
    this.isCropperShow = true;
    this.isUploadLogoValid = false;
    this.isUploadLogoSize = false;
    this.isUploadLogoType = false;
    this.compressFile(event.base64);
  }

  /**
   * Upload and compress image
   */
  compressFile(image) {
    let orientation = -1;

    if (image == null) {

      this.imageUrl = this.lastImage;
      this.CropImageUrl = this.lastImage;
      this.oldImage = this.lastImage;
      this.isUpdateButton = false;

    } else {
      this._imageCompress.compressFile(image, orientation, 40, 40).then(result => {
        this.imageUrl = result;
        this.CropImageUrl = result;
        this.oldImage = null;
        this.isUpdateButton = true;
      });
    }
  }

  /**
   * Upload image
   */
  fileChangeEvent(event: any): void {
    this.isUploadLogoValid = false;
    this.isUploadLogoSize = false;
    this.isUploadLogoType = false;
    let file = event.target.files[0];
    if (file != undefined) {
      this.imageFileName = file.name;
      this.fileLoadAndValidate(event, file);
      this.imageChangedEvent = event;
      this.isUploadLogoValid = false;
      this.isUploadLogoSize = false;
      this.isUploadLogoType = false;
      if (this.imageChangedEvent.target.files.length == 0)
        this.cropperShow();
    }
  }

  /**
   * file load and get width/height and validate file
   */
  fileLoadAndValidate(event, file) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      if (file.type == "image/jpeg" || file.type == "image/png") {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_width < '100' || img_height < '100') {

            this.isUploadLogoValid = true;
            this.imageChangedEvent = null;
            this.isCropperShow = false;
            this.compressFile(null);
          } else if ((file.size / 1024) > 2048) {

            this.isUploadLogoSize = true;
            this.imageChangedEvent = null;
            this.isCropperShow = false;
            this.compressFile(null);
          } else {
            this.isCropperShow = true;
          }
        };
      } else {
        this.isUploadLogoType = true;
        this.imageChangedEvent = null;
        this.isCropperShow = false;
        this.compressFile(null);
      }
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  /**
    * close cropper while file event close
    */
  cropperShow() {
    this.isCropperShow = false;
    this.isUploadLogoValid = false;
    this.isUploadLogoSize = false;
    this.isUploadLogoType = false;
    this.compressFile(null);
  }

  /**
  This code for set image as a 64baseUrl in form
      Update logo
   */
  updateLogo() {
    let imageData = '';
    this.isUpdateButton = false;
    this.isCropperShow = false;
    this.isUploadLogoValid = false;
    this.isUploadLogoSize = false;
    this.isUploadLogoType = false;

    if (this.imageUrl != null) {
      this.oldImage = this.CropImageUrl.split(',')[1];
      imageData = this.imageUrl.split(",")[1];
    } else {
      imageData = this.lastImage;
    }

    this.bannerImagePath = '';
    this.saveProfilePicture(imageData);
    this.modalReference.close();
  }

  /**
    Convert Base64 image to File
   */
  imageBase64DataToFile(imageBase64Data, fileName, fileType) {

    var mime = fileType;
    var bstr = atob(imageBase64Data);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: fileType });
  }

  /**
  * Remove company logo
  */
  removeLogo() {
    this.isUploadLogoValid = false;
    this.isUploadLogoSize = false;
    this.isUploadLogoType = false;
    this.imageChangedEvent = null;
    this.isCropperShow = false;
    this.bannerImage = null;

    if (this.lastImage == null) {
      this.imageUrl = null;
      this.oldImage = null;
      this.CropImageUrl = null;
    } else {
      const swalWithBootstrapButtons = swal.mixin({
        customClass: {
          confirmButton: 'btn btn_global btn_global-swal',
          cancelButton: 'btn btn_global red btn_global-swal'
        },
        buttonsStyling: true,
      })
      swalWithBootstrapButtons.fire({
        title: '',
        text: 'Are you sure you want to remove your profile pic?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("image");
          // this.profileImage = CONSTANTS.DEFAULT_PROFILE_IMAGE;
          this.imageUrl = null;
          this.oldImage = null;
          this.CropImageUrl = null;
          this.saveProfilePicture(null);
          this.isUpdateButton = false;
        } else {
          this.isUpdateButton = false;
        }
      });
    }
  }

  /**
  	Save and Remove profile picture from directory
   */
  saveProfilePicture(imageData){
  	this.bannerImage = imageData ;
  	
  }

}
