import { Injectable } from "@angular/core";
import{ Cookie }from'ng2-cookies';
import { CONSTANTS } from "./service.assets";

@Injectable({ providedIn: 'root' })
export class CookieService {

    /**
     * remove all cookie
     */
     clearCookie() {
        Cookie.deleteAll();
    }

    /**
	 * remove cookie
	 */
	removeCookie(key: string) {
        
		let path: string = "/";

		Cookie.delete(key, path);
	}

    /**
     * check cookie
     * @param key 
     * @returns 
     */
    checkCookie(key: string): boolean {
		return Cookie.check(key);
	}

    /**
     * get cookie
     * @param key 
     * @returns 
     */
    getCookie(key: string): any {        
		return Cookie.get(key);
	}

    /**
	 * Set cookie
	 */
	setCookie(key: string, value: any, cookieExpirationDays: number) {
		let path: string = "/";
		//If cookie already exists then remove it
		if (this.getCookie(key)) {
			this.removeCookie(key);
		}
		if (cookieExpirationDays) {
			Cookie.set(key, value, (cookieExpirationDays / 60 / 60 / 24), path);
			//Cookie.set(key, value, cookieExpirationDays);
		}
		else {
			Cookie.set(key, value, undefined, path);
		}
	}

	static getShowRole(): boolean {
        let permission = Cookie.get(CONSTANTS.PERMISSION);
        let isFounded = CONSTANTS.ROLE_PERMISSION.some( ai => permission.includes(btoa(ai)) );
		console.log("----------------- isFounded ---------- getShowRole ---------");
		console.log(permission)
		console.log(isFounded);
        if(permission.indexOf(btoa('all')) >= 0 || isFounded) {
            return false;
        }
        return true;
    }
    static getShowRoleUser(): boolean {
       let permission =  Cookie.get(CONSTANTS.PERMISSION);

       let isFounded = CONSTANTS.ROLE_USER_PERMISSION.some( ai => permission.includes(btoa(ai)) );
	   console.log("----------------- isFounded ---------- getShowRoleUser ---------");
		console.log(permission)
		console.log(isFounded);
       if(permission.indexOf(btoa('all')) >= 0  || isFounded) {
            return false;
       }
       return true;
    }
    static getShowAppUser(): boolean {
        let permission =  Cookie.get(CONSTANTS.PERMISSION);
 
        let isFounded = CONSTANTS.APP_USER_PERMISSION.some( ai => permission.includes(btoa(ai)) );
        console.log("----------------- isFounded ---------- getShowAppUser ---------");
         console.log(permission)
         console.log(isFounded);
        if(permission.indexOf(btoa('all')) >= 0  || isFounded) {
             return false;
        }
        return true;
    }

    static getShowBanner(): boolean {
        let permission =  Cookie.get(CONSTANTS.PERMISSION);
 
        let isFounded = CONSTANTS.BANNER_PERMISSION.some( ai => permission.includes(btoa(ai)) );
        console.log("----------------- isFounded ---------- getShowBanner ---------");
         console.log(permission)
         console.log(isFounded);
        if(permission.indexOf(btoa('all')) >= 0  || isFounded) {
             return false;
        }
        return true;
    }
    static getShowCmsPages(): boolean {
        let permission =  Cookie.get(CONSTANTS.PERMISSION);
 
        let isFounded = CONSTANTS.CMS_PAGES_PERMISSION.some( ai => permission.includes(btoa(ai)) );
        console.log("----------------- isFounded ---------- getShowBanner ---------");
         console.log(permission)
         console.log(isFounded);
        if(permission.indexOf(btoa('all')) >= 0  || isFounded) {
             return false;
        }
        return true;
    }
    static getAdmin(): boolean {
        if (atob(localStorage.getItem(CONSTANTS.ROLE)) == 'ROLE_MASTER'){
            return false;
        }else{
            return true;
        }
        
    }
    


}