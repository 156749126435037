import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/auth/service/common.service';
import { RoleService } from 'app/auth/service/role.service';
import { SubAdminService } from 'app/auth/service/sub-admin.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-add-sub-admin',
  templateUrl: './add-sub-admin.component.html',
  styleUrls: ['./add-sub-admin.component.scss']
})
export class AddSubAdminComponent implements OnInit {

  constructor(private _activeRouter : ActivatedRoute,
              private _subAdminService : SubAdminService,
              private _commonService : CommonService,
              private formBuilder: FormBuilder,
              private _roleService : RoleService,
              private _route: Router) { }

    private subAdminId : any;
    public contentHeader: object;

    public ReactiveUserDetailsForm: FormGroup;
    public ReactiveUDFormSubmitted = false;
    public submitted = false;
    public RoleList = [];

    // Reactive User Details form data
    public UDForm = {
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
    };

  @BlockUI() blockUI: NgBlockUI;

  async ngOnInit() {
    this._activeRouter.queryParams.subscribe(params => {
      this.subAdminId = params['id'] ? atob(params['id']) : null;
    });

    this.ReactiveUserDetailsForm = this.formBuilder.group({
      id:[null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      encAdminRoleId: ['', [Validators.required]],  
      mobileNumber: ['', [Validators.required]],
      countryCode: ['+91', [Validators.required]],
      deviceType: [null],
    });
    await this.getRoleList();
    await this.getSubAdminDetailAndSetHeader();
  }

  getSubAdminDetailAndSetHeader(){

    if (this.subAdminId != undefined || this.subAdminId != null) {
      this.contentHeader = {
        headerTitle: 'Role User',
        actionButton: true,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'Role User',
              isLink: true,
              link: '../'
            },
            {
              name: 'edit',
              isLink: false
            }
          ]
        }
      }
      this.blockUI.start();
      this.ReactiveUserDetailsForm.controls.id.setValue(this.subAdminId);
      this._subAdminService.getSubAdminById(this.subAdminId).subscribe(async ( results ) => {
        this.blockUI.stop();
        this.ReactiveUserDetailsForm.controls.firstName.setValue(results.data.firstName);
        this.ReactiveUserDetailsForm.controls.lastName.setValue(results.data.lastName);
        this.ReactiveUserDetailsForm.controls.email.setValue(results.data.email);
        this.ReactiveUserDetailsForm.controls.encAdminRoleId.setValue(results.data.encAdminRoleId);
        this.ReactiveUserDetailsForm.controls.mobileNumber.setValue(results.data.mobileNumber);
      },( error ) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
      });
      
    } else {
      this.contentHeader = {
        headerTitle: 'Role User',
        actionButton: false,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'Role User',
              isLink: true,
              link: '../'
            },
            {
              name: 'add',
              isLink: false
            }
          ]
        }
      }
    }

  }

  ReactiveUDFormOnSubmit() {
    this.ReactiveUDFormSubmitted = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.ReactiveUserDetailsForm.invalid) {
      return;
    }
    this.blockUI.start();
    this.ReactiveUserDetailsForm.controls.deviceType.setValue("WEB");
    this._subAdminService.saveOrUpdateSubAdmin(this.ReactiveUserDetailsForm.value).subscribe(async ( results ) => {
      this.blockUI.stop();
      this._commonService.checkSuccess(results);
      this._route.navigate(["admin/roleUser"]);
    },( error ) => {
      this.blockUI.stop();
      this._commonService.checkError(error);
    });

    console.log(this.ReactiveUserDetailsForm.controls);
  }

  get ReactiveUDForm() {
    return this.ReactiveUserDetailsForm.controls;
  }

  getRoleList(){
    this.blockUI.start();
    this._roleService.getAllRole().subscribe(async ( results ) => {
      this.blockUI.stop();
      this.RoleList = results.data.content;
    },( error ) => {
      this.blockUI.stop();
      this._commonService.checkError(error);
    });
  }
}
