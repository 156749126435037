<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      <section id="banner-page">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body pb-1">
  
                <form [formGroup]="ReactiveUserDetailsForm" (ngSubmit)="ReactiveUDFormOnSubmit()">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDTitle">Title</label>
                        <input type="text" id="UDTitle" [(ngModel)]="UDForm.title" formControlName="title"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.title.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.title.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.title.errors.required">Title is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDUrl">External Link</label>
                        <input type="text" id="UDUrl" formControlName="url" [(ngModel)]="UDForm.url"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.url.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.url.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.url.errors.required">External Link is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="UDPosition">Position</label>
                        <input type="text" id="UDPosition" pattern="^-?(0|[1-9]\d*)?$" formControlName="position"
                          [(ngModel)]="UDForm.position" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.position.errors }" />
                        <div *ngIf="submitted && ReactiveUDForm.position.errors" class="invalid-feedback">
                          <div *ngIf="ReactiveUDForm.position.errors.required">Position is required</div>
                          <div *ngIf="ReactiveUDForm.position.errors.pattern" >Please enter valid Position</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <!-- <label><a href="javascript:void(0)" class="d-block" data-toggle="modal"
                                data-target="#profilePictureModal" (click)="setProfilePictureModal(profilePictureModal)"
                                class="edit_ic">harsh</a></label> -->
                            <img *ngIf="bannerImagePath" class="profile" [src]="bannerImagePath" style="width: 100%;" />
                            <figure>								
                              <a href="javascript:void(0)" class="d-block" data-toggle="modal"
                                data-target="#profilePictureModal" (click)="setProfilePictureModal(profilePictureModal)"
                                class="edit_ic">                                
                                <img class="profile" [src]="oldImage ? 'data:image/png;base64,'+oldImage : '../../../../assets/images/uploadFile.png'"  />
                              </a>
                            </figure>
                            <div *ngIf="submitted && isInvalidImage" >
                                <div *ngIf="isInvalidImage" class="error-msg">Banner Image is required</div>
                            </div>
                        </div>
                      </div>
                    
                    <div class="col-12">
                      <div class="form-group">
                        <button class="btn btn-primary" rippleEffect>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </div>
  
  <ng-template #profilePictureModal let-modal>
		<div class="modal-dialog modal-lg modal-dialog-centered add-banner-modal" role="document">
			<div class="modal-content">
				<div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Banner</h5>
                    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
				<div class="modal-body">
					<div class="row d-flexbox profile-image profile-image">
					
						<div class="col-sm-12 text-center">
							<div class="user-img">
								<image-cropper *ngIf="isCropperShow" [imageChangedEvent]="imageChangedEvent"
									[maintainAspectRatio]="true" [containWithinAspectRatio]="false" format="png"
									accept="image/*" [roundCropper]="true" [cropperMinWidth]="500" [roundCropper]="false"
									[cropperMinHeight]="200" [onlyScaleDown]="false" [cropper]="cropper"
									(imageLoaded)="imageLoaded()" (imageCropped)="imageCropped($event)"
									(cropperReady)="cropperReady()"
                                    [aspectRatio]="16 / 9">
								</image-cropper>

								<img *ngIf="oldImage" [src]="'data:image/png;base64,'+oldImage" alt="" class="" style="width: 250px;">
                                <img *ngIf="(CropImageUrl == null || CropImageUrl == '' )"
									src="../../../../assets/images/uploadFile.png" alt="" class="" />
								 <label
									*ngIf="isUploadLogoValid" class="color-red">Please upload image with minimum size
									100 X 100</label>
								<label *ngIf="isUploadLogoSize" class="color-red">An image should be less than
									2MB</label>
								<label *ngIf="isUploadLogoType" class="color-red">Upload valid image. Only PNG and JPEG
									are allowed</label>
							</div>
						</div>
					</div>
					<div class="row d-flexbox profile-image">
						<div class="col-sm-12 text-center">
							<ul class="list-inline pt-lg-3">
								<li *ngIf="!isUpdateButton" class="btn btn-primary profileimg-btn btn_global"
									(click)="fileInput.click()"><input type="file" #fileInput type="file"
										accept="image/*" class="d-none" (click)="fileChangeEvent($event)"
										(change)="fileChangeEvent($event)" /> <i id="editIcon" title="Edit Logo"
										class="fas fa-pencil-alt dt-cursor"></i> Edit
								</li>
								<li *ngIf="!isDefaultProfile && oldImage"
									class="btn btn-danger profileimg-btn btn_global red" (click)="removeLogo()">
									<i class="far fa-trash-alt dt-cursor" id="editIcon" title="Remove Logo"></i> Remove
								</li>
								<li *ngIf="isUpdateButton" class="btn btn-primary profileimg-btn btn_global"
									(click)="updateLogo()"> Save</li>
								<li *ngIf="isUpdateButton" class="btn btn-danger profileimg-btn btn_global red"
									(click)="cropperShow()"> Cancel</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>