import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'app/auth/models/page';
import { AppUserService } from 'app/auth/service/app-user.service';
import { APIClientService } from 'app/auth/service/apiclient.service';
import { CommonService } from 'app/auth/service/common.service';
import { CookieService } from 'app/auth/service/cookie.service';
import { CONSTANTS } from 'app/auth/service/service.assets';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { URL } from "app/auth/service/service.assets";
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import * as $ from "jquery";
import { ExportToCsv } from 'export-to-csv';
@Component({
  selector: 'app-manage-transaction',
  templateUrl: './manage-transaction.component.html',
  styleUrls: ['./manage-transaction.component.scss']
})



export class ManageTransactionComponent implements OnInit {

  page = new Page();

  name = 'Angular';  
  keyword = 'firstName'; 

  constructor(
    private _activeRouter: ActivatedRoute,
    private _appUserService: AppUserService,
    private formBuilder: FormBuilder,
    private _apiClientService: APIClientService,
    private _commonService: CommonService,
    private _cookieService: CookieService,
    public datepipe: DatePipe,
    private _route: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }
  public contentHeader: object;
  public basicSelectedOption: number = 10;
  public userdata: any;
  public userAllList: any;
  maxBirthDate: any;

  pageLimitOptions: any = [1000];
  SORT_BY: string[] = ['firstName'];
  isDirectionDesc: boolean = true;
  private searchPolicyUserObs: Subscription;
  private searchTransactionObs: Subscription;
  public pageStart: any = 0;
  public ReactiveUserDetailsForm: FormGroup;
  public ReactiveUDFormSubmitted = false;
  public submitted = false;
  private appUserId: any;
  public initialValue: any;
  public transactionCSVList:any;
  public showDateErrorMessage = false;
  public settingdata: any ;
  public stripe_commition_percentage: number;
  public stripe_commition_fee: number;

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Transaction CSV',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    headers: ['transactionid', 'Transaction By User', 'Transaction To User', 'transactionType','Amount', 'Rate', 'Fees', 'Total Amount', 'Date']
  };
  


  fetchAppUserListParam = {
    limit: 10,
    pageNo: 1,
    searchString:"",
    sortBy: "createdDate",
    direction: "desc",
    user_id: '',
    start_date: '',
    end_date: ''
  };

  fetchCSVTransactionListParam = {            
    user_id: '',
    start_date: '',
    end_date: ''
  };
  

  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {
    this.searchRole(true);
    $("#showDateErrorMessage").hide();

    this.ReactiveUserDetailsForm = this.formBuilder.group({
      user_id: [''],
      start_date: [''],
      end_date: ['']
    });

    // $('.start-date-input').click(function (e) { $(this).focus(); })
    //     .change(function (e) {
    //       var myValue = $(this);
    //       if (!myValue.val()) {
    //         this.fetchAppUserListParam.start_date = '';
    //         this.fetchCSVTransactionListParam.start_date = '';
    //         this.ReactiveUserDetailsForm.controls['start_date'].setValue('');            
    //       } else {}
    //     });

   
    $('.end-date-input').change(function (e) {    
      $(this).focus();       
      if ($("#start_date").val()) {
        if ((Date.parse($("#start_date").val())) > (Date.parse($("#end_date").val()))) {
          $("#end_date").val('')      
          $("#showDateErrorMessage").show();    
          this.showDateErrorMessage = false;
        }else{
          $("#showDateErrorMessage").hide();    
        }
      }
    }); 
    

    this._activeRouter.queryParams.subscribe(params => {
      this.appUserId = params['id'] ? atob(params['id']) : null;
      this.fetchAppUserListParam.user_id = this.appUserId
      this.fetchCSVTransactionListParam.user_id = this.appUserId
    });
       

    let auxDate = this.substractYearsToDate(new Date(), 0);
    this.maxBirthDate = this.getDateFormateForSearch(auxDate);
    
    this.contentHeader = {
      headerTitle: 'User Transaction',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'User Transaction',
            isLink: false
          }
        ]
      }
    }   

    setTimeout(() => {
      this.userAll();
      this.searchUserTransaction(true);
      this.userCSVTransactionList(true);
    }, 500);
   
  }

  searchRole(loader){
    if(loader){
      this.blockUI.start()
    }
    this._apiClientService.getCall(URL.BASE_URL + "admin/settings/get")
      .subscribe(
        res => {
          this.blockUI.stop();
          this.settingdata = res.data;

          this.settingdata.forEach(element => {            
            if(element.settingsProperty == 'STRIPE_COMMITION_PERCENTAGE'){
              this.stripe_commition_percentage = element.value;
            }
            if(element.settingsProperty == 'STRIPE_COMMITION_FEE'){
              this.stripe_commition_fee = element.value;
            }           
          });

          console.log(this.stripe_commition_percentage)
          console.log(this.stripe_commition_fee)
        },
        err => {
          this.blockUI.stop();
          this._commonService.checkError(err);
        }
      );
  }

  userAll() {   
    return new Promise((resolve) => {

      this._apiClientService.getCall(URL.BASE_URL + "admin/user/list-without-pagination").subscribe(
          res => {
            this.blockUI.stop();
            this.userAllList = res.data;   
            this.userAllList.map(user => user.firstName = `${user.firstName} ${user.lastName} ${user.mobileNumber}`);                   

          if (this.appUserId) {
            let obj = this.userAllList.find(o => o.id === this.appUserId);            
            this.initialValue = obj;
            this.ReactiveUserDetailsForm.controls.user_id.setValue(this.appUserId);
          } 

          },
          err => {
            this.blockUI.stop();
            this._commonService.checkError(err);
          }
        );
    });
  }

  selectEvent(item) {    
    this.fetchAppUserListParam.user_id = item.id
    this.fetchCSVTransactionListParam.user_id = item.id
  }

  onChangeSearch(val: string) {
    console.log(val)
  }

  onFocused(e) {
    // do something when input is focused
  }
  onCleared(e) {
    this.fetchAppUserListParam.user_id = '';
    this.fetchCSVTransactionListParam.user_id = '';
  }

  substractYearsToDate(auxDate: Date, years: number): Date {
    auxDate.setFullYear(auxDate.getFullYear() - years);
    return auxDate;
  }

  getDateFormateForSearch(date: Date): string {
    let year = date.toLocaleDateString('es', { year: 'numeric' });
    let month = date.toLocaleDateString('es', { month: '2-digit' });
    let day = date.toLocaleDateString('es', { day: '2-digit' });
    return `${year}-${month}-${day}`;
  }
  
  /**
   * search sub admin
   * @returns 
   */
  searchUserTransaction(loader) {
    if (loader) {
      this.blockUI.start();
    }
    return new Promise((resolve) => {
      if (this.searchPolicyUserObs) {
        this.searchPolicyUserObs.unsubscribe();
      }
      this.searchPolicyUserObs = this._appUserService.searchUserTransaction(this.fetchAppUserListParam, true, false).subscribe(async (results) => {
        this.blockUI.stop();
        this.userdata = results.data.content;
        this.pageStart = (results.data.pageable.pageSize * results.data.pageable.pageNumber) + 1;
        this.page.totalElements = results.data.totalElements;
        resolve("");
      }, (error) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
        resolve("");
      });
    });
  }

  userCSVTransactionList(loader) {
    if (loader) {
      this.blockUI.start();
    }
    return new Promise((resolve) => {
      if (this.searchTransactionObs) {
        this.searchTransactionObs.unsubscribe();
      }
      this.searchTransactionObs = this._appUserService.searchUserTransaction(this.fetchCSVTransactionListParam, true, false).subscribe(async (results) => {
        this.blockUI.stop();
        //this.transactionCSVList = results.data.content;   
        
        this.transactionCSVList = results.data.map(data => {
          let retObj = {};
          retObj['transactionid'] = data.id;
          
          retObj['transaction_by_user'] = data.transactionByUser.firstName +' '+ data.transactionByUser.lastName +' - '+ data.transactionByUser.mobileNumber +' ('+  ((data.transactionByUser.userType == 1)?'User':'Cashier') +')'

          if (data.transactionType == 'WALLET_RECHARGE'){
            if (data.transactionByUser && data.transactionByUser.mobileNumber){
              retObj['transaction_to_user'] = data.transactionByUser.firstName +' '+ data.transactionByUser.lastName +' - '+ data.transactionByUser.mobileNumber + ' (' + ((data.transactionByUser.userType == 1) ? 'User' : 'Cashier') + ')' 
            }
          }
          if (data.transactionType != 'WALLET_RECHARGE') {
            if (data.transferToUser && data.transferToUser.mobileNumber) {
              retObj['transaction_to_user'] = data.transferToUser.firstName +' '+ data.transferToUser.lastName +' - '+ data.transferToUser.mobileNumber + ' (' + ((data.transferToUser.userType == 1) ? 'User' : 'Cashier') + ')' 
            }
          }
          
          if (data.transactionType == 'WALLET_RECHARGE'){
            retObj['transactiontype'] = 'WALLET RECHARGE';
          } else if (data.transactionType == 'RECEIVED'){
            retObj['transactiontype'] = 'RECEIVED';
          }else{
            retObj['transactiontype'] = 'TRANSFER';
          }

          if (data.mainPrice) {
            retObj['amount'] = data.mainPrice;
          } else {
            retObj['amount'] = "";
          }
          
          if (data.usdToMxnRate){
            retObj['rate'] = data.usdToMxnRate;
          }else{
            retObj['rate'] = '';
          }
          
          if (data.mainPrice) {
            if (data.transactionType == 'WALLET_RECHARGE') {
              retObj['fees'] = ((data.mainPrice * 2.9) / 100 + 0.30).toFixed(2)               
            }else{
              retObj['fees'] = data.fees;
            }            
          }

          if (data.mainPrice) {
            if (data.transactionType == 'WALLET_RECHARGE') {
              retObj['total_amount'] = (data.mainPrice + (data.mainPrice * 2.9) / 100 + 0.30).toFixed(2)   
            }else{
              retObj['total_amount'] = ((data.mainPrice * data.usdToMxnRate) - data.fees);
            }            
          }          
         
          
          retObj['date'] = this.datepipe.transform(data.createdDate, 'dd/MM/yyyy');
          return retObj;
        });   

        resolve("");
      }, (error) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
        resolve("");
      });
    });
  }

  /**
   * change page size and find data and set page 0
   * @param id 
   */
  changePageSize(id: any) {
    this.basicSelectedOption = id;
    this.fetchAppUserListParam.limit = id;
    this.page.size = id;
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
    this.searchUserTransaction(false);
  }
  /**
   * sort field name and set page 0
   * @param e 
   */
  onSort(e) {
    this.fetchAppUserListParam.direction = e.newValue;
    this.fetchAppUserListParam.sortBy = e.column.prop;
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
    this.searchUserTransaction(false);
  }
  /**
   * page change evnet set page number and find data
   * @param event 
   */
  setPage(event) {    
    this.page.pageNumber = event.offset;
    this.fetchAppUserListParam.pageNo = event.offset + 1;
    this.searchUserTransaction(false);
  }
  /**
   * search sub admin and set page 0
   */
  searchUserTransactionBySearchBox() {
    this.page.pageNumber = 0;
    this.fetchAppUserListParam.pageNo = 1;
    this.searchUserTransaction(false);
  }

  ReactiveUDFormOnSubmit() {

    if (this.ReactiveUserDetailsForm.get('start_date').value && this.ReactiveUserDetailsForm.get('end_date').value) {      
      if ((Date.parse(this.ReactiveUserDetailsForm.get('start_date').value)) > (Date.parse(this.ReactiveUserDetailsForm.get('end_date').value))) {
        this.showDateErrorMessage = true;
        $("#showDateErrorMessage").show(); 
        return;
      }
    }
    this.showDateErrorMessage = false;
    $("#showDateErrorMessage").hide(); 

    if (!$("#start_date").val()){
      this.fetchAppUserListParam.start_date = '';
      this.fetchCSVTransactionListParam.start_date = '';
      this.ReactiveUserDetailsForm.controls['start_date'].setValue('');           
    }

    if (!$("#end_date").val()) {
      this.fetchAppUserListParam.end_date = '';
      this.fetchCSVTransactionListParam.end_date = '';
      this.ReactiveUserDetailsForm.controls['end_date'].setValue('');
    }

    // if (parseInt(this.ReactiveUserDetailsForm.get('user_id').value) !== 0){
    //   this.fetchAppUserListParam.user_id = this.ReactiveUserDetailsForm.get('user_id').value 
    // }else{      
    //   this.fetchAppUserListParam.user_id = '';
    // }

    if (this.ReactiveUserDetailsForm.get('start_date').value){      
      this.fetchAppUserListParam.start_date = this.datepipe.transform(this.ReactiveUserDetailsForm.get('start_date').value, 'dd/MM/yyyy')
      this.fetchCSVTransactionListParam.start_date = this.datepipe.transform(this.ReactiveUserDetailsForm.get('start_date').value, 'dd/MM/yyyy')
    }
    if (this.ReactiveUserDetailsForm.get('end_date').value){
      this.fetchAppUserListParam.end_date = this.datepipe.transform(this.ReactiveUserDetailsForm.get('end_date').value, 'dd/MM/yyyy')  
      this.fetchCSVTransactionListParam.end_date = this.datepipe.transform(this.ReactiveUserDetailsForm.get('end_date').value, 'dd/MM/yyyy')
    }
    this.userCSVTransactionList(false);
    this.searchUserTransaction(false);
    

  }

  resetFilter(){
    this.fetchAppUserListParam.user_id = '';
    this.fetchAppUserListParam.start_date = ""; 
    this.fetchAppUserListParam.end_date = "";
    this.fetchCSVTransactionListParam.user_id = '';
    this.fetchCSVTransactionListParam.start_date = "";
    this.fetchCSVTransactionListParam.end_date = "";
    this.ReactiveUserDetailsForm.reset(); 
    $(".material-icons").click();   
    this.searchUserTransaction(false);
    this.userCSVTransactionList(false);

  }

  get ReactiveUDForm() {
    return this.ReactiveUserDetailsForm.controls;
  }

  
  download() {

    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(this.transactionCSVList);
  }

}
