<block-ui> </block-ui>
<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <section id="sub-admin-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body pb-1">

              <form [formGroup]="ReactiveUserDetailsForm" (ngSubmit)="ReactiveUDFormOnSubmit()">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="UDFirstName">First Name</label>
                      <input type="text" id="UDFirstName" [(ngModel)]="UDForm.firstName" formControlName="firstName"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.firstName.errors }" />
                      <div *ngIf="submitted && ReactiveUDForm.firstName.errors" class="invalid-feedback">
                        <div *ngIf="ReactiveUDForm.firstName.errors.required">First Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="UDLastName">Last Name</label>
                      <input type="text" id="UDLastName" formControlName="lastName" [(ngModel)]="UDForm.lastName"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.lastName.errors }" />
                      <div *ngIf="submitted && ReactiveUDForm.lastName.errors" class="invalid-feedback">
                        <div *ngIf="ReactiveUDForm.lastName.errors.required">Last Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="UDEmail">Email <span style="padding-left: 10px;"><i data-feather="info" [size]="15" class="mr-25 all-header-component"></i> User will receive instructions to set password on this email</span></label>
                      <input type="text" id="UDEmail" formControlName="email" [(ngModel)]="UDForm.email"
                      pattern="^[_\.0-9a-zA-Z-]+@([0-9a-zA-Z][0-9a-zA-Z-]+)+((\.)[a-zA-Z]{2,})+$"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.email.errors }" />
                      <div *ngIf="submitted && ReactiveUDForm.email.errors" class="invalid-feedback">
                        <div *ngIf="ReactiveUDForm.email.errors.required">Email is required</div>
                        <div *ngIf="ReactiveUDForm.email.errors.email || ReactiveUDForm.email.errors.pattern" >Email must be a valid email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="UDMobileNumber">Mobile Number</label>
                      <input type="text" id="UDMobileNumber" pattern="^-?(0|[1-9]\d*)?$" formControlName="mobileNumber"
                        [(ngModel)]="UDForm.mobileNumber" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.mobileNumber.errors }" />
                      <div *ngIf="submitted && ReactiveUDForm.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="ReactiveUDForm.mobileNumber.errors.required">Mobile Number is required</div>
                        <div *ngIf="ReactiveUDForm.mobileNumber.errors.pattern" >Please enter valid mobile number</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="encAdminRoleId">Role</label>
                      <select id="encAdminRoleId" formControlName="encAdminRoleId" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.encAdminRoleId.errors }">
                        <option [value]="role.id" *ngFor="let role of RoleList">{{role.name}}</option>
                      </select>
                      <div *ngIf="submitted && ReactiveUDForm.encAdminRoleId.errors" class="invalid-feedback">
                        <div *ngIf="ReactiveUDForm.encAdminRoleId.errors.required">Role is required</div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-6">
                    <div class="form-group">
                      <label for="UDDeviceType">DeviceType</label>
                      <select id="UDDeviceType" formControlName="deviceType" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.deviceType.errors }">
                        <option selected value="WEB">WEB</option>
                        <option value="ANDROID">ANDROID</option>
                        <option value="IOS">IOS</option>
                      </select>
                      <div *ngIf="submitted && ReactiveUDForm.deviceType.errors" class="invalid-feedback">
                        <div *ngIf="ReactiveUDForm.deviceType.errors.required">DeviceType is required</div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="col-6">
                    <div class="form-group">
                      <label for="UDCountryCode">Country Code</label>
                      <input type="text" id="UDCountryCode" formControlName="countryCode"
                        [(ngModel)]="UDForm.countryCode" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && ReactiveUDForm.countryCode.errors }" />
                      <div *ngIf="submitted && ReactiveUDForm.countryCode.errors" class="invalid-feedback">
                        <div *ngIf="ReactiveUDForm.countryCode.errors.required">Country Code is required</div>
                      </div>
                    </div>
                  </div> -->
                  
                  <div class="col-12">
                    <div class="form-group">
                      <button class="btn btn-primary" rippleEffect>Submit</button>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>