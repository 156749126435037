import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'app/auth/models/page';
import { CmsPagesService } from 'app/auth/service/cmsPages.service';
import { CommonService } from 'app/auth/service/common.service';
import { CookieService } from 'app/auth/service/cookie.service';
import { CONSTANTS } from 'app/auth/service/service.assets';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'app/auth/service/localstorage.service';

@Component({
  selector: 'app-manage-cms-page',
  templateUrl: './manage-cms-page.component.html',
  styleUrls: ['./manage-cms-page.component.scss']
})
export class ManageCmsPageComponent implements OnInit {
  
  page = new Page();

  constructor(private _cmsPagesService : CmsPagesService,
              private _commonService : CommonService,
              private _cookieService : CookieService,
              private _localStorageService: LocalStorageService,
              private _route : Router) { 
                this.page.pageNumber = 0;
                this.page.size = 10;
              }

  public contentHeader: object;
  public basicSelectedOption: number = 10;
  public data: any ;

  isDirectionDesc: boolean = true;
  private searchPolicyUserObs: Subscription;
  public pageStart: any = 0;
  public isEditButton: boolean = false;
  
  fetchCmsPagesListParam = {
    limit: 10,
    pageNo: 1,
    searchString: "",
    sortBy: "createdDate",
    direction: "desc",
  };
  @BlockUI() blockUI: NgBlockUI;
  ngOnInit(): void {

    const userRole = this._localStorageService.getLocalStorage(CONSTANTS.ROLE);    
    if(userRole == 'ROLE_MASTER'){   
      this.isEditButton = true;
    }else{
      let permission =  this._cookieService.getCookie(CONSTANTS.PERMISSION);
      if(permission.indexOf(btoa('all')) >= 0 || permission.indexOf(btoa('UPDATE_CMS_PAGES')) >= 0){
        this.isEditButton = true;
      }
    }
    

    this.contentHeader = {
      headerTitle: 'Manage CMS',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Manage CMS',
            isLink: false
          }
        ]
      }
    }
    this.searchCmsPages(true);
  }

  searchCmsPages(loader){
    if(loader){
      this.blockUI.start();
    }
    return new Promise( (resolve)=>{
      if(this.searchPolicyUserObs){
        this.searchPolicyUserObs.unsubscribe();
      }
      this.searchPolicyUserObs = this._cmsPagesService.searchCmsPages(this.fetchCmsPagesListParam,false).subscribe(async ( results ) => {
        this.blockUI.stop();
        this.data = results.data.content;
        this.pageStart = (results.data.pageable.pageSize * results.data.pageable.pageNumber) + 1;
        this.page.totalElements = results.data.totalElements;
        resolve("");
      },( error ) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
        resolve("");
      });
    });
  }

  editSelectedCmsPage(id : any){
    this._route.navigate(["admin/cmsPages/add"], { queryParams: { id : btoa(id)}});
  }

  /**
   * change page size 
   * @param id 
   */
   changePageSize(id : any){
    this.basicSelectedOption = id;
    this.fetchCmsPagesListParam.limit = id;
    this.page.size = id;
    this.page.pageNumber = 1;
    this.fetchCmsPagesListParam.pageNo = 1;
    this.searchCmsPages(false);
  }
  /**
   * on sort set colom and find data and set page 1
   * @param e 
   */
  onSort(e){
    this.fetchCmsPagesListParam.direction = e.newValue;
    this.fetchCmsPagesListParam.sortBy = e.column.prop;
    this.page.pageNumber = 1;
    this.fetchCmsPagesListParam.pageNo = 1;
    this.searchCmsPages(false);
  }
  /**
   * page chage set page and according find data
   * @param event 
   */
  setPage(event){
    this.page.pageNumber = event.offset;
    this.fetchCmsPagesListParam.pageNo = event.offset;
    this.searchCmsPages(false);
  }
  /**
   * search box value wise find and set page 0
   */
  searchCmsPagesBySearchBox(){
    this.page.pageNumber = 1;
    this.fetchCmsPagesListParam.pageNo = 1;
    this.searchCmsPages(false);
  }

}
