import { DIR_DOCUMENT } from "@angular/cdk/bidi";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { APIClientService } from "./apiclient.service";
import { URL } from "./service.assets";

@Injectable({ providedIn: 'root' })
export class AppUserService {

    constructor(private _apiClientService: APIClientService){}

    /**
     * search role
     * @param fetchRoleListParam 
     * @param isActive 
     * @param isDeleted 
     * @returns 
     */
    searchAppUser(fetchAppUserListParam : any,isActive : any, isDeleted : any){
        // const formData = new FormData();
        var urlencoded = new URLSearchParams();
        urlencoded.append('name', fetchAppUserListParam.searchString);
        urlencoded.append('direction', fetchAppUserListParam.direction);
        urlencoded.append('sortBy', fetchAppUserListParam.sortBy);
        urlencoded.append('limit', fetchAppUserListParam.limit);
        urlencoded.append('pageNo', fetchAppUserListParam.pageNo);
      //  urlencoded.append('isDeleted', isDeleted);
        urlencoded.append('isActvie', isActive);
        urlencoded.append('mobileNumber', fetchAppUserListParam.searchString);
        urlencoded.append('email', fetchAppUserListParam.searchString);
        urlencoded.append('deviceType', fetchAppUserListParam.searchString);
        
      return this._apiClientService.doPostXURL(URL.SEARCH_APP_USER,urlencoded).pipe(
			    map(response => response)
		    );
    }

  searchUserTransaction(fetchAppUserListParam: any, isActive: any, isDeleted: any) {
    // const formData = new FormData();
    var urlencoded = new URLSearchParams();    
    //urlencoded.append('sortBy', fetchAppUserListParam.sortBy);
    if (fetchAppUserListParam.limit){
      urlencoded.append('limit', fetchAppUserListParam.limit);
    }
    if (fetchAppUserListParam.pageNo){
      urlencoded.append('pageNo', fetchAppUserListParam.pageNo);    
    }
    if (fetchAppUserListParam.user_id){
      var user_id: any[] = fetchAppUserListParam.user_id
      urlencoded.append('userEncIds', fetchAppUserListParam.user_id);
    }
    if (fetchAppUserListParam.end_date){
      urlencoded.append('endDate', fetchAppUserListParam.end_date);
    }
    if (fetchAppUserListParam.start_date) { 
      urlencoded.append('startDate', fetchAppUserListParam.start_date);
    }

    return this._apiClientService.doPostXURL(URL.SEARCH_USER_TRANSACTION, urlencoded).pipe(
      map(response => response)
    );
  } 

  requestUserForLevel(fetchAppUserListParam: any, isActive: any, isDeleted: any) {
    // const formData = new FormData();
    var urlencoded = new URLSearchParams();
    //urlencoded.append('sortBy', fetchAppUserListParam.sortBy);
    if (fetchAppUserListParam.limit) {
      urlencoded.append('limit', fetchAppUserListParam.limit);
    }
    if (fetchAppUserListParam.pageNo) {
      urlencoded.append('pageNo', fetchAppUserListParam.pageNo);
    }
    if (fetchAppUserListParam.user_id) {
      var user_id: any[] = fetchAppUserListParam.user_id
      urlencoded.append('userEncIds', fetchAppUserListParam.user_id);
    }
    if (fetchAppUserListParam.end_date) {
      urlencoded.append('endDate', fetchAppUserListParam.end_date);
    }
    if (fetchAppUserListParam.start_date) {
      urlencoded.append('startDate', fetchAppUserListParam.start_date);
    }

    return this._apiClientService.doPostXURL(URL.REQUEST_DOC_LEVEL_LIST, urlencoded).pipe(
      map(response => response)
    );
  }

  userAllList() {
    return this._apiClientService.getCall(URL.GET_ALL_USER_LIST).pipe(
      map(response => response)
    );
  }

    

    /**
     * get roleby id
     * @param id 
     * @returns 
     */
    getAppUserById(id : any){
      
      return this._apiClientService.doGet(URL.GET_APP_USER_BY_ID+"?uId="+id).pipe(
			  map(response => response)
		  );
    }

    getAppUserDocumetId(id: any) {

      return this._apiClientService.doGet(URL.GET_APP_USER_DOCUMENT_BY_ID + "?userId=" + id).pipe(
        map(response => response)
      );
    }

    getAppUserDocumet(id: any) {

      return this._apiClientService.doGet(URL.GET_APP_USER_DOCUMENT + "?userId=" + id).pipe(
        map(response => response)
      );
    }

    postAppUserDocumet(id: any, formData) {
      return this._apiClientService.doPost(URL.GET_APP_USER_DOCUMENT, formData).pipe(
        map(response => response)
      );
    }

    postDeleteDocumentData(urlencode) {

      return this._apiClientService.doPost(URL.POST_DELETE_DOCUMENT, urlencode).pipe(
        map(response => response)
      );
    }

    postExternalIdClick(urlencode) {

      return this._apiClientService.doPost(URL.UPDATE_EXTERNAL_ID, urlencode).pipe(
        map(response => response)
      );
    }

    updateUserExternalIdClick(urlencode) {

      return this._apiClientService.doPost(URL.UPDATE_USER_EXTERNAL_ID, urlencode).pipe(
        map(response => response)
      );
    }

    /**
     * delete role by id
     * @param id 
     * @returns 
     */
    deleteAppUserId(id : any){
      
      const formData = new FormData();
      formData.append('uId', id);
      return this._apiClientService.doPost(URL.DELETE_APP_USER_BY_ID,formData).pipe(
			  map(response => response)
		  );
    }
    
    /**
     * save or update role
     * @param formData 
     * @returns 
     */
    saveOrUpdateAppUser(formData : any){
        return this._apiClientService.doPost(URL.SAVE_OR_UPDATE_APP_USER,formData).pipe(
			map(response => response)
		);
    }

    searchNotification(fetchAppUserListParam : any,isActive : any, isDeleted : any){
        // const formData = new FormData();
        var urlencoded = new URLSearchParams();
        urlencoded.append('name', fetchAppUserListParam.searchString);
        urlencoded.append('direction', fetchAppUserListParam.direction);
        urlencoded.append('sortBy', fetchAppUserListParam.sortBy);
        urlencoded.append('limit', fetchAppUserListParam.limit);
        urlencoded.append('pageNo', fetchAppUserListParam.pageNo);
        urlencoded.append('isDeleted', isDeleted);
        urlencoded.append('isActvie', isActive);
        urlencoded.append('mobileNumber', fetchAppUserListParam.searchString);
        urlencoded.append('email', fetchAppUserListParam.searchString);
        urlencoded.append('deviceType', fetchAppUserListParam.searchString);
        
      return this._apiClientService.doPostXURL(URL.SEARCH_NOTIFICATION, urlencoded).pipe(
			    map(response => response)
		    );
    }

    sendnotification(data){
      return this._apiClientService.doPostRaw(URL.SEND_NOTIFICATION, data).pipe(
			    map(response => response)
		    );
    }

  /**
   * get document by id
   * @param id 
   * @returns 
   */
  getDocumentAppUserById(fetchAppUserListParam : any,isActive : any, isDeleted : any) {

    var urlencoded = new URLSearchParams();    
    urlencoded.append('direction', fetchAppUserListParam.direction);
    urlencoded.append('sortBy', fetchAppUserListParam.sortBy);
    urlencoded.append('limit', fetchAppUserListParam.limit);
    urlencoded.append('pageNo', fetchAppUserListParam.pageNo);    

    var param = "?limit=" + fetchAppUserListParam.limit+"&pageNo=" + fetchAppUserListParam.pageNo;
    if(fetchAppUserListParam.userId){
      urlencoded.append('userId', fetchAppUserListParam.userId);  
      param = "?userId=" + fetchAppUserListParam.userId+"&limit=" + fetchAppUserListParam.limit+"&pageNo=" + fetchAppUserListParam.pageNo;
    }
       

    // return this._apiClientService.doPostXURL(URL.VIEW_DOCUMENT_APP_USER + "?userId=" + fetchAppUserListParam.userId, urlencoded).pipe(
    //   map(response => response)
    // );    

    return this._apiClientService.doGet(URL.VIEW_DOCUMENT_APP_USER + param).pipe(
      map(response => response)
    );

    
  }




}