import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/auth/service/common.service';
import { PermissionService } from 'app/auth/service/permission.service';

@Component({
  selector: 'app-add-permission',
  templateUrl: './add-permission.component.html',
  styleUrls: ['./add-permission.component.scss']
})
export class AddPermissionComponent implements OnInit {

  constructor(private _activeRouter : ActivatedRoute,
              private _permissionService : PermissionService,
              private _commonService : CommonService,
              private _route: Router) { }

  public contentHeader: object;
  public permissionId: any;
  public name: any;

  ngOnInit(): void {
    this._activeRouter.queryParams.subscribe(params => {
      this.permissionId = params['id'] ? atob(params['id']) : null;
    });
    this.getPermissionDetailAndSetHeader();
    
  }


  getPermissionDetailAndSetHeader(){

    if (this.permissionId != undefined || this.permissionId != null) {
      this.contentHeader = {
        headerTitle: 'Permission',
        actionButton: true,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'Permission',
              isLink: true,
              link: '../'
            },
            {
              name: 'edit',
              isLink: false
            }
          ]
        }
      }
      this._permissionService.getPermissionById(this.permissionId).subscribe(async ( results ) => {
        this.name = results.data.name;
      },( error ) => {
        this._commonService.checkError(error);
      });
      
    } else {
      this.contentHeader = {
        headerTitle: 'Permission',
        actionButton: false,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'Permission',
              isLink: true,
              link: '../'
            },
            {
              name: 'add',
              isLink: false
            }
          ]
        }
      }
    }

  }

  save(PermissionForm){
    if(!PermissionForm.form.valid){
      return false;
    }
    let formData = new FormData();
    if (this.permissionId != undefined || this.permissionId != null) {
      formData.append('id', this.permissionId);
    }
    formData.append('name', PermissionForm.form.value.Name);
    this._permissionService.saveOrUpdatePermission(formData).subscribe(async ( results ) => {
      this._commonService.checkSuccess(results);
      this._route.navigate(["admin/permission"]);
    },( error ) => {
      this._commonService.checkError(error);
    });

  }

}
