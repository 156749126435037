import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommonService } from "../service/common.service";
import { CookieService } from "../service/cookie.service";
import { LocalStorageService } from "../service/localstorage.service";
import { ROUTS } from "../service/service.assets";

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private _router: Router,
                private _commonService: CommonService,
                private _cookieService:CookieService,
                private _localStorageService:LocalStorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            return next.handle(request).pipe(catchError(err => {
                
            if (err.status == 401) {
                    
                    this._cookieService.clearCookie();
                    this._localStorageService.clearLocalStorage();
                    this._commonService.sessionExpiredMessage = "Your session is expired. Please login again.";
                    
                    err = null;
                    //this.router.navigate([ROUTS.ERROR_401]);
                    this._router.navigate([ROUTS.SIGN_IN]);
                            
            }
            
            if (err.status == 403) {
                err = null;
                this._router.navigate([ROUTS.NOT_AUTHORIZED]);
            }
            
            if (err.status == 404) {
                let message = null;
                if(err.error.message){
                    message = err.error.message;
                }
                err = null;
                this._router.navigate([ROUTS.ERROR_404], { queryParams: { msg: message } });
            }
            
            if (err.status == 0) {
                err = null;
                this._router.navigate([ROUTS.MAINTENANCE]);     
            }
            return throwError(err);  
        }));
    }
}