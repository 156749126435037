import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/auth/service/common.service';
import { RoleService } from 'app/auth/service/role.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

  constructor(private _activeRouter : ActivatedRoute,
              private _roleService : RoleService,
              private _commonService : CommonService,
              private formBuilder: FormBuilder,
              private _route: Router) { }

  private roleId : any;
  public contentHeader: object;
  public permissionList: any = [];
  public allPermissionList: any = ['KMgwbdldzjjI5WxMOiQwiA', 'J42N7ZeVmutmiwy9VMJJVA', 'Y9Qfkovo1K1Ny-pTgcvQtg', 'QvsXBAU_3nNT282k6_cE-Q', 'eKSaoLqDuDmkzjszLrscGg', 'Fi_5SsL-r5M2m3lKX6IDEA', 'Mz3-wfIzCxf-pLSUbUHGJg', '23awvN6TvR72Y0TtidvB6A', 'YA06gXB678riRCaN6HjzUg', 'VPsGZ4h6s3XE1PRdAL6YRA', 'd6wN1H3VzISyHydACsHcCw', 'TTBcCcN5a2Rat-JnL6DKcA', 'Me7Wze8DsPESKZJ8ZESZQQ', 'nUp8TDGaXDHBfsG9Bs7t9Q', 'tSyaFNTTzsGns5MPbgXGqg', 'wMQADFfVE_MxRJQ-roPM2g', 'VLGMQhJjXln--4OysIKehg', 'rVcIlYPhxCE3chcZPpHKAA'];
  public allPermissionListCheckBox: any = ['all', 'cms-pages-all', 'banner-all', 'role-all', 'role-user-all', 'app-user-all', 'KMgwbdldzjjI5WxMOiQwiA', 'J42N7ZeVmutmiwy9VMJJVA', 'Y9Qfkovo1K1Ny-pTgcvQtg', 'QvsXBAU_3nNT282k6_cE-Q', 'eKSaoLqDuDmkzjszLrscGg', 'Fi_5SsL-r5M2m3lKX6IDEA', 'Mz3-wfIzCxf-pLSUbUHGJg', '23awvN6TvR72Y0TtidvB6A', 'YA06gXB678riRCaN6HjzUg', 'VPsGZ4h6s3XE1PRdAL6YRA', 'd6wN1H3VzISyHydACsHcCw', 'TTBcCcN5a2Rat-JnL6DKcA', 'Me7Wze8DsPESKZJ8ZESZQQ', 'nUp8TDGaXDHBfsG9Bs7t9Q', 'tSyaFNTTzsGns5MPbgXGqg', 'wMQADFfVE_MxRJQ-roPM2g', 'VLGMQhJjXln--4OysIKehg', 'rVcIlYPhxCE3chcZPpHKAA'];
  
  public roleAllList: any = ['KMgwbdldzjjI5WxMOiQwiA', 'J42N7ZeVmutmiwy9VMJJVA' , 'Y9Qfkovo1K1Ny-pTgcvQtg','QvsXBAU_3nNT282k6_cE-Q'];
  public roleUserAllList: any = ['eKSaoLqDuDmkzjszLrscGg', 'Fi_5SsL-r5M2m3lKX6IDEA', 'Mz3-wfIzCxf-pLSUbUHGJg', '23awvN6TvR72Y0TtidvB6A'];
  public appUserAllList: any = ['YA06gXB678riRCaN6HjzUg', 'VPsGZ4h6s3XE1PRdAL6YRA', 'd6wN1H3VzISyHydACsHcCw', 'TTBcCcN5a2Rat-JnL6DKcA'];
  public bannerAllList: any = ['Me7Wze8DsPESKZJ8ZESZQQ', 'nUp8TDGaXDHBfsG9Bs7t9Q', 'tSyaFNTTzsGns5MPbgXGqg', 'wMQADFfVE_MxRJQ-roPM2g'];
  public cmsPagesAllList : any = ['VLGMQhJjXln--4OysIKehg' , 'rVcIlYPhxCE3chcZPpHKAA' ];
  public transactionAllList : any = ['nsp04ykIziTFeWHgqfqu6A' ];
  public settingAllList : any = ['6pqYaWan5gV2iY69NCYcQA' , 'O5xx8dsKkXlcbRKOaHLwmA' ];
  public addRoleForm: FormGroup;
  public submitted = false;
  public name:any;

  @BlockUI() blockUI: NgBlockUI;

  async ngOnInit(){

    this.addRoleForm = this.formBuilder.group({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required]),
      gurdName: new FormControl(null),
      permissions: new FormControl([]),
    });

    this._activeRouter.queryParams.subscribe(params => {
      this.roleId = params['id'] ? atob(params['id']) : null;
    });
    await this.getRoleDetailAndSetHeader();
  }

  getRoleDetailAndSetHeader(){

    if (this.roleId != undefined || this.roleId != null) {
      this.contentHeader = {
        headerTitle: 'Role',
        actionButton: true,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'Role',
              isLink: true,
              link: '../'
            },
            {
              name: 'edit',
              isLink: false
            }
          ]
        }
      }
      this.blockUI.start();
      this._roleService.getRoleById(this.roleId).subscribe(async ( results ) => {
        this.blockUI.stop();
        this.addRoleForm.controls.name.setValue(results.data.name);
        this.permissionList = results.data.permissionIds;
        this.setCheckBoxValue();
      },( error ) => {
        this.blockUI.stop();
        this._commonService.checkError(error);
      });
      
    } else {
      this.contentHeader = {
        headerTitle: 'Role',
        actionButton: false,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'Role',
              isLink: true,
              link: '../'
            },
            {
              name: 'add',
              isLink: false
            }
          ]
        }
      }
    }

  }
  onSingleCheckBox(event){
    
    if(event.target.checked) { 
      this.permissionList.push(event.target.id);
    } else {
      var id = this.permissionList.indexOf(event.target.id);
      this.permissionList.splice(id,1);
    }

    let allRole = this.roleAllList.every( ai => this.permissionList.includes(ai) );
  
    if(allRole){
      (<HTMLInputElement>document.getElementById("role-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("role-all")).checked = false;
    }

    let allRoleUser = this.roleUserAllList.every( ai => this.permissionList.includes(ai) );
    if(allRoleUser){
      (<HTMLInputElement>document.getElementById("role-user-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("role-user-all")).checked = false;
    }

    if(allRoleUser && allRole) {
      (<HTMLInputElement>document.getElementById("all")).checked = true;
    } else{
      (<HTMLInputElement>document.getElementById("all")).checked = false;
    }

    let allAPPUser = this.appUserAllList.every( ai => this.permissionList.includes(ai) );
    if(allAPPUser){
      (<HTMLInputElement>document.getElementById("app-user-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("app-user-all")).checked = false;
    }

    let allBanner = this.bannerAllList.every( ai => this.permissionList.includes(ai) );
    console.log(allBanner)
    if(allBanner){
      (<HTMLInputElement>document.getElementById("banner-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("banner-all")).checked = false;
    }

    let allCmsPages = this.cmsPagesAllList.every( ai => this.permissionList.includes(ai) );
    if(allCmsPages){
      (<HTMLInputElement>document.getElementById("cms-pages-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("cms-pages-all")).checked = false;
    }

    let allTransactionPages = this.transactionAllList.every( ai => this.permissionList.includes(ai) );
    if(allTransactionPages){
      (<HTMLInputElement>document.getElementById("transaction-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("transaction-all")).checked = false;
    }

    let allSettingsPages = this.settingAllList.every( ai => this.permissionList.includes(ai) );
    if(allSettingsPages){
      (<HTMLInputElement>document.getElementById("setting-pages-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("setting-pages-all")).checked = false;
    }
    // this.filterErrorDataList = this.allErrorDataList.filter(n => n.file_name.toLowerCase().includes(this.searchStringValue.toLowerCase()));
  }
  onSelectAllRole(event){
    if(event.target.checked) { 
      this.permissionList = this.permissionList.filter( ( el ) => !this.roleAllList.includes( el ) );
      this.permissionList = this.permissionList.concat(this.roleAllList);
      this.roleAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = true;
      })
    } else {
      this.permissionList = this.permissionList.filter( ( el ) => !this.roleAllList.includes( el ) );
      this.roleAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = false;
      })
    }
    console.log(this.permissionList);
  }
  onSelectAllRoleUser(event){
    if(event.target.checked) { 
      this.permissionList = this.permissionList.filter( ( el ) => !this.roleUserAllList.includes( el ) );
      this.permissionList = this.permissionList.concat(this.roleUserAllList);
      this.roleUserAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = true;
      })
    } else {
      this.permissionList = this.permissionList.filter( ( el ) => !this.roleUserAllList.includes( el ) );
      this.roleUserAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = false;
      })
    }
    console.log(this.permissionList);
  }
  onSelectAllAppUser(event){
    if(event.target.checked) { 
      this.permissionList = this.permissionList.filter( ( el ) => !this.appUserAllList.includes( el ) );
      this.permissionList = this.permissionList.concat(this.appUserAllList);
      this.appUserAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = true;
      })
    } else {
      this.permissionList = this.permissionList.filter( ( el ) => !this.appUserAllList.includes( el ) );
      this.appUserAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = false;
      })
    }
  }
  onSelectAllBanner(event){
    if(event.target.checked) { 
      this.permissionList = this.permissionList.filter( ( el ) => !this.bannerAllList.includes( el ) );
      this.permissionList = this.permissionList.concat(this.bannerAllList);
      this.bannerAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = true;
      })
    } else {
      this.permissionList = this.permissionList.filter( ( el ) => !this.bannerAllList.includes( el ) );
      this.bannerAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = false;
      })
    }
  }

  onSelectAllCmsPages(event){
    if(event.target.checked) { 
      this.permissionList = this.permissionList.filter( ( el ) => !this.cmsPagesAllList.includes( el ) );
      this.permissionList = this.permissionList.concat(this.cmsPagesAllList);
      this.cmsPagesAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = true;
      })
    } else {
      this.permissionList = this.permissionList.filter( ( el ) => !this.cmsPagesAllList.includes( el ) );
      this.cmsPagesAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = false;
      })
    }
  }

  onSelectAllTransactionPages(event){
    if(event.target.checked) { 
      this.permissionList = this.permissionList.filter( ( el ) => !this.transactionAllList.includes( el ) );
      this.permissionList = this.permissionList.concat(this.transactionAllList);
      this.transactionAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = true;
      })
    } else {
      this.permissionList = this.permissionList.filter( ( el ) => !this.transactionAllList.includes( el ) );
      this.transactionAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = false;
      })
    }
  }

  onSelectAllSettingPages(event){
    if(event.target.checked) { 
      this.permissionList = this.permissionList.filter( ( el ) => !this.settingAllList.includes( el ) );
      this.permissionList = this.permissionList.concat(this.settingAllList);
      this.settingAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = true;
      })
    } else {
      this.permissionList = this.permissionList.filter( ( el ) => !this.settingAllList.includes( el ) );
      this.settingAllList.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = false;
      })
    }
  }


  onSelectAll(event) {

    if(event.target.checked) { 

      this.permissionList = [];
      this.permissionList = this.allPermissionList;
      this.allPermissionListCheckBox.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = true;
      })

    } else {

      this.allPermissionListCheckBox.forEach(item => {
        (<HTMLInputElement>document.getElementById(item)).checked = false;
      })
      this.permissionList = [];

    }
  }

  submit(RoleForm){
    this.submitted = true;

    if(!RoleForm.form.valid || this.permissionList.size > 0){
      return false;
    }
    
    if (this.roleId != undefined || this.roleId != null) {
      this.addRoleForm.controls.id.setValue(this.roleId);
    }
    this.blockUI.start();
    this.addRoleForm.controls.name.setValue(RoleForm.form.value.name);
    this.addRoleForm.controls.gurdName.setValue("WEB");
    this.addRoleForm.controls.permissions.setValue(this.permissionList);
    this._roleService.saveOrUpdateRole(this.addRoleForm.value).subscribe(async ( results ) => {
      this.blockUI.stop();
      this._commonService.checkSuccess(results);
      this._route.navigate(["admin/role"]);
    },( error ) => {
      this.blockUI.stop();
      this._commonService.checkError(error);
    });

  }
  setCheckBoxValue(){
    this.permissionList.forEach(item => {
      (<HTMLInputElement>document.getElementById(item)).checked = true;
    })

    let allRole = this.roleAllList.every( ai => this.permissionList.includes(ai) );
  
    if(allRole){
      (<HTMLInputElement>document.getElementById("role-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("role-all")).checked = false;
    }

    let allRoleUser = this.roleUserAllList.every( ai => this.permissionList.includes(ai) );
    if(allRoleUser){
      (<HTMLInputElement>document.getElementById("role-user-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("role-user-all")).checked = false;
    }

    if(allRoleUser && allRole) {
      (<HTMLInputElement>document.getElementById("all")).checked = true;
    } else{
      (<HTMLInputElement>document.getElementById("all")).checked = false;
    }

    let allAppUser = this.appUserAllList.every( ai => this.permissionList.includes(ai) );
    if(allAppUser){
      (<HTMLInputElement>document.getElementById("app-user-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("app-user-all")).checked = false;
    }

    let allBanner = this.bannerAllList.every( ai => this.permissionList.includes(ai) );
    if(allBanner){
      (<HTMLInputElement>document.getElementById("banner-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("banner-all")).checked = false;
    }

    let allCmsPages = this.cmsPagesAllList.every( ai => this.permissionList.includes(ai) );
    if(allCmsPages){
      (<HTMLInputElement>document.getElementById("cms-pages-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("cms-pages-all")).checked = false;
    }

    let alltransactionPages = this.transactionAllList.every( ai => this.permissionList.includes(ai) );
    if(alltransactionPages){
      (<HTMLInputElement>document.getElementById("transaction-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("transaction-all")).checked = false;
    }

    let allSettingPages = this.settingAllList.every( ai => this.permissionList.includes(ai) );
    if(allSettingPages){
      (<HTMLInputElement>document.getElementById("setting-pages-all")).checked = true;
    } else {
      (<HTMLInputElement>document.getElementById("setting-pages-all")).checked = false;
    }
  }

}
