<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <section id="manage-permission-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body pb-1">
              <form class="form-horizontal" (ngSubmit)="save(PermissionForm)" #PermissionForm="ngForm">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-control-label" for="Name">Name</label>
                      <input class="form-control input-md" ngModel #NameRef="ngModel" required id="Name" name="Name"
                        [(ngModel)]="name" type="text" placeholder="Name">
                      <span *ngIf="PermissionForm.submitted && NameRef.invalid" class="invalid-form">
                        <small class="form-text text-muted danger" *ngIf="NameRef.errors.required">This
                          field is
                          required!</small>
                      </span>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary" rippleEffect>Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>