import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'app/auth/models/page';
import { CommonService } from 'app/auth/service/common.service';
import { PermissionService } from 'app/auth/service/permission.service';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';

@Component({
  selector: 'app-manage-permission',
  templateUrl: './manage-permission.component.html',
  styleUrls: ['./manage-permission.component.scss']
})
export class ManagePermissionComponent implements OnInit {

  page = new Page();

  constructor(private _permissionService : PermissionService,
              private _commonService : CommonService,
              private _route: Router) { 
                this.page.pageNumber = 0;
                this.page.size = 10;
              }

  public data: any ;
  public basicSelectedOption: number = 10;

  pageLimitOptions: any = [1000];
  SORT_BY: string[] = ['name'];
  isDirectionDesc: boolean = true;
  private searchPolicyUserObs: Subscription;
  public pageStart: any = 0; 

  fetchPermissionListParam = {
		limit: 10,
		pageNo: 0,
		searchString: "",
		sortBy: "createdDate",
		direction: "desc",
		name: ""
	};
  public contentHeader: object;

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Permission',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Permission',
            isLink: false
          }
        ]
      }
    }
    this.searchPermission();
  }

  /**
   * search permission
   * @returns 
   */
  searchPermission() {
    return new Promise( (resolve)=>{
    if(this.searchPolicyUserObs){
      this.searchPolicyUserObs.unsubscribe();
    }
    this.searchPolicyUserObs = this._permissionService.searchPermission(this.fetchPermissionListParam,true,false).subscribe(async ( results ) => {
      this.data = results.data.content;
      this.pageStart = (results.data.pageable.pageSize * results.data.pageable.pageNumber) + 1;
      this.page.totalElements = results.data.totalElements;
      resolve("");
    },( error ) => {
      this._commonService.checkError(error);
      resolve("");
    });
  });
  }

  /**
   * delete permission
   * @param id 
   */
  deleteSelectedPemission(id : any ){
    swal.fire({
      title: 'Are you sure you want to remove permission?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      if (result.value) {
        this._permissionService.deletePermissionById(id).subscribe(async ( results ) => {
          this._commonService.checkSuccess(results);
          await this.searchPermission();
        },( error ) => {
          this._commonService.checkError(error);
        });
      }
    });
    
  }

  /**
   * edit time call edit page
   * @param id 
   */
  editSelectedPemission(id : any){
    this._route.navigate(["admin/permission/add"], { queryParams: { id : btoa(id)}});
  }

  /**
   * change page size
   * @param id 
   */
  changePageSize(id : any){
    this.basicSelectedOption = id;
    this.fetchPermissionListParam.limit = id;
    this.page.size = id;
    this.page.pageNumber = 0;
    this.fetchPermissionListParam.pageNo = 0;
    this.searchPermission();
  }
  /**
   * on sort which colom sport set value
   * @param e 
   */
  onSort(e){
    this.fetchPermissionListParam.direction = e.newValue;
    this.fetchPermissionListParam.sortBy = e.column.prop;
    this.page.pageNumber = 0;
    this.fetchPermissionListParam.pageNo = 0;
    this.searchPermission();
  }
  /**
   * 
   * @param event page change event set page
   */
  setPage(event){
    this.page.pageNumber = event.offset;
    this.fetchPermissionListParam.pageNo = event.offset;
    this.searchPermission();
  }
  /**
   * on search get data and page set o
   */
  searchPermissionBySearchBox(){
    this.page.pageNumber = 0;
    this.fetchPermissionListParam.pageNo = 0;
    this.searchPermission();
  }
}
