import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { APIClientService } from "./apiclient.service";
import { URL } from "./service.assets";

@Injectable({ providedIn: 'root' })
export class BannerService {

    constructor(private _apiClientService: APIClientService){}

    /**
     * search banner
     * @param fetchBannerListParam 
     * @param isActive 
     * @param isDeleted 
     * @returns 
     */
    searchBanner(fetchBannerListParam : any,isActive : any, isDeleted : any){
        var urlencoded = new URLSearchParams();
        urlencoded.append('title', fetchBannerListParam.searchString);
        urlencoded.append('direction', fetchBannerListParam.direction);
        urlencoded.append('sortBy', fetchBannerListParam.sortBy);
        urlencoded.append('limit', fetchBannerListParam.limit);
        urlencoded.append('pageNo', fetchBannerListParam.pageNo);
        urlencoded.append('isDeleted', isDeleted);
        urlencoded.append('isActvie', isActive);

        return this._apiClientService.doPostXURL(URL.SEARCH_BANNER,urlencoded).pipe(
			    map(response => response)
		    );
    }

    /**
     * get bannerby id
     * @param id 
     * @returns 
     */
    getBannerById(id : any){
      
      return this._apiClientService.doGet(URL.GET_BANNER_BY_ID+"?bId="+id).pipe(
			  map(response => response)
		  );
    }

    /**
     * delete banner by id
     * @param id 
     * @returns 
     */
    deleteBannerById(id : any){
      
      const formData = new FormData();
      formData.append('bId', id);
      return this._apiClientService.doPost(URL.DELETE_BANNER_BY_ID,formData).pipe(
			  map(response => response)
		  );
    }
    
    /**
     * save or update banner
     * @param formData 
     * @returns 
     */
    saveOrUpdateBanner(formData : any){
        
        return this._apiClientService.doPost(URL.SAVE_OR_UPDATE_BANNER,formData).pipe(
			map(response => response)
		);
    }

}