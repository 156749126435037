import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS, ROUTS, URL } from "./service.assets";
import { RequestOptions } from "https";
import { Router } from "@angular/router";
import { LocalStorageService } from "./localstorage.service";
import { ToastrService } from "ngx-toastr";
import swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class CommonService {

    constructor(
        private _router: Router,
        private _localStorageService: LocalStorageService,
        private _toastrService:ToastrService) {}
    
    public sessionExpiredMessage = "";
    
    /**
     * error wise open model
     * @param error 
     */
    checkError(error: any){
        console.log(error);
        if (error.error){
            if(error.error.errorCode && error.error.errorCode == 6002){
                swal.fire({
                    title: error.error.message,
                    icon: 'error',
                    showCancelButton: false,
                    //timer:10000
                  })
            }else if(error.error.error_description) {
                this._toastrService.error(error.error.error_description);
            } else {
                this._toastrService.error(error.error.message);
            }
        }
    }

    checkStaticError(error: any){        
        if (error){
            this._toastrService.error(error);
        }
    }

    /**
     * success message
     * @param success 
     */
    checkSuccess(success: any){
        if(success){
            if(success.message) {
                this._toastrService.success(success.message);
            } 
        }
    }


////////////////// login & logout ////////////////

    /**
     * check login and redirect to sign in page
     */
    checkLoginAndRedirectToSignPage() {
        if (!this.checkLogin()) {
            this._router.navigateByUrl(ROUTS.SIGN_IN);
        }
    }

    /**
     * check user login or not 
     * @returns 
     */
    checkLogin(): boolean {
		if (this.checkIsNullOrUndefined(this._localStorageService.getLocalStorage(CONSTANTS.ACCESSTOKEN)))
			return false;

		return true;
	}

    /**
     * check object null or undefine
     * @param data 
     * @returns 
     */
    checkIsNullOrUndefined(data: any) {
		if (data == null || data == undefined || data == '')
			return true;
		return false;
	}

    /**
     * login the redirect to dashboard
     */
    checkLoginAndRedirectToDashboard() {
		if (this.checkLogin()) {
			this._router.navigate(['/']);
		}
	}


}